import './css/landcourses.css';
import { Link as RouterLink } from 'react-router-dom';
import axios from '../config/index';
import { useState, useEffect } from 'react';
import star from './images/home/Star 1.svg';
import designerGirl from './images/home/Designer girl-cuate 1.png';
import imgCourse from './images/home/image 14.png';
import imgCourse2 from './images/home/image 14 (2).png';
import StarRating from './StarRating';
const Stars = Array(5).fill(null);

function LandCourses() {
    const [courseData, setAllCourses] = useState([]);
    const [users, setAllUsers] = useState([]);
    const [coursesWithInstructors, setCoursesWithInstructors] = useState([]);

    const getAllCourses = () => {
        axios.post('AllItems', { table: 'courses' })
            .then((res) => {
                if (res.data) {
                    setAllCourses(res.data);
                } else {
                    console.log("Error happened");
                }
            })
            .catch((err) => console.log(err));
    };

    const getAllUsers = () => {
        axios.post('AllUsers', { table: 'users' })
            .then((res) => {
                if (res.data) {
                    setAllUsers(res.data);
                } else {
                    console.log("Error happened");
                }
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        getAllCourses();
        getAllUsers();
    }, []);

    useEffect(() => {
        if (courseData.length > 0 && users.length > 0) {
            // Filter and combine course data with instructor data
            const activeCourses = courseData.filter(course => course.status === 'active');
            const combinedData = activeCourses.map(course => {
                const instructor = users.find(user => user.user_id === course.user_id) || {};
                return { ...course, instructor };
            });
            setCoursesWithInstructors(combinedData);
        }
    }, [courseData, users]);

    const lastFourCourses = coursesWithInstructors.slice(-2);
    const staticRating1 = 4.9;
const staticRating2 = 4.7;
const staticRating3 = 4.6;
    return (
      <section className="land-courses">
        <div className="container">
          <div className="section-header">
            <h2 className="coursesHeader"> الدورات </h2>
            <h1 className="section-headline"> تعرف على أكثر الدورات طلبا</h1>
          </div>
          {/* <div className='row'>
                    {lastFourCourses.length ? (
                    lastFourCourses.map((course, index) => (
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12' key={index}>
                            <div className='course'>
                                <RouterLink to={`/course?id=${course.id}`}>
                                    <img src={`./uploads/${course.img}`} alt='course image' />
                                </RouterLink>
                                <div className='text'>
                                    <div className='reviews'>
                                        <ul>
                                            {Stars.map((_, i) => (
                                                <li key={i}>
                                                    <i className="las la-star"></i>
                                                </li>
                                            ))}
                                        </ul>
                                        <span>({course.reviews} مراجعات)</span>
                                    </div>
                                    <h1>{course.header}</h1>
                                    <div className='course-info'>
                                        <span><i className="las la-book"></i> {course.lessons} دروس</span>
                                        <span><i className="las la-users"></i> {course.students} طلاب</span>
                                    </div>
                                    <p className='section-text'>{course.description}</p>
                                    <div className='instructor-info'>
                                        <img src={`./uploads/${course.instructor.profile_img}`} alt='instructor image' />
                                        <h4>بواسطة <span>{course.instructor.fullname}</span></h4>
                                    </div>
                                    <div className='course-price'>
                                        {course.free === 1 ? <h3> مجاني </h3> : (
                                            <>
                                                <h3>${course.price} <span>${course.prev_price}</span></h3>
                                            </>
                                        )}
                                        <RouterLink to={`/course?id=${course.id}`}>اعرف أكثر <i className="las la-arrow-right"></i></RouterLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                   ))
                            ) : null }
                </div> */}
          <div className="courses-container">
            {/* Main Course Card */}
            <div className="course-main">
              <div className="design-item">
                <img
                  src={designerGirl}
                  alt="Designer Girl"
                  className="main-course-image"
                />
              </div>
              <div className="design-item course-info">
                <h1 className="course-title">كورس احتراف الفوتوشوب من الصفر</h1>
                <p>
                  هل ترغب في اتقان برنامج الفوتوشوب وتطبيق مهارات التصميم بشكل
                  احترافي؟ انضم إلينا في هذا الكورس الشامل الذي يأخذك من مستوى
                  المبتدئ إلى مستوى المحترف في الفوتوشوب. نقدم لك تقنيات متقدمة
                  وأسرار التصميم التي يستخدمها المحترفون لتصميمات رائعة وجذابة.
                </p>
                <div className="money">
                  <h1 className="price">
                    $35 <span className="original-price">$100</span>
                  </h1>
                  <h1 className="rating">
                    {staticRating1}
                    <img src={star} alt="Star" className="star-icon" />
                  </h1>
                </div>
                <div className="btn-container">
                  <a
                    className="btn-primary"
                    href="https://www.meedcourses.com/course?id=26"
                    target="new"
                  >
                    شاهد الدورة
                  </a>
                  {/* <button className="btn-secondary">اضف إلى المفضلة</button> */}
                </div>
              </div>
            </div>

            {/* Additional Course Cards */}
            <div className="course-row">
              {lastFourCourses.length
                ? lastFourCourses.slice(0, 2).map((course, index) => (
                      <div key={index} className="course-item">
                        <RouterLink to={`/course?id=${course.id}`}>
                          <img
                            src={`./uploads/${course.img}`}
                            alt="AI Course"
                            className="course-image"
                          />
                        </RouterLink>
                        <h1 className="course-title">{course.header}</h1>
                        <p className="course-description">
                          {course.description}
                        </p>
                        <div className="course-rate">
                          <div className="rating-info">
                            <StarRating
                              totalStars={Stars.length}
                              rating={staticRating2}
                            />
                            <p className="rating-count">
                              ({course.reviews} تعليق)
                            </p>
                          </div>
                          <RouterLink  to={`/course?id=${course.id}`}>
                            <button className="btn-course">عرض الدورة</button>
                          </RouterLink>
                        </div>
                      </div>
                  ))
                : null}

              {/* <div className="course-item">
                <img
                  src={imgCourse}
                  alt="Design Thinking Course"
                  className="course-image"
                />
                <h1 className="course-title">دورة التفكير التصميمي</h1>
                <p className="course-description">
                  هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم
                  توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا
                  النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف
                  التى يولدها التطبيق.
                </p>
                <div className="course-rate">
                  <div className="rating-info">
                    <StarRating totalStars={5} rating={staticRating3} />
                    <p className="rating-count">(47 تعليق)</p>
                  </div>
                  <button className="btn-course">عرض الدورة</button>
                </div>
              </div> */}
            </div>
          </div>
          <div className="more-btn">
            <RouterLink to="/courses">
              <button className="more">تعرف على المزيد</button>
            </RouterLink>
          </div>
        </div>
      </section>
    );
}

export default LandCourses;
