import React from "react";
import styles from "./css/services.module.css";
import desktop from "./images/home/desktop_fill.svg";
import Paper from "./images/home//Paper_fill.svg";
import gift from "./images/home/GIft_fill.svg";
import headphone from "./images/home/Headphones_fill.svg";
function Services() {
  return (
    <section className={styles.serviceSection}>
      <div className={styles.div1}>الخدمات</div>
      <div className={styles.div}>
        نقدم لكم افضل خدماتنا التعليمية عن بعد لكل الاعمار
      </div>

      <div className={styles.servicesContainer}>
        <div className={styles.rectangleParent}>
          <div className={styles.groupChild}>
            <img className={styles.giftFillIcon} alt="" src={gift} />
            <div>
              <h3 className={styles.b}>فعاليات وهدايا للطلاب</h3>
              <p className={styles.b1}>نحن نؤمن بأن التعليم يجب أن يكون تجربة مشوقة ومحفزة، ولهذا السبب نقدم لطلابنا فعاليات وهدايا حصرية كجزء من رحلتهم التعليمية معنا. نسعى من خلال هذه الفعاليات إلى خلق بيئة تعليمية ملهمة وتشجيعية، تجعل من التعلم تجربة ممتعة وتفاعلية.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.rectangleGroup}>
          <div className={styles.groupChild}>
            <img className={styles.paperFillIcon} alt="" src={Paper} />
            <div>
              <h3 className={styles.b2}>شواهد معتمدة</h3>
              <p className={styles.b1}>
في منصتنا التعليمية، نفخر بتقديم شواهد معتمدة لكافة الدورات التي تقومون بإكمالها بنجاح. نحن نحرص على ضمان أن تكون شهاداتنا معترف بها وموثوقة، مما يضيف قيمة حقيقية لمهاراتكم ومعرفتكم التي اكتسبتموها من خلال دوراتنا.
 </p>
            </div>
          </div>
        </div>
        <div className={styles.rectangleContainer}>
          <div className={styles.groupChild}>
            <img className={styles.desktopFillIcon} alt="" src={desktop} />
            <div>
              <h3 className={styles.b4}>دورات مسجلة متنوعة</h3>
              <p className={styles.b1}>
في منصتنا التعليمية، نقدم لكم مجموعة واسعة من الدورات المسجلة التي تغطي مواضيع متعددة في شتى المجالات. تتميز دوراتنا بأنها مسجلة مسبقاً وبجودة عالية، مما يتيح لكم تعلم المهارات الجديدة في أي وقت يناسبكم.
    </p>
            </div>
          </div>
        </div>
        <div className={styles.groupDiv}>
          <div className={styles.groupChild}>
            <img className={styles.headphonesFillIcon} alt="" src={headphone} />
            <div>
              <h3 className={styles.b6}>دعم فني متواصل</h3>
              <p className={styles.b7}>
في منصتنا التعليمية، نؤمن بأهمية الدعم الفني المتواصل لضمان تجربة تعليمية سلسة وفعالة لمستخدمينا. فريقنا من المتخصصين في الدعم الفني متاح على مدار الساعة، لتقديم المساعدة والإجابة على استفساراتكم وحل أي مشكلات قد تواجهونها.
 </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
