import './css/dashboardtable.css';
import React, { useState, useEffect } from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';

function AllUsersCourses() {
    const navigate = useNavigate();
    const [AllItems, setAllItems] = useState([]);
    const [courses, setAllCourses] = useState([]);
    const [users, setUsers] = useState([]);

    axios.defaults.withCredentials = true;

    useEffect(() => {
        axios.get('/admin-session')
            .then((res) => {
                if (res.data.validadmin) {
                    if (res.data.type != "admin") {
                        console.log(res.data.type)
                        navigate('/admin-login');
                    }
                } else {
                    console.log(res.data.validadmin)

                    navigate('/admin-login');
                }
            })
            .catch((err) => console.log(err));
    }, [navigate]);

    const getAllCourses = () => {
        axios.post('AllItems', { table: 'courses' })
            .then((res) => {
                if (res.data) {
                    setAllCourses(res.data);
                } else {
                    console.log("Error happened");
                }
            })
            .catch((err) => console.log(err));
    };

    const getAllUsers = () => {
        axios.post('AllUsers', { table: 'users' })
            .then((res) => {
                if (res.data) {
                    setUsers(res.data);
                } else {
                    console.log("Error happened");
                }
            })
            .catch((err) => console.log(err));
    };

    const getAllItems = () => {
        axios.post('AllItems', { table: 'user_courses' })
            .then((res) => {
                if (res.data) {
                    setAllItems(res.data);
                } else {
                    console.log("Error happened");
                }
            })
            .catch((err) => console.log(err));
    };

    const deleteItem = (id) => {
        axios.delete(`delete-item/${id}`, {
            data: {
                table: 'user_courses',
            },
        })
            .then((response) => {
                if (response.data.message === 'Item deleted successfully') {
                    getAllItems();
                } else {
                    console.error('Failed to delete result:', response.data.message);
                }
            })
            .catch((error) => {
                console.error('Error deleting result:', error);
            });
    };

    useEffect(() => {
        getAllItems();
        getAllCourses();
        getAllUsers();
    }, []);

    return (
        <>
            <section className='dashboard'>
                <div className='results-table'>
                    <Link className='new-item' to='/add-user-course'> إضافة كورس الى طالب </Link>
                    <table>
                        <thead>
                            <tr>
                                <th> # </th>
                                <th> الكورس </th>
                                <th> البريد الخاص بالطالب </th>
                                <th> الإختيارات </th>
                            </tr>
                        </thead>
                        <tbody>
                            {AllItems.length ? (
                                AllItems.map((item) => {
                                    const user = users.find(u => u.user_id === item.user_id) || {};
                                    const course = courses.find(c => c.id === item.course_id) || {};

                                    return (
                                        <tr key={item.id}>
                                            <td>
                                                <h6>{item.id}</h6>
                                            </td>
                                            <td>
                                                <h6>{course.header}</h6>
                                            </td>
                                            <td>
                                                <h6>{user.email}</h6>
                                            </td>
                                            <td><Link className='edit' to={`/edit-user-course?id=${item.id}`}> <i className="las la-edit"></i> </Link></td>
                                            <td>
                                                <button className='delete' onClick={() => deleteItem(item.id)}>
                                                    <i className="las la-trash-alt"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={5}>Loading...</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    );
}

export default AllUsersCourses;
