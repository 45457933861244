import './css/numbers.css';



import axios from '../config/index';
import { useState, useEffect } from 'react';
function Numbers() {

    const [allNumbers, setAllNumbers] = useState({
        header1: '',
        para1: '',
        header2: '',
        para2: '',
        header3: '',
        para3: '',
        header4: '',
        para4: ''
    });
    const [numbersData, setNumbersData] = useState([]);

    const getAllNumbers = (itemId) => {
        const table = "numbers";
        axios.post('/get-item', { itemId, table })
            .then((res) => {
                if (!res.data.message) {
                    setAllNumbers(res.data);
                }
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        getAllNumbers(1);
    }, []);

    useEffect(() => {
        setNumbersData([
            { value: allNumbers.header1, label: allNumbers.para1 },
            { value: allNumbers.header2, label: allNumbers.para2 },
            { value: allNumbers.header3, label: allNumbers.para3 },
            { value: allNumbers.header4, label: allNumbers.para4 },
        ]);
    }, [allNumbers]);

return (
    <section className='numbers'>
    <div className='container'>
        <div className='row'>
        {numbersData.map((number, index) => (
            <div key={index} className='col-lg-3 col-md-3 col-sm-6 col-12'>
            <div className='number'>
                <h1>{number.value} <i className="las la-plus"></i></h1>
                <h2 className=''>{number.label}</h2>
                {index !== numbersData.length - 1 && <span className='line'></span>}
            </div>
            </div>
        ))}
        </div>
    </div>
    </section>
);
}

export default Numbers;
