import React, { useState, useEffect } from 'react';
import './css/sidebar.css';
import axios from "../config/index";

const arrowU = <i className="fa-solid fa-chevron-up up"></i>;
const arrowD = <i className="fa-solid fa-chevron-down down"></i>;

function Sidebar({
  setSelectedCategory,
  setSelectedRating,
  setSelectedPrice,
  setVideoDuration,
  setLevel,
  setFreeOrPaid,
  resetFilters
}) {
  const [categoriesOpen, setCategoriesOpen] = useState(true);
  const [filtersOpen, setFiltersOpen] = useState(true);
  const [selectedRating, setSelectedRatingState] = useState(null);
  const [selectedPrice, setSelectedPriceState] = useState(null);
  const [videoDuration, setVideoDurationState] = useState([]);
  const [level, setLevelState] = useState([]);
  const [freeOrPaid, setFreeOrPaidState] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategoryState] = useState(null);

  const handleCategoriesModalToggle = () => setCategoriesOpen(!categoriesOpen);
  const handleFiltersModalToggle = () => setFiltersOpen(!filtersOpen);

  const handleRatingChange = (event) => {
    const value = event.target.value;
    setSelectedRatingState(value);
    setSelectedRating(value);
  };

  const handleCategoryChange = (event) => {
    const selectedCatId = parseInt(event.target.value, 10);
    setSelectedCategoryState(selectedCatId);
    setSelectedCategory(selectedCatId);
  };

  const handlePriceChange = (event) => {
    const value = event.target.value;
    setSelectedPriceState(value);
    setFreeOrPaid(value);
  };

  const handleDurationChange = (event) => {
    const value = event.target.id;
    setVideoDurationState((prev) =>
      prev.includes(value) ? prev.filter(item => item !== value) : [...prev, value]
    );
    setVideoDuration((prev) =>
      prev.includes(value) ? prev.filter(item => item !== value) : [...prev, value]
    );
  };

  const handleLevelChange = (event) => {
    const value = event.target.id;
    setLevelState((prev) =>
      prev.includes(value) ? prev.filter(item => item !== value) : [...prev, value]
    );
    setLevel((prev) =>
      prev.includes(value) ? prev.filter(item => item !== value) : [...prev, value]
    );
  };

  const resetAllFilters = () => {
    setSelectedCategoryState(null);
    setSelectedCategory(null);
    setSelectedRatingState(null);
    setSelectedRating(null);
    setSelectedPriceState(null);
    setFreeOrPaid(null);
    setVideoDurationState([]);
    setVideoDuration([]);
    setLevelState([]);
    setLevel([]);
    resetFilters(); 
  };

  useEffect(() => {
    const getAllCategories = () => {
      axios.post("AllItems", { table: "categories" })
        .then((res) => {
          if (res.data) {
            setCategories(res.data);
          } else {
            console.log("Error happened");
          }
        })
        .catch((err) => console.log(err));
    };

    getAllCategories();
  }, []);

  return (
    <div className="sidebar">
      <div className="section">
      {(
      selectedCategory || 
      selectedPrice || 
      selectedRating || 
      videoDuration.length > 0 || 
      level.length > 0
    ) && (
      <button className="section-header center" onClick={resetAllFilters}>
        مسح
      </button>
    )}
        <button className="section-header" onClick={handleCategoriesModalToggle}>
          التصنيفات {categoriesOpen ? arrowU : arrowD}
        </button>
        {categoriesOpen && (
          <div className="section-content1 radio-button-group">
            {categories.map(category => (
              <React.Fragment key={category.id}>
                <input
                  type="radio"
                  name="category"
                  value={category.id}
                  id={`category-${category.id}`}
                  checked={category.id === selectedCategory}
                  onChange={handleCategoryChange}
                />
                <label className="radio-button" htmlFor={`category-${category.id}`}>
                  {category.header}
                </label>
              </React.Fragment>
            ))}
          </div>
        )}
      </div>

      <div className="section s2">
        <button className="section-header" onClick={handleFiltersModalToggle}>
          الفلاتر {filtersOpen ? arrowU : arrowD}
        </button>
        {filtersOpen && (
          <div className="section-content">
            <div className="filter-group">
              <label>التسعير</label>
              <div className="radio-button-group">
                <input
                  type="radio"
                  id="free"
                  name="price"
                  value="free"
                  checked={selectedPrice === "free"}
                  onChange={handlePriceChange}
                />
                <label htmlFor="free" className="radio-button">مجاني</label>

                <input
                  type="radio"
                  id="paid"
                  name="price"
                  value="paid"
                  checked={selectedPrice === "paid"}
                  onChange={handlePriceChange}
                />
                <label htmlFor="paid" className="radio-button">مدفوع</label>
              </div>
            </div>

            <div className="filter-group">
              <label>التقييمات</label>
              <div className="rating-options">
                {[5, 4, 3].map((rating) => (
                  <label
                    key={rating}
                    className={`rating-box ${selectedRating === String(rating) ? "selected" : ""}`}
                  >
                    <input
                      type="radio"
                      name="rating"
                      value={rating}
                      checked={selectedRating === String(rating)}
                      onChange={handleRatingChange}
                    />
                    <div>
                      <span>{'★ '.repeat(rating)}</span>
                      <span>نجوم {rating}</span>
                    </div>
                  </label>
                ))}
              </div>
            </div>

            <div className="filter-group">
              <label>مدة الكورس</label>
              <div className="radio-button-group flex-column">
                {[
                  { duration: '1', header: 'ساعة' }, 
                  { duration: '2', header: 'ساعتين' }, 
                  { duration: '3', header: 'أكثر من ثلاث ساعات' },
                ].map((duration) => (
                  <React.Fragment key={duration.duration}>
                    <input
                      type="radio"
                      id={duration.duration}
                      name="duration"
                      value={duration.duration}
                      checked={videoDuration.includes(duration.duration)}
                      onChange={handleDurationChange}
                    />
                    
                    <label htmlFor={duration.duration} className="radio-button">{duration.header}</label>
                  </React.Fragment>
                ))}
              </div>
            </div>

            <div className="filter-group">
              <label>المستوى</label>
              <div className="radio-button-group flex-column">
                {[
                  { level: 'مبتدئ', header: 'مبتدئ' }, 
                  { level: 'متوسط', header: 'متوسط' }, 
                  { level: 'متقدم', header: 'متقدم' },
                ].map((levelItem) => (
                  <React.Fragment key={levelItem.level}>
                    <input
                      type="radio"
                      id={levelItem.level}
                      name="level"
                      value={levelItem.level}
                      checked={level.includes(levelItem.level)}
                      onChange={handleLevelChange}
                    />
                    <label htmlFor={levelItem.level} className="radio-button">{levelItem.header}</label>
                  </React.Fragment>
                ))}
              </div> 
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Sidebar;
