import './css/dashboardtable.css';
import './css/editform.css';
import React, { useState, useEffect } from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';




function EditNumber() {



    useEffect(() => {
        axios.get('/admin-session')
            .then((res) => {
                if (res.data.validadmin) {
                    if (res.data.type !== "admin") {
                        navigate('/admin-login');
                    }
                } else {
                    // Handle the case when session is not valid
                }
            })
            .catch((err) => console.log(err));
    }, []);




    const [formChanged, setFormChanged] = useState(false); // Track if the form has changed


    const handleInputChange = () => {
        setFormChanged(true); // Set the formChanged flag to true when any input changes
    };




    const navigate = useNavigate();


    const [ItemData, setItemData] = useState({});


    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');


    const [formInputs, setFormInputs] = useState({
        header1: "",
        para1: "",
        header2: "",
        para2: "",
        header3: "",
        para3: "",
        header4: "",
        para4: "",

    });
    
    // Use formInputs to set the initial value of inputs
    useEffect(() => {
        if (ItemData.id) {
        setFormInputs({
            
            header1: ItemData.header1,
            para1: ItemData.para1,
            header2: ItemData.header2,
            para2: ItemData.para2,

            header3: ItemData.header3,
            para3: ItemData.para3,

            header4: ItemData.header4,
            para4: ItemData.para4,



        });
        }
    }, [ItemData]);

        const handleChange = (e) => {
            handleInputChange();
            const { name, value, type } = e.target;
            const inputValue = type === "file" ? e.target.files[0] : value;
        
            setFormInputs((prevFormInputs) => ({
            ...prevFormInputs,
            [name]: inputValue,
            }));
        };


        
        const handleSubmit = async (e) => {
            e.preventDefault();
        
            let Loading = document.querySelector(".loading-screen");
            Loading.classList.add("active");
        
            const { header1,para1,header2,para2,header3,para3,header4,para4 } = formInputs;
        
            if (!header1 || !para1|| !header2|| !para2|| !header3|| !para3|| !header4|| !para4  ) {
            return alert("All fields are required");
            }
        
            try {
            const form = new FormData();
            form.append("id", id);
            form.append("header1", header1);
            form.append("para1", para1);
            form.append("header2", header2);
            form.append("para2", para2);
            form.append("header3", header3);
            form.append("para3", para3);
            form.append("header4", header4);
            form.append("para4", para4);


            const response = await axios.put("/update-numbers", form);
        
            if (response.status === 200) {
                // Update LandingSlide state after a successful update
                setItemData((prevItemData) => ({
                ...prevItemData,
                header1,para1,header2,para2,header3,para3,header4,para4
                }));
        
                Loading.classList.remove("active");
                let alertS = document.querySelector(".alert-success");
                alertS.classList.add("active");
        
                setTimeout(() => {
                alertS.classList.remove("active");
                }, 3000);
            } else {
                Loading.classList.remove("active");
                let alertD = document.querySelector(".alert-danger");
                alertD.classList.add("active");
                setTimeout(() => {
                alertD.classList.remove("active");
                }, 3000);
            }
            } catch (error) {
            console.error(error);
            alert("Server error");
            }
        };




    axios.defaults.withCredentials = true;
    
    const getItemData = (itemId) => {
        let table = "numbers";
        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setItemData(res.data);
                }
            })
            .catch((err) => console.log(err));
    };




    const getIfLogin = () => {
        axios.get('/admin-session')
            .then((res) => {
                if (!res.data.validadmin || id <= 0 || id == null || id == "") {
                    navigate('/admin-login');
                } else {
                    getItemData(id);
                }
            })
            .catch((err) => console.log(err));
    };
    



    useEffect(() => {
        getItemData(id);
    }, []);
    
    return (
        <>

<div className='loading-screen'>
                            <div class="loader">
                                <div class="outer"></div>
                                <div class="middle"></div>
                                <div class="inner"></div>
                            </div>

                        </div>
            <section className='dashboard'>
                <div className='edit-form'>

                
                <div className='alert-success'>
                        <h1> تمت الإضافة  <i class="las la-check-circle"></i></h1>
                    </div>

                    <div className='alert-danger'>
                        <h1> فشل التعديل <i class="las la-exclamation-triangle"></i></h1>

                    </div>


                    <form onSubmit={handleSubmit}>
                

            
                        
                            <div className='input'>
                                <label> العنوان الأول  </label>
                                <input   value={formInputs.header1} onChange={handleChange} type='text' required="required"  maxLength="230" name='header1'/>
                            </div>

                            <div className='input'>
                                <label> النص الأول  </label>
                                <textarea value={formInputs.para1} onChange={handleChange} type='text' required="required"  name='para1'> </textarea>
                            </div>


                            
                            <div className='input'>
                                <label> العنوان الثاني  </label>
                                <input   value={formInputs.header2} onChange={handleChange} type='text' required="required"  maxLength="230" name='header2'/>
                            </div>

                            <div className='input'>
                                <label> النص الثاني  </label>
                                <textarea value={formInputs.para2} onChange={handleChange} type='text' required="required"  name='para2'> </textarea>
                            </div>


                            
                            <div className='input'>
                                <label> العنوان الثالث  </label>
                                <input   value={formInputs.header3} onChange={handleChange} type='text' required="required"  maxLength="230" name='header3'/>
                            </div>

                            <div className='input'>
                                <label> النص الثالث  </label>
                                <textarea value={formInputs.para3} onChange={handleChange} type='text' required="required"  name='para3'> </textarea>
                            </div>


                            <div className='input'>
                                <label> العنوان الرابع  </label>
                                <input   value={formInputs.header4} onChange={handleChange} type='text' required="required"  maxLength="230" name='header4'/>
                            </div>

                            <div className='input'>
                                <label> النص الرابع  </label>
                                <textarea value={formInputs.para4} onChange={handleChange} type='text' required="required"  name='para4'> </textarea>
                            </div>




                                <button disabled={!formChanged} type='submit'> تحديث </button>
                            </form>
                        </div>
                    </section>
                </>
            );
            }
            


            export default EditNumber;
