import './css/reviews.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Navigation, Pagination } from 'swiper';
import girlStudent from './images/home/image 19.svg';
import boyStudent from './images/home/image 20.svg';
import reviewIcon from './images/home/Group 8640.svg';
import shap2 from './images/home/Rectangle 4248.svg';
import shap22 from './images/home/Rectangle 4250.svg';
import axios from '../config/index';
import { useState, useEffect } from 'react';


function Reviews() {
    const staticReviews = [
       
        {
          id: 1,
          img: girlStudent,
          name: 'سارة حسن',
          title: 'طالب',
          para: 'لا استطيع التوقف عن التعلم من دوراتكم، محتوى متجدد ومفيد جداً.'
        },
        {
            id: 2,
            img: boyStudent,  // Example image file, replace with the correct image path
            name: 'علي محمد',
            title: 'طالب',
            para: 'تجربة تعليمية رائعة! كنت متردداً في البداية ولكن دوراتكم أضافت الكثير لمعرفتي ومهاراتي.'
          },
        {
          id: 3,
          img: girlStudent,
          name: 'نورا خالد',
          title: 'طالب',
          para: 'أنصح بشدة بدوراتكم، محتوى متنوع وتجربة تعلم سهلة ومفيدة.'
        },
        {
            id: 1,
            img: girlStudent,
            name: 'سارة حسن',
            title: 'طالب',
            para: 'لا استطيع التوقف عن التعلم من دوراتكم، محتوى متجدد ومفيد جداً.'
          },
          {
              id: 2,
              img: boyStudent,  // Example image file, replace with the correct image path
              name: 'علي محمد',
              title: 'طالب',
              para: 'تجربة تعليمية رائعة! كنت متردداً في البداية ولكن دوراتكم أضافت الكثير لمعرفتي ومهاراتي.'
            },
          {
            id: 3,
            img: girlStudent,
            name: 'نورا خالد',
            title: 'طالب',
            para: 'أنصح بشدة بدوراتكم، محتوى متنوع وتجربة تعلم سهلة ومفيدة.'
          }
      ];
    const [AllRates, setAllRates] = useState([]);

    const getAllRates = (itemId) => {
        const table = "rates";
        axios.post('/AllItems', { table }).then((res) => {
            if (!res.data.message) {
                setAllRates(res.data);
            }
        }).catch((err) => console.log(err));
    };

    useEffect(() => {
        getAllRates();
    }, []);

console.log(staticReviews + 'dataaaaaaaaaaaaa');
console.log('====================================');
console.log();
console.log('====================================');
    return (
        <>
            <section className='reviews'>
                <div className='container'>
                    <div className='section-header'>
                        <h2 className='section-text2'>اراء الطلاب</h2>
                        <h1 className='section-headline'>غير حياتك للافضل مع كل دورة</h1>
                    </div>
                    <div className='reviews-slider'>
      <Swiper
        grabCursor={true}
        spaceBetween={50}
        slidesPerView={'auto'}
        centeredSlides={true}
        loop={true}
        navigation={{
          nextEl: '.next-btn',
          prevEl: '.prev-btn',
        }}
        pagination={{ clickable: true }} 
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Navigation, Pagination]}
      >
        {staticReviews.length > 0 ? (
          staticReviews.map(rate => (
            <SwiperSlide key={rate.id}>
                <div className='review-container'>
                <div className='review'>
                <div className='user-info'>
                  {/* <img src={`./uploads/${rate.img}`} alt='user image' className='user-image' /> */}
                  <p className='section-text'>
                  {rate.para}
                </p>
                <img src={reviewIcon} alt='review icon' className='review-icon' />
             
                </div>
             
              </div>
              <div className='user'>
              <img src={rate.img} alt='user image' className='user-image' />
                  <div className='user-details'>
                    <h2>{rate.name}</h2>
                    <h3>{rate.title}</h3>
                  </div>
              </div>
                </div>
             
            </SwiperSlide>
          ))
        ) : <></>}
      </Swiper>
    </div>

                </div>
            </section>
        </>
    );
}

export default Reviews;
