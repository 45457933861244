import './css/loginregister.css';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import axios from '../config/index';

function LoginRegisterInstructor() {
    const navigate = useNavigate();

    const [loginEmail, setLoginEmail] = useState('');
    const [loginPassword, setLoginPassword] = useState('');
    const [registerEmail, setRegisterEmail] = useState('');
    const [registerName, setRegisterName] = useState('');
    const [registerPassword, setRegisterPassword] = useState('');
    const [registerConfirmPassword, setRegisterConfirmPassword] = useState('');
    const [registerType, setRegisterType] = useState('inst');
    const [loading, setLoading] = useState(false); // Add loading state
    const [infoText, setInfoText] = useState(''); // New state for infoText
    const [cvFile, setCvFile] = useState(null); // New state for cvFile

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true); // Show loading screen
        try {
            const response = await axios.post('/login', {
                email: loginEmail,
                password: loginPassword
            });
            navigate('/');
        } catch (error) {
            alert(error.response.data.message);
        } finally {
            setLoading(false); // Hide loading screen
        }
    };

    const handleRegister = async (e) => {
        e.preventDefault();
        if (registerPassword !== registerConfirmPassword) {
            alert('Passwords do not match');
            return;
        }
        setLoading(true); // Show loading screen
        try {
            const formData = new FormData();
            formData.append('fullname', registerName);
            formData.append('email', registerEmail);
            formData.append('password', registerPassword);
            formData.append('type', registerType);
            formData.append('infoText', infoText);
            if (cvFile) {
                formData.append('cvFile', cvFile);
            }
            const response = await axios.post('/signupInst',formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
        });
            navigate('/confirm-email');
        } catch (error) {
            alert(error.response.data.message);
        } finally {
            setLoading(false); // Hide loading screen
        }
    };

    return (
        <>
            {loading && (
                <div className='loading-screen'>
                    <div className="loader"></div>
                </div>
            )}
            <section className='login-register'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='form-element'>
                                <h1>تسجيل الدخول</h1>
                                <form onSubmit={handleLogin}>
                                    <div className='inputs'>
                                        <div className='input'>
                                            <input
                                                type='email'
                                                placeholder='البريد الإلكتروني'
                                                required
                                                value={loginEmail}
                                                onChange={(e) => setLoginEmail(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='inputs'>
                                        <div className='input'>
                                            <input
                                                type='password'
                                                placeholder='كلمة المرور'
                                                required
                                                value={loginPassword}
                                                onChange={(e) => setLoginPassword(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='forget-password'>
                                        <RouterLink to='/forget-password'>
                                            هل نسيت كلمة المرور؟
                                        </RouterLink>
                                    </div>
                                    <button type='submit' className='link-btn-midcolor'>
                                        تسجيل الدخول <i className='las la-arrow-right'></i>
                                    </button>
                                </form>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='form-element'>
                                <h1>تسجيل جديد</h1>
                                <form onSubmit={handleRegister}>
                                    <div className='inputs'>
                                        <div className='input'>
                                            <input
                                                type='email'
                                                placeholder='البريد الإلكتروني'
                                                required
                                                value={registerEmail}
                                                onChange={(e) => setRegisterEmail(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='inputs'>
                                        <div className='input'>
                                            <input
                                                type='text'
                                                placeholder='الاسم'
                                                required
                                                value={registerName}
                                                onChange={(e) => setRegisterName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                   
                                    <div className='inputs'>
                                        <div className='input'>
                                            <input
                                                type='password'
                                                placeholder='كلمة المرور'
                                                required
                                                value={registerPassword}
                                                onChange={(e) => setRegisterPassword(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='inputs'>
                                        <div className='input'>
                                            <input
                                                type='password'
                                                placeholder='تأكيد كلمة المرور'
                                                required
                                                value={registerConfirmPassword}
                                                onChange={(e) => setRegisterConfirmPassword(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='inputs'>
                                        <div className='input'>
                                        <textarea cols='14'  class="form-control" id="exampleFormControlTextarea1" rows="3"
                                         placeholder="نبذة عنك "
                                          value={infoText}
                                           onChange={(e) => setInfoText(e.target.value)}>

                                           </textarea>
                                        </div>
                                    </div>
                                                            <div className="inputs">
                                    <div className="input mb-3">
                                        <label htmlFor="fileUpload" className="form-label mt-3">     ارفق سيرتك الذاتية</label>
                                        <input 
                                            className="form-control"
                                            type="file"
                                            id="fileUpload"
                                            onChange={(e) => setCvFile(e.target.files[0])} 
                                        />
                                        {cvFile && <small className="text-muted mt-2">Selected file: {cvFile.name}</small>}
                                    </div>
                                </div>
                                    <button type='submit' className='link-btn-color'>
                                        تسجيل جديد <i className='las la-arrow-right'></i>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default LoginRegisterInstructor;
