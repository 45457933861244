import './css/dashboardtable.css';
import React, { useState,useEffect} from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';




function AllUsersPage(){

    const [filteredUsers, setFilteredUsers] = useState([]);
    const [selectedRole, setSelectedRole] = useState("all"); // State to track the selected role filter
    const navigate = useNavigate();
    const [users, setAllUsers] = useState([]);
        
    useEffect(() => {
        axios
    .get('admin-session')
    .then((res) => {
        if (res.data.validadmin) {

            if(res.data.type == "admin"){

            }else{
                navigate('/admin-login');
            }

        } else {

        }
    })
    .catch((err) => console.log(err));
    },[]);



    
    

    axios.defaults.withCredentials = true;


    const GetAllUsers = () => {
        axios
          .post("AllUsers", {
            table: "users",
          })
          .then((res) => {
            if (res.data) {
              setAllUsers(res.data);
              setFilteredUsers(res.data); // Set both users and filteredUsers initially
            } else {
              console.log("Error happened");
            }
          })
          .catch((err) => console.log(err));
      };
    

    const deleteItem = (id) => {
        axios
        .delete(`delete-item/${id}`, {
            data: {
            table: 'users',
            },
        })
        .then((response) => {
            if (response.data.message === 'Item deleted successfully') {
            GetAllUsers();
            } else {
            console.error('Failed to delete result:', response.data.message);
            }
        })
        .catch((error) => {
            console.error('Error deleting result:', error);
        });
    };
    

  
    const ActivateUser = (id) => {
        axios.put(`activate-user/${id}`, { data: { table: 'users' } })
            .then((response) => {
                if (response.data.message === 'Item activated successfully') {
                    GetAllUsers();
                } else {
                    console.error('Failed to activate result:', response.data.message);
                }
            })
            .catch((error) => {
                console.error('Error activating result:', error);
            });
    };

    const DiActivateUser = (id) => {
        axios.put(`diactivate-user/${id}`, { data: { table: 'users' } })
            .then((response) => {
                if (response.data.message === 'Item diactivated successfully') {
                    GetAllUsers();
                } else {
                    console.error('Failed to diactivate result:', response.data.message);
                }
            })
            .catch((error) => {
                console.error('Error diactivating result:', error);
            });
    };

  // Function to filter users based on the selected role
  const filterUsersByRole = (role) => {
    if (role === "all") {
      setFilteredUsers(users); // If "all" is selected, show all users
    } else {
      const filtered = users.filter((user) => user.type === role);
      setFilteredUsers(filtered);
    }
  };

  // Handle role change
  const handleRoleChange = (e) => {
    const selectedRole = e.target.value;
    setSelectedRole(selectedRole);
    filterUsersByRole(selectedRole);
  };
useEffect(() => {

    GetAllUsers();
    

}, []);

// State for the modal visibility and selected user
const [isModalOpen, setIsModalOpen] = useState(false);
const [selectedInstructor, setSelectedInstructor] = useState(null);

// Function to handle opening the modal
const openModal = (instructor) => {
  setSelectedInstructor(instructor);
  setIsModalOpen(true);
};

// Function to close the modal
const closeModal = () => {
  setIsModalOpen(false);
  setSelectedInstructor(null);
};


    

    return(
        <>
            <section className='dashboard'>



                <div className='results-table'>
                <Link className='new-item' to='/add-user'> أضف مستخدم جديد </Link>
       {/* Role Filter Dropdown */}
<div className="filter">
  {/* <label htmlFor="role-filter">Filter by role:</label> */}
  <div className="custom-select-wrapper">
    <select
      id="role-filter"
      className="custom-select"
      value={selectedRole}
      onChange={handleRoleChange}
    >
      <option value="all">All</option>
      <option value="user">User</option>
      <option value="admin">Admin</option>
      <option value="inst">Instructor</option>
    </select>
    <i className="fa-solid fa-chevron-down down select-icon"></i>
  </div>
</div>

                <table>

                    <thead>
                        <tr>
                            <th>#</th>
                            <th> الصورة </th>
                            <th> الأسم </th>
                            <th> البريد الإلكتروني </th>
                            <th> النوع </th>
                            <th> الإختيارات </th>

                        </tr>
                    </thead>
                    <tbody>
                    {filteredUsers.length ? (
                    filteredUsers.map((user) => {
                        return (
                        <tr key={user.user_id}>
                            
                            <td>
                            <h6>{user.user_id}</h6>
                            </td>

                            <td><img src={`./uploads/${user.profile_img}`} alt="item Image"/></td>

                            <td>
                            <h6>{user.fullname}</h6>
                            </td>

                            <td>
                            <h6>{user.email}</h6>
                            </td>

                            <td>
                            <h6>{user.type}</h6>
                            </td>



                            <td class='d-flex gap-2 justify-content-between align-items-baseline'> 
                            {/* <Link className='edit' to={`/edit-user?id=${user.user_id}`}> <i class="las la-edit"></i> </Link> */}
                                <button className='delete' onClick={() => deleteItem(user.user_id)}>
                                    <i className="las la-trash-alt"></i>
                                </button>
                                {user.confirmed == "0" ? (
                                    <button className='activate' onClick={() => ActivateUser(user.user_id)}>
                                        <i class="fa-solid fa-user"></i>
                                    </button>
                                ) : (
                                    <button className='diactivate' onClick={() => DiActivateUser(user.user_id)}>
                                         <i class="fa-solid fa-user-slash"></i>
                                    </button>
                                )}
                                 {/* Show the button for instructors */}
                    {user.type === 'inst' && (
                 
                        <button className="show-details" onClick={() => openModal(user)}>
                        <i class="fa-solid fa-eye"></i>
                        </button>
                   
                    )}
                            </td>
                        </tr>
                        );
                    })
                    ) : (
                    <tr>
                        <td colSpan={5}>تحميل...</td>
                    </tr>
                    )}
                    </tbody>
                </table>
    {/* Modal to show instructor data */}
    {selectedInstructor && (
  <div className={`modal ${isModalOpen ? 'modal-open' : ''}`}>
    <div className="modal-content">
      <span className="close" onClick={closeModal}>&times;</span>
      <h2>تفاصيل المدرب</h2>
      
      <p className="d-flex text">
        <i className="fa-solid fa-user"></i> {/* User icon */}
        <strong> الاسم: </strong> {selectedInstructor.fullname}
      </p>
      
      <p>
        <i className="fa-solid fa-envelope"></i> {/* Email icon */}
        <strong> البريد الإلكتروني: </strong> {selectedInstructor.email}
      </p>
      
      <p>
        <i className="fa-solid fa-info-circle"></i> {/* Info icon */}
        <strong> نبذة عن المدرب: </strong> {selectedInstructor.info_text}
      </p>
      
      <p>
        <i className="fa-solid fa-file-alt"></i> {/* CV/Document icon */}
        <strong> السيرة الذاتية: </strong>
        {selectedInstructor.cv_file ? (
          <a 
            href={`../../public/uploads/${selectedInstructor.cv_file}`} 
            //G:\MeedCourseProject\meedcourses\public\uploads\1726689268729.pdf
            download={selectedInstructor.cv_file} 
            target="_blank" 
            rel="noopener noreferrer"   
            className="download-cv"
          >
            تحميل السيرة الذاتية
          </a>
        ) : (
          <span>لا توجد سيرة ذاتية حتى الآن</span>  
        )}
      </p>
    </div>
  </div>
)}



                </div>
            </section>
        
        </>
    )
}

export default AllUsersPage;