import './css/lessonpage.css';
import { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import axios from '../config/index';

function LessonPage() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = parseInt(queryParams.get('id'));
    const courseId = parseInt(queryParams.get('course_id'));
    const [isPlaying, setIsPlaying] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [score, setScore] = useState(0);
    const [showScore, setShowScore] = useState(false);
    const [userFinishedLesson, setUserFinishedLesson] = useState(false);
    const [lessons, setLessons] = useState([]);
    const videoRef = useRef(null);
    const navigate = useNavigate();
    const [videoUrl, setVideoUrl] = useState('');
    const [lessonHeader, setlessonHeader] = useState('');


    const playVideo = () => {
        const video = videoRef.current;
        if (video.paused || video.ended) {
            video.play().then(() => setIsPlaying(true)).catch((error) => {
                console.error('Error playing the video:', error);
            });
        }
    };

    const pauseVideo = () => {
        const video = videoRef.current;
        if (!video.paused && !video.ended) {
            video.pause();
            setIsPlaying(false);
        }
    };

    const handlePlayPause = () => {
        if (isPlaying) {
            pauseVideo();
        } else {
            playVideo();
        }
    };

    useEffect(() => {
        const video = videoRef.current;
        video.src = videoUrl;

        video.addEventListener('loadedmetadata', () => {
            playVideo();
        });

        return () => {
            video.removeEventListener('loadedmetadata', () => {
                playVideo();
            });
        };
    }, [videoUrl]);

    useEffect(() => {
        const getAllLessons = async () => {
            try {
                if (isNaN(courseId)) {
                    console.error('Invalid course_id:', courseId);
                    return;
                }

                const response = await axios.post('/AllItems', { table: 'lessons' });
                const courseLessons = response.data.filter(lesson => lesson.course_id === courseId);

                courseLessons.sort((a, b) => a.id - b.id);
                setLessons(courseLessons);

                const currentLessonIndex = courseLessons.findIndex(lesson => lesson.id === id);
                if (currentLessonIndex === -1) {
                    console.error('Current lesson not found in course lessons');
                    return;
                }

                if (currentLessonIndex === 0) {
                    setUserFinishedLesson(true);
                } else {
                    const previousLessonId = courseLessons[currentLessonIndex - 1].id;
                    const userFinishedResponse = await axios.post('/is-user-finished-this-lesson', { lessonId: previousLessonId });
                    if (!userFinishedResponse.data.finished) {
                        navigate(`/lesson?id=${previousLessonId}&course_id=${courseId}`);
                    } else {
                        setUserFinishedLesson(true);
                    }
                }
            } catch (error) {
                console.error('Error fetching lessons:', error);
            }
        };

        getAllLessons();
    }, [id, courseId, navigate]);

    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const response = await axios.post('/AllItems', { table: 'questions' });
                const lessonQuestions = response.data.filter(question => question.lesson_id === id);
                setQuestions(lessonQuestions);

                if (lessonQuestions.length === 0) {
                    await axios.post('/add-to-finished-lessons', { lessonId: id });
                    console.log('No questions available. Lesson marked as finished');
                }
            } catch (error) {
                console.error('Error fetching questions:', error);
            }
        };

        fetchQuestions();
    }, [id]);

    useEffect(() => {
        const fetchVideoUrl = async () => {
            try {
                const response = await axios.post('/get-item', { itemId: id, table: "lessons" });
                setVideoUrl(`./uploads/${response.data.video}`);
                setlessonHeader(response.data.header)
            } catch (error) {
                console.error('Error fetching video URL:', error);
            }
        };

        fetchVideoUrl();
    }, [id]);

    const handleAnswerClick = (answer) => {
        if (answer === questions[currentQuestionIndex].correct_answer) {
            setScore(score + 1);
        }

        const nextQuestion = currentQuestionIndex + 1;
        if (nextQuestion < questions.length) {
            setCurrentQuestionIndex(nextQuestion);
        } else {
            setShowScore(true);
            if (score >= questions.length / 2) {
                axios.post('/add-to-finished-lessons', { lessonId: id })
                    .then(() => console.log('Lesson marked as finished'))
                    .catch(error => console.error('Error marking lesson as finished:', error));
            }
        }
    };

    const currentLessonIndex = lessons.findIndex(lesson => lesson.id === id);
    const previousLessonId = currentLessonIndex > 0 ? lessons[currentLessonIndex - 1].id : null;
    const nextLessonId = currentLessonIndex < lessons.length - 1 ? lessons[currentLessonIndex + 1].id : null;

    const handleNextLessonClick = () => {
        if (userFinishedLesson) {
            // Reset state variables
            setQuestions([]);
            setCurrentQuestionIndex(0);
            setScore(0);
            setShowScore(false);
            // Navigate to the next lesson
            navigate(`/lesson?id=${nextLessonId}&course_id=${courseId}`);
        }
    };

    return (
        <section className='lesson-page'>
            <div className='container'>
                <div className='lesson-content'>
                    <div className='lesson'>
                        <div className='lesson-video'>
                            <div className='video'>
                                <video controls ref={videoRef} onClick={handlePlayPause}></video>
                                {!isPlaying && (
                                    <button className='play-video' onClick={handlePlayPause}>
                                        <i className="las la-play"></i>
                                    </button>
                                )}
                            </div>
                        </div>

                        <h1> {lessonHeader} </h1>

                        <div className='questions'>
                            <span className='out-of'> {currentQuestionIndex + 1} / {questions.length} </span>
                            {showScore ? (
                                <div className='score'>
                                    <h2> النتيجة {score} من {questions.length}</h2>
                                    <h3> يجب ان تحقق على الأقل نصف النتيجة للانتقال الى الدرس التالي <i className="las la-info"></i> </h3>
                                </div>
                            ) : (
                                questions.length > 0 && (
                                    <div className='question'>
                                        <h2> {questions[currentQuestionIndex]?.header || 'No question available'} </h2>
                                        <ul className='answers'>
                                            {questions[currentQuestionIndex]?.answer_1 && (
                                                <li>
                                                    <button className='answer' onClick={() => handleAnswerClick(questions[currentQuestionIndex].answer_1)}>
                                                        {questions[currentQuestionIndex].answer_1}
                                                    </button>
                                                </li>
                                            )}
                                            {questions[currentQuestionIndex]?.answer_2 && (
                                                <li>
                                                    <button className='answer' onClick={() => handleAnswerClick(questions[currentQuestionIndex].answer_2)}>
                                                        {questions[currentQuestionIndex].answer_2}
                                                    </button>
                                                </li>
                                            )}
                                            {questions[currentQuestionIndex]?.answer_3 && (
                                                <li>
                                                    <button className='answer' onClick={() => handleAnswerClick(questions[currentQuestionIndex].answer_3)}>
                                                        {questions[currentQuestionIndex].answer_3}
                                                    </button>
                                                </li>
                                            )}
                                            {questions[currentQuestionIndex]?.answer_4 && (
                                                <li>
                                                    <button className='answer' onClick={() => handleAnswerClick(questions[currentQuestionIndex].answer_4)}>
                                                        {questions[currentQuestionIndex].answer_4}
                                                    </button>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                )
                            )}
                        </div>

                        {previousLessonId && (
                            <RouterLink to={`/lesson?id=${previousLessonId}&course_id=${courseId}`} className='btn'>
                                الدرس السابق
                            </RouterLink>
                        )}

                        {nextLessonId &&  (score >= questions.length / 2) || (questions.length === 0) ? (
                            <RouterLink
                                to={`/lesson?id=${nextLessonId}&course_id=${courseId}`}
                                className='btn'
                                onClick={handleNextLessonClick}
                                disabled={!showScore || score < questions.length / 2}
                            >
                                الدرس التالي
                            </RouterLink>
                        ): 
                        <RouterLink>
                                يرجى مشاهدة الدرس وحل الاسالة لفتح الدرس التالي
                            </RouterLink>}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default LessonPage;