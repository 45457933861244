import './css/dashboardtable.css';
import React, { useState,useEffect} from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';




function AllSubscribers(){


        
    useEffect(() => {
        axios
    .get('/admin-session')
    .then((res) => {
        if (res.data.validadmin) {

            if(res.data.type == "admin"){

            }else{
                navigate('/admin-login');
            }

        } else {

        }
    })
    .catch((err) => console.log(err));
    },[]);



    
    const navigate = useNavigate();


    const [AllItems, setAllItems] = useState([]);

    axios.defaults.withCredentials = true;



    

    const getAllItems = ()=>{
        axios.post('AllItems', {
            table: 'subscribers',
        })
        .then((res) => {
            if (res.data) {
                setAllItems(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    





    const getIfLogin= ()=>{
        
    axios
    .get('/session')
    .then((res) => {
        if (!res.data.validadmin) {
            navigate('/admin-login');

        }else{
            console.log(res.data.validadmin);

        }
    })
    .catch((err) => console.log(err));

    }





    const deleteItem = (id) => {
        axios
        .delete(`delete-item/${id}`, {
            data: {
            table: 'subscribers',
            },
        })
        .then((response) => {
            if (response.data.message === 'Item deleted successfully') {
                getAllItems();
            } else {
            console.error('Failed to delete result:', response.data.message);
            }
        })
        .catch((error) => {
            console.error('Error deleting result:', error);
        });
    };




useEffect(() => {

    getAllItems();
    

}, []);




    

    return(
        <>
            <section className='dashboard'>



                <div className='results-table'>
                <table>

                    <thead>
                        <tr>
                            <th> # </th>
                            <th> الاسم </th>
                            <th> البريد الإلكتروني </th>
                            <th> رقم الهاتف </th>
                            <th> الإختيارات </th>

                        </tr>
                    </thead>
                    <tbody>
                    {AllItems.length ? (
                    AllItems.map((item) => {
                        return (
                        <tr key={item.id}>
                            
                            <td>
                            <h6>{item.id}</h6>
                            </td>

                            <td>
                            <h6>{item.name}</h6>
                            </td>

                            <td>
                            <h6>{item.email}</h6>
                            </td>

                            <td>
                            <h6>{item.phonenumber}</h6>
                            </td>


                            <td>
                            <button className='delete' onClick={() => deleteItem(item.id)}>
                                <i className="las la-trash-alt"></i>
                            </button>
                            </td>
                        </tr>
                        );
                    })
                    ) : (
                    <tr>
                        <td colSpan={5}>Loading...</td>
                    </tr>
                    )}
                    </tbody>
                </table>

                </div>
            </section>
        
        </>
    )
}

export default AllSubscribers;