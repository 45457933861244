import './css/instdash.css';
import { Link as RouterLink,useNavigate } from 'react-router-dom';
import InstImg from './images/instructors/1.jpg';

import CourseImg1 from './images/courses/1.jpg';
import CourseImg2 from './images/courses/2.jpg';
import CourseImg3 from './images/courses/3.jpg';

import InstImg1 from './images/smusers/1.png';
import InstImg2 from './images/smusers/2.png';
import InstImg3 from './images/smusers/3.png';
import BooksImages from './images/books.webp';
import axios from '../config/index';

import { useState,useEffect } from 'react';


const courseData = [
    {
        img: CourseImg1,
        reviews: 15,
        title: 'React من الأمام إلى الخلف',
        lessons: 20,
        students: 50,
        description: 'React Js حقيقة طويلة أن القارئ سيشتت انتباهه بسبب القراءة المقروءة.',
        instructor: { img: InstImg1, name: 'عبد الرحمن الصاوي' },
        price: { current: 60, original: 120 },
        link: '/course'
    },
    {
        img: CourseImg2,
        reviews: 23,
        title: 'PHP المبتدئ + المتقدم',
        lessons: 14,
        students: 80,
        description: 'إنها حقيقة مثبتة منذ زمن طويل أن القارئ سيشتت انتباهه بسبب القراءة المقروءة.',
        instructor: { img: InstImg2, name: 'عبد الرحمن الصاوي' },
        price: { current: 40, original: 70 },
        link: '/course'
    },
    {
        img: CourseImg3,
        reviews: 70,
        title: 'Angular من الصفر إلى الاحتراف',
        lessons: 9,
        students: 90,
        description: 'Angular Js حقيقة طويلة أن القارئ سيشتت انتباهه بسبب القراءة المقروءة.',
        instructor: { img: InstImg3, name: 'عبد الرحمن الصاوي' },
        price: { current: 60, original: 120 },
        link: '/course'
    }
];



function InstDash(){



    const navigate = useNavigate();
    const [loggedin, setLoggedIn] = useState(false);
    const [userId, setUserId] = useState(false);
    const [usertype, setUsertype] = useState(false);
    const [user, setUser] = useState([]);
    const [courses, setCourses] = useState([]);
    const [userCourses, setUserCourses] = useState([]);
    const [activeCount, setActiveCount] = useState(0);
    const [notActiveCount, setNotActiveCount] = useState(0);
    const [filtredCoursesCount, setFilteredCoursesCount] = useState(0);
    const [totalEnrolledStudents, setTotalEnrolledStudents] = useState(0);
    const [lessons, setLessons] = useState([]);
    const Stars = Array(user.rate || 5).fill(null);

    useEffect(() => {
        axios.get('/session')
            .then((res) => {
                if (res.data.valid && res.data.type === "inst") {
                    setUserId(res.data.userId);
                    setUsertype(res.data.type);
                } else {
                    navigate("/");
                }
            })
            .catch((err) => console.log(err));
    }, [navigate]);

    const getItemData = (itemId) => {
        axios.post('/get-user', { itemId })
            .then((res) => {
                if (!res.data.message) {
                    setUser(res.data);
                }
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        if (userId) {
            getItemData(userId);
        }
    }, [userId]);

    const getAllCourses = () => {
        axios.post('AllItems', { table: 'courses' })
            .then((res) => {
                if (res.data) {
                    setCourses(res.data);
                } else {
                    console.log("Error happened");
                }
            })
            .catch((err) => console.log(err));
    };

    const getAllLessons = () => {
        axios.post('AllItems', { table: 'lessons' })
            .then((res) => {
                if (res.data) {
                    setLessons(res.data);
                } else {
                    console.log("Error happened");
                }
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        getAllCourses();
        getAllLessons();
    }, []);

    useEffect(() => {
        if (courses.length > 0 && lessons.length > 0) {
            addLessonCountToCourses();
        }
    }, [courses, lessons]);

    const addLessonCountToCourses = () => {
        const coursesWithLessons = courses.map(course => {
            const lessonCount = lessons.filter(lesson => lesson.course_id === course.id).length;
            return { ...course, lessonCount };
        });
        filterCourses(coursesWithLessons);
    };

    const filterCourses = (allCourses) => {
        if (allCourses.length > 0) {
            const filteredCourses = allCourses.filter(course => course.user_id === userId);
            const filtredCoursesCount = filteredCourses.length;
            const activeCoursesCount = filteredCourses.filter(course => course.status === 'active').length;
            const notActiveCoursesCount = filteredCourses.filter(course => course.status !== 'active').length;
            const enrolledStudentsSum = filteredCourses.reduce((sum, course) => sum + (course.enrolled_students || 0), 0);

            setUserCourses(filteredCourses);
            setActiveCount(activeCoursesCount);
            setNotActiveCount(notActiveCoursesCount);
            setFilteredCoursesCount(filtredCoursesCount);
            setTotalEnrolledStudents(enrolledStudentsSum);
        }
    };

    useEffect(() => {
        filterCourses(courses);
    }, [courses]);

    return(
        <>
            <section className='inst-dash'>
                <div className='dash-header'>
                    <div className='inst-header'>
                    
                    <div className='background-colored'>
                    
                    </div>

                    <div className='inst-card'>
                    <img  src={BooksImages} alt='books'/>
                        <h2> مدرب في مييد </h2>

                        <h1>  تعلم الان مع {user.fullname} </h1>

                        <h3> إعمل - تعلم - سافر </h3>

                        <div className='info'>
                            <img src={`./uploads/${user.profile_img}`} alt='instructor image'/>

                            <div className='info-data'>
                                <h4> {user.fullname} </h4>
                                <div className='reviews'>
                                    <ul>
                                        {Stars.map((_, i) => (
                                            <li key={i}>
                                                <i className="las la-star"></i>
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                                <div className='courses-stu'>

                                    <span> <i class="las la-book"></i> {filtredCoursesCount} كورس  </span>

                                    <span> <i class="las la-users"></i> {totalEnrolledStudents} طالب  </span>

                                </div>
                            </div>
                        </div>

                        <div className='add-course-btn'>
                            <RouterLink to="/add-course">
                            <i class="las la-arrow-left"></i>
                                أضف كورس جديد 
                            </RouterLink>
                        </div>
                    </div>
                </div>
                </div>



                <div className='user-dashboard'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                                <div className='user-side-nav'>
                                    <h2> أهلا وسهلا {user.fullname} </h2>

                                    <ul>
                                        <li>
                                            <RouterLink to="/instructor-dashboard"> لوحة التحكم <i class="las la-home"></i> </RouterLink>
                                        </li>

                                        <li>
                                            <RouterLink to={`/instructor-profile?id=${user.user_id}`}> الملف الشخصي <i class="las la-user"></i> </RouterLink>
                                        </li>

                                        <li>
                                            <RouterLink to="/user-settings"> الإعدادات <i class="las la-cog"></i></RouterLink>
                                        </li>


                                    </ul>
                                </div>
                            </div>

                            <div className='col-lg-8 col-md-8 col-sm-12 col-12'>
                                <div className='dash-content'>
                                    <div className='row'>
                                        <div className='col-lg-3 col-md-4 col-sm-12 col-12'>
                                            <div className='number'>
                                            <i class="las la-swatchbook"></i>
                                                <h1> {filtredCoursesCount}  </h1>

                                                <h4> الكورسات الخاص بي </h4>
                                            </div>
                                        </div>


                                        <div className='col-lg-3 col-md-4 col-sm-12 col-12'>
                                            <div className='number'>
                                            <i class="las la-check-circle"></i>
                                                <h1> {activeCount}  </h1>

                                                <h4> الكورسات المفعله </h4>
                                            </div>
                                        </div>

                                        
                                        <div className='col-lg-3 col-md-4 col-sm-12 col-12'>
                                            <div className='number'>
                                            <i class="las la-clock"></i>
                                                <h1> {notActiveCount}  </h1>

                                                <h4> الكورسات غير مفعله </h4>
                                            </div>
                                        </div>

                                        
                                        <div className='col-lg-3 col-md-4 col-sm-12 col-12'>
                                            <div className='number'>
                                            <i class="las la-users"></i>
                                                <h1> {totalEnrolledStudents}  </h1>

                                                <h4> طلابي </h4>
                                            </div>
                                        </div>

                                    


                                    </div>

                                    
                                    <div className='int-courses'>
                                        <div className='row'>
                                        {userCourses.length?(
                                        userCourses.map((course, index) => (
                                                <div className='col-lg-6 col-md-6 col-sm-12 col-12' key={index}>
                                                <div className='course-card'>
                                                    <RouterLink to={`/course?id=${course.id}`}>
                                                        <img src={`./uploads/${course.img}`} alt='course image' />
                                                    </RouterLink>
                                                    <div className='text'>
                                                        <div className='reviews'>
                                                            <ul>
                                                                {Stars.map((_, i) => (
                                                                    <li key={i}>
                                                                        <i className="las la-star"></i>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                        <h1>{course.header}</h1>
                                                        <div className='course-info'>
                                                            <span><i className="las la-book"></i> {course.lessonCount } دروس</span>
                                                            <span><i className="las la-users"></i> {course.enrolled_students} طلاب</span>
                                                        </div>
                                                        <p className='section-text'>{course.description}</p>
                                                        <div className='instructor-info'>
                                                        <img src={`./uploads/${user.profile_img}`} alt='instructor image'/>
                                                        <h4>بواسطة <span>{user.fullname}</span></h4>
                                                        </div>

                                                        <div className='course-price'>
                                                            {course.free == 1 ? <h3> مجاني </h3> :<>
                                                                <h3>${course.price} <span>${course.prev_price}</span></h3>
                                                            </>}
                                                            <RouterLink to={`/edit-course?id=${course.id}`}>  اضافة الشروحات والأساله  <i className="las la-arrow-right"></i></RouterLink>

                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                           ))
                            ) : <></>}
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </> 
    )
}

export default InstDash;