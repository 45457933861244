import './css/dashboardtable.css';
import React, { useState,useEffect} from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';




function AllLanding(){


        
    useEffect(() => {
        axios
    .get('admin-session')
    .then((res) => {
        if (res.data.validadmin) {

            if(res.data.type == "admin"){

            }else{
                navigate('/admin-login');
            }

        } else {

        }
    })
    .catch((err) => console.log(err));
    },[]);



    
    const navigate = useNavigate();
    
    const [AllLanding, setAllLanding] = useState([]);

    axios.defaults.withCredentials = true;





    const GetAllLanding = ()=>{
        axios.post('AllItems', {
            table: 'landing',
        })
        .then((res) => {
            if (res.data) {
                setAllLanding(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    








useEffect(() => {

    GetAllLanding();
    

}, []);




    

    return(
        <>
            <section className='dashboard'>



                <div className='results-table'>
                <table>

                    <thead>
                        <tr>
                            <th>#</th>
                            <th> العنوان   </th>
                            <th> النص  </th>

                            <th>الخيارات</th>

                        </tr>
                    </thead>
                    <tbody>
                    {AllLanding.length ? (

                        AllLanding.map(landing => {

                        return (
                            <tr key={landing.id}>
                            <td><h6>{landing.id}</h6></td>

                            <td><h2>{landing.header}</h2></td>

                            <td><h2>{landing.para}</h2></td>


                            <td>
                                <Link className='edit' to={`/edit-landing?id=${landing.id}`}> <i class="las la-edit"></i> </Link>
                            </td>


                            </tr>
                        );
                        })): 
                        
                        
                        
                        (
                            <tr>
                                <td colSpan={5}>تحميل...</td>
                            </tr>
                            )}
                    </tbody>
                </table>

                </div>
            </section>
        
        </>
    )
}

export default AllLanding;