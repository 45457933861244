import './css/home-register.css';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import axios from '../config/index';
import HomeRegisterImg from './images/home/home-register.png';

function HomeRegister() {
    const navigate = useNavigate();

    const [loginEmail, setLoginEmail] = useState('');
    const [loginPassword, setLoginPassword] = useState('');
    const [registerEmail, setRegisterEmail] = useState('');
    const [registerName, setRegisterName] = useState('');
    const [registerPassword, setRegisterPassword] = useState('');
    const [registerConfirmPassword, setRegisterConfirmPassword] = useState('');
    const [registerType, setRegisterType] = useState('user');
    const [loading, setLoading] = useState(false); // Add loading state

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true); // Show loading screen
        try {
            const response = await axios.post('/login', {
                email: loginEmail,
                password: loginPassword
            });
            navigate('/');
        } catch (error) {
            alert(error.response.data.message);
        } finally {
            setLoading(false); // Hide loading screen
        }
    };

    const handleRegister = async (e) => {
        e.preventDefault();
        if (registerPassword !== registerConfirmPassword) {
            alert('Passwords do not match');
            return;
        }
        setLoading(true); // Show loading screen
        try {
            const response = await axios.post('/signup', {
                fullname: registerName,
                email: registerEmail,
                password: registerPassword,
                type: 'user'
            });
            navigate('/confirm-email');
        } catch (error) {
            alert(error.response.data.message);
        } finally {
            setLoading(false); // Hide loading screen
        }
    };

    return (
        <>
            {loading && (
                <div className='loading-screen'>
                    <div className="loader"></div>
                </div>
            )}
            <section className='login-register'>
                <div className='container'>
                    <div className='row gap-5 justify-content-between '>
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <img src={HomeRegisterImg} alt="Home registration"  className='register-img'/>         
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='form-element'>
                            <p className='headline'>  إنشاء الحساب</p> 
                            <p className='title'>  انطلق الان نحو افضل اسثمار 
                            في حياتك </p> 
                                           <form onSubmit={handleRegister}>
                                    <div className='inputs'>
                                        <div className='input'>
                                            <input
                                                type='email'
                                                placeholder='البريد الإلكتروني'
                                                required
                                                value={registerEmail}
                                                onChange={(e) => setRegisterEmail(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='inputs'>
                                        <div className='input'>
                                            <input
                                                type='text'
                                                placeholder='الاسم'
                                                required
                                                value={registerName}
                                                onChange={(e) => setRegisterName(e.target.value)}
                                            />
                                        </div>
                                    </div> 
                                    <div className='inputs'>
                                        <div className='input'>
                                            <input
                                                type='password'
                                                placeholder='كلمة المرور'
                                                required
                                                value={registerPassword}
                                                onChange={(e) => setRegisterPassword(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='inputs'>
                                        <div className='input'>
                                            <input
                                                type='password'
                                                placeholder='تأكيد كلمة المرور'
                                                required
                                                value={registerConfirmPassword}
                                                onChange={(e) => setRegisterConfirmPassword(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <button type='submit' className='link-btn-color'>
                                        تسجيل جديد <i className='las la-arrow-right'></i>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HomeRegister;
