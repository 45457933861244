import './css/dashboardtable.css';
import './css/editform.css';
import React, { useState,useEffect} from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';
import ShowButton from '../ShowButton';






function AddCategory(){


        






    const navigate = useNavigate();


    
    const [header, setHeader] = useState("");
    
    const [img, setImg] = useState("");
    const [icon, setIcon] = useState("");


    const handleSubmit = (event) => {
        event.preventDefault();

        let Loading = document.querySelector(".loading-screen"); 
        Loading.classList.add("active");

        const data = new FormData();

                
        data.append("header", header || "");
        if (img) {
            data.append("img", img);
        }
        if (icon) {
            data.append("icon", icon);
        }
    
    
        const config = {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        };
    
        axios
        .post("/add-category", data, config)
        .then((response) => {
            
            Loading.classList.remove("active");

            let alertS = document.querySelector(".alert-success"); 
            alertS.classList.add("active");


            setTimeout(() => {
                alertS.classList.remove("active");
            }, 3000);


        })
        .catch((error) => {
            
            Loading.classList.remove("active");

            let alertD = document.querySelector(".alert-danger"); 
            alertD.classList.add("active");
            setTimeout(() => {
                alertD.classList.remove("active");
            }, 3000);
            console.log(error);
        });
    };
    


    axios.defaults.withCredentials = true;






    useEffect(() => {
    }, []);
    




    

    return(
        <>

                    <div className='loading-screen'>
                            <div class="loader">
                                <div class="outer"></div>
                                <div class="middle"></div>
                                <div class="inner"></div>
                            </div>

                        </div>
            <section className='dashboard'>



                <div className='edit-form'>

                    <div className='alert-success'>
                        <h1>  تمت الإضافة بنجاح  <i class="las la-check-circle"></i></h1>
                    </div>

                    <div className='alert-danger'>
                        <h1> فشل في الإضافة <i class="las la-exclamation-triangle"></i></h1>

                    </div>

                    <h1> إضافة القسم </h1>

                    <form onSubmit={handleSubmit}>


                    
                    <div className='input'>
                        <label> العنوان  </label>
                        <input  onChange={(event) => setHeader(event.target.value)} type='text' required="required"  maxLength="230" name='header'/>
                    </div>
                    

                    <div className='input'>
                        <label> الصورة (لا تتعدى الصورة الواحد ميجا) </label>
                        <input onChange={(event) => setImg(event.target.files[0])} type='file' name="img" accept="image/*"/>
                    </div>

                    <div className='input'>
                        <label> الأيقونة (لا تتعدى الأيقونة الواحد ميجا) </label>
                        <input onChange={(event) => setIcon(event.target.files[0])} type='file' name="icon" accept="image/*"/>
                    </div>



                
                    <button type='submit'> إضافة </button>

                    </form>
                </div>
            </section>
        
        </>
    )
}

export default AddCategory;