import './css/Payment.css';
import { Link as RouterLink } from 'react-router-dom';
import axios from '../config/index';
import { useState, useEffect } from 'react';
function Payment() {

    const [socails, setsocails] = useState([]);


    
    const getAllSocials = (itemId) => {
        const table = "socials";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (!res.data.message) {
                setsocails(res.data);
            }
        }).catch((err) => console.log(err));
    };

    useEffect(() => {
        getAllSocials(1);
    }, []);

    return (
        <section className='land-contact'>
            <div className='container'>
                <div className='text'>
                    <h1> ابدأ رحلتك التعليمية مع دورات ميد اليوم </h1>
                    <p>
                        مجتمع داعم: انضم إلى مجتمع من المتعلمين واحصل على الدعم من الزملاء والمدربين.
                    </p>
                    <div>
                        <a href={`mailto:${socails.email}`} className="link-btn-midcolor">
                            أرسل بريد إلكتروني
                        </a>
                        <a href={`${socails.whatsapp}`} className='link-btn-midcolor'>
                            أرسل رسالة واتساب
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Payment;
