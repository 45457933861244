import './css/dashboardtable.css';
import React, { useState, useEffect, useRef } from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';

function AllCourses() {
    const navigate = useNavigate();
    const [AllItems, setAllItems] = useState([]);
    const [Users, setUsers] = useState([]);  // Initialize as an array

    axios.defaults.withCredentials = true;

    useEffect(() => {
        axios.get('admin-session')
            .then((res) => {
                if (res.data.validadmin) {
                    if (res.data.type !== "admin") {
                        navigate('/admin-login');
                    }
                }
            })
            .catch((err) => console.log(err));
        
        getAllItems();
        getAllUsers();
    }, []);

    const getAllItems = () => {
        axios.post('AllItems', { table: 'courses' })
            .then((res) => {
                if (res.data) {
                    setAllItems(res.data);
                } else {
                    console.log("Error happened");
                }
            })
            .catch((err) => console.log(err));
    };

    const getAllUsers = () => {
        axios.post('AllUsers', { table: 'users' })
            .then((res) => {
                if (res.data) {
                    setUsers(res.data);
                } else {
                    console.log("Error happened");
                }
            })
            .catch((err) => console.log(err));
    };

    const getIfLogin = () => {
        axios.get('/admin-session')
            .then((res) => {
                if (!res.data.validadmin) {
                    navigate('/admin-login');
                }
            })
            .catch((err) => console.log(err));
    };

    const deleteItem = (id) => {
        axios.delete(`delete-item/${id}`, { data: { table: 'courses' } })
            .then((response) => {
                if (response.data.message === 'Item deleted successfully') {
                    getAllItems();
                } else {
                    let alertD = document.querySelector(".alert-danger"); 
                    alertD.classList.add("active");
                    setTimeout(() => {
                        alertD.classList.remove("active");
                    }, 3000);

                    console.error('Failed to delete result:', response.data.message);
                }
            })
            .catch((error) => {
                let alertD = document.querySelector(".alert-danger"); 
                alertD.classList.add("active");
                setTimeout(() => {
                    alertD.classList.remove("active");
                }, 3000);

                console.error('Error deleting result:', error);
            });
    };

    const ActivateItem = (id) => {
        axios.put(`activate-item/${id}`, { table: 'courses' })
            .then((response) => {
                if (response.data.message === 'Item activated successfully') {
                    getAllItems();
                } else {
                    console.error('Failed to activate result:', response.data.message);
                }
            })
            .catch((error) => {
                console.error('Error activating result:', error);
            });
    };
    

    const DiActivateItem = (id) => {
        axios.put(`diactivate-item/${id}`, { table: 'courses' })
            .then((response) => {
                if (response.data.message === 'Item diactivated successfully') {
                    getAllItems();
                } else {
                    console.error('Failed to diactivate result:', response.data.message);
                }
            })
            .catch((error) => {
                console.error('Error diactivating result:', error);
            });
    };

    const handleFullScreen = (videoElement) => {
        if (videoElement.requestFullscreen) {
            videoElement.requestFullscreen();
        } else if (videoElement.mozRequestFullScreen) { // Firefox
            videoElement.mozRequestFullScreen();
        } else if (videoElement.webkitRequestFullscreen) { // Chrome, Safari and Opera
            videoElement.webkitRequestFullscreen();
        } else if (videoElement.msRequestFullscreen) { // IE/Edge
            videoElement.msRequestFullscreen();
        }
    };

    return (
        <>
            <section className='dashboard'>
                <div className='results-table'>
                    <div className='alert-danger'>
                        <h1> لا يمكنك حذف هذا الكورس لأنه مربوط بدروس يرجى حذفها اولا  <i className="las la-exclamation-triangle"></i></h1>
                    </div>

                    <table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>الفيديو (إضغط للمشاهدة)</th>
                                <th>العنوان</th>
                                <th>اسم المالك</th>
                                <th>البريد الخاص بالمالك</th>
                                <th>الطلاب المسجلين</th>
                                <th>السعر</th>
                                <th>السعر قبل الخصم</th>
                                <th>اللغة</th>
                                <th>المدة</th>
                                <th>المستوى</th>
                                <th>مجاني؟</th>
                                <th>التاريخ</th>
                                <th>الخيارات</th>
                            </tr>
                        </thead>
                        <tbody>
                            {AllItems.length ? (
                                AllItems.map((item) => {
                                    const user = Users.find(u => u.user_id === item.user_id) || {};
                                    return (
                                        <tr key={item.id}>
                                            <td><h6>{item.id}</h6></td>
                                            <td>
                                                <video
                                                    controls
                                                    src={`./uploads/${item.intro_video}`}
                                                    onClick={(e) => handleFullScreen(e.currentTarget)}
                                                ></video>
                                            </td>
                                            <td><h6>{item.header}</h6></td>
                                            <td><h6>{user.fullname}</h6></td>
                                            <td><h6>{user.email}</h6></td>
                                            <td><h6>{item.enrolled_students}</h6></td>
                                            <td><h6>{item.price}</h6></td>
                                            <td><h6>{item.prev_price}</h6></td>
                                            <td><h6>{item.language}</h6></td>
                                            <td><h6>{item.duration}</h6></td>
                                            <td><h6>{item.level}</h6></td>
                                            <td><h6>{item.free === "1" ? "مجاني" : "ليس مجاني"}</h6></td>
                                            <td><h6>{item.date}</h6></td>
                                            <td>
                                                <button className='delete' onClick={() => deleteItem(item.id)}>
                                                    <i className="las la-trash-alt"></i>
                                                </button>
                                                {item.status === "notactive" ? (
                                                    <button className='activate' onClick={() => ActivateItem(item.id)}>
                                                        تفعيل
                                                    </button>
                                                ) : (
                                                    <button className='diactivate' onClick={() => DiActivateItem(item.id)}>
                                                        اللغاء التفعيل
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={14}>Loading...</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    );
}

export default AllCourses;
