import React, { useState, useRef, useEffect } from 'react';
import './css/addcourse.css';
import './css/editcourse.css';
import axios from '../config/index';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';

function EditCourse() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const [course, setCourse] = useState({});
    const navigate = useNavigate();
    const [userId, setUserId] = useState();
    const [usertype, setUsertype] = useState();
    const [user, setUser] = useState({});
    const [currentScreen, setCurrentScreen] = useState('paid');
    const [uploadedFile, setUploadedFile] = useState(null);
    const [uploadedVideo, setUploadedVideo] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [questions, setQuestions] = useState([]);
    const fileInputRef = useRef(null);
    const [lessonHeader, setLessonHeader] = useState('');
   
   
    
    const handleVideoUpload = (e) => {
        const file = e.target.files[0]; 
        if (file) {
            const videoUrl = URL.createObjectURL(file); 
            setUploadedVideo(file);  
            setCourse({ ...course, intro_video: videoUrl }); 
        }
    };
    useEffect(() => {
        axios.get('/session').then((res) => {
            if (res.data.valid && res.data.type === "inst") {
                setUserId(res.data.userId);
                setUsertype(res.data.type);
            } else {
                navigate("/");
            }
        }).catch((err) => console.log(err));
    }, [navigate]);

    const getCourse = (itemId) => {
        const table = "courses";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (!res.data.message) {
                setCourse(res.data);
            }
        }).catch((err) => console.log(err));
    };

    useEffect(() => {
        if (id) {
            getCourse(id);
        }
    }, [id]);

    const getItemData = (itemId) => {
        axios.post('/get-user', { itemId }).then((res) => {
            if (!res.data.message) {
                setUser(res.data);
            }
        }).catch((err) => console.log(err));
    };

    useEffect(() => {
        if (userId) {
            getItemData(userId);
        }
    }, [userId]);

    useEffect(() => {
        if (course.user_id && userId) {
            setTimeout(() => {
                if (course.user_id !== userId) {
                    navigate("/");
                }
            }, 1000);
        }
    }, [userId, course, id, navigate]);

    useEffect(() => {
        setTimeout(() => {
            if (course.free === "1") {
                setCurrentScreen("free");
            } else {
                setCurrentScreen("paid");
            }
        }, 1000);
    }, [userId, course, id]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setUploadedFile(file);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        handleFileChange({ target: { files: [file] } });
    };

    const handleDivClick = () => {
        fileInputRef.current.click();
    };

    const addNewQuestion = () => {
        setQuestions([
            ...questions,
            {
                question: '',
                answers: ['', '', '', ''],
                correctAnswer: ''
            }
        ]);
    };

    const handleQuestionChange = (index, value) => {
        const newQuestions = [...questions];
        newQuestions[index].question = value;
        setQuestions(newQuestions);
    };

    const handleAnswerChange = (questionIndex, answerIndex, value) => {
        const newQuestions = [...questions];
        newQuestions[questionIndex].answers[answerIndex] = value;
        setQuestions(newQuestions);
    };

    const handleCorrectAnswerChange = (index, value) => {
        const newQuestions = [...questions];
        newQuestions[index].correctAnswer = value;
        setQuestions(newQuestions);
    };

    const CHUNK_SIZE = 1024 * 1024 * 5; // 5MB

const uploadChunk = async (file, start, end, chunkIndex) => {
    const chunk = file.slice(start, end);
    console.log(chunk)
    const formData = new FormData();
    formData.append('chunk', chunk);
    formData.append('chunkIndex', chunkIndex);
    formData.append('filename', file.name);
    
    const maxRetries = 3;
    let attempt = 0;
    let success = false;

    while (attempt < maxRetries && !success) {
        try {
            await axios.post('/upload-chunk', formData, {
                onUploadProgress: progressEvent => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(prevProgress => ({
                        ...prevProgress,
                        [chunkIndex]: percentCompleted
                    }));
                }
            });
            success = true;
        } catch (error) {
            attempt++;
            if (attempt === maxRetries) {
                throw error;
            }
        }
    }
};

const handleSubmitLesson = async () => {
    $(".loading-screen").addClass("active");

    setUploading(true);
    const lessonFile = fileInputRef.current.files[0];
    if (!lessonFile) {
        alert('Please select a lesson file.');
        setUploading(false);
        $(".loading-screen").removeClass("active");
        return;
    }

    if (!lessonHeader) {
        alert('Please select a lesson Header.');
        setUploading(false);
        $(".loading-screen").removeClass("active");
        return;
    }

    const totalChunks = Math.ceil(lessonFile.size / CHUNK_SIZE);

    console.log(totalChunks)
    const uploadPromises = [];

    for (let i = 0; i < totalChunks; i++) {

        const start = i * CHUNK_SIZE;
        const end = Math.min(start + CHUNK_SIZE, lessonFile.size); // Ensure the last chunk is handled correctly
        console.log(start);
        console.log(end);
        console.log(i);


        uploadPromises.push(uploadChunk(lessonFile, start, end, i));
        console.log(uploadPromises);
        
    }

    try {
        await Promise.all(uploadPromises);

        const lessonFormData = new FormData();
        lessonFormData.append('filename', lessonFile.name);
        lessonFormData.append('header', lessonHeader);
        lessonFormData.append('course_id', id);

        const questionsData = questions.map(question => ({
            question: question.question,
            answers: question.answers,
            correctAnswer: question.correctAnswer
        }));
        lessonFormData.append('questions', JSON.stringify(questionsData));

        await axios.post('/finalize-upload', lessonFormData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        $(".loading-screen").removeClass("active");
        alert('Lesson and questions added successfully!');
        setUploadProgress({});
        setUploading(false);
        setQuestions([]);
        fileInputRef.current.value = null;
    } catch (error) {
        $(".loading-screen").removeClass("active");
        console.error('Error finalizing upload:', error);
        alert('Error finalizing upload. Please try again.');
        setUploading(false);
    }
};
    

    const handleUpdateCourse = () => {
        const courseData = {
            id: course.id,
            header: course.header,
            sec_header: course.sec_header,
            duration: course.duration,
            level: course.level,
            description: course.description,
            requirments: course.requirments,
            free: course.free,
            price: course.price,
        };

        const courseFormData = new FormData();
        for (const key in courseData) {
            courseFormData.append(key, courseData[key]);
        }

        if (uploadedFile) {
            courseFormData.append('img', uploadedFile);
        }
    // If there's an uploaded video file, append it to the form data
    if (uploadedVideo) {
        courseFormData.append('intro_video', uploadedVideo);
    }
        axios.put('/update-course', courseFormData).then(() => {
            alert('Course updated successfully!');
        }).catch(err => {
            console.error(err);
            alert('Failed to update course.');
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCourse(prevCourse => ({
            ...prevCourse,
            [name]: value
        }));
    };
    const handleNavigation = () => {
        const newRoute = `/edit-lesson?courseId=${course.id}`; // Define your new route here
        window.open(newRoute, '_blank'); // Opens the route in a new tab
    };
    const uploadProgressArray = Object.values(uploadProgress).filter(val => typeof val === 'number');
    console.log(course.intro_video , 'viiiiiiiiiiid');
    console.log(course.id , 'iiiiiiiiiiid');
    return (
        <>
        <div className='loading-screen'>
                <div class="loader">
                    <div class="outer"></div>
                    <div class="middle"></div>
                    <div class="inner"></div>
                </div>

            </div>
        <section className='add-course'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                        <div className='course-tip'>
                            <h1>الملحوظات</h1>
                            <ul>
                                <li>تعيين سعر الكورس أو جعله مجانيًا.</li>
                                <li>قسم الفيديو يتحكم في فيديو نظرة عامة على الكورس.</li>
                                <li>منشئ الكورس هو المكان الذي تنشئ فيه وتنظم الكورس.</li>
                                <li>أضف المواضيع في قسم منشئ الكورس لإنشاء الدروس، الاختبارات، والتكليفات.</li>
                            </ul>
                        </div>
                    </div>

                    <div className='col-lg-8 col-md-8 col-sm-12 col-12'>
                        <div className='course-addition-inputs'>
                            <div className='addition-input'>
                                <div className='header'>
                                    <i className="las la-plus"></i>
                                    <h1>تفاصيل الكورس</h1>
                                </div>

                                <div className='content'>
                                    <div className='input'>
                                        <label>عنوان الكورس</label>
                                        <input name='header' value={course.header || ''} onChange={handleInputChange} type='text' placeholder='كورس جديد' maxLength={200} />
                                        <span className='note'>العنوان يجب أن لا يقل عن 20 حرف ولا يزيد عن 200 حرف <i className="las la-info-circle"></i></span>
                                    </div>

                                    <div className='input'>
                                        <label>عنوان فرعي للكورس</label>
                                        <input name='sec_header' value={course.sec_header || ''} onChange={handleInputChange} type='text' placeholder='كورس جديد' maxLength={250} />
                                        <span className='note'>العنوان يجب أن لا يقل عن 20 حرف ولا يزيد عن 250 حرف <i className="las la-info-circle"></i></span>
                                    </div>
                                    <div className='input'>
                                        <label> مدة الكورس </label>
                                        <input name='duration' value={course.duration || ''} onChange={handleInputChange} type='text' placeholder='ساعتين' maxLength={250} />
                                        <span className='note'> <i className="las la-info-circle"></i></span>
                                    </div>
                                    <div className='input'>
                                        <label> مستوى الكورس </label>
                                        <input name='level' value={course.level || ''} onChange={handleInputChange} type='text' placeholder='مبتدئ' maxLength={250} />
                                        <span className='note'> <i className="las la-info-circle"></i></span>
                                    </div>

                                    <div className='input'>
                                        <label>وصف الكورس</label>
                                        <textarea name='description' value={course.description || ''} onChange={handleInputChange} placeholder='أضف وصف الكورس هنا' maxLength={1500}></textarea>
                                        <span className='note'>الوصف يجب أن لا يقل عن 20 حرف ولا يزيد عن 1500 حرف <i className="las la-info-circle"></i></span>
                                    </div>

                                    <div className='input'>
                                        <label>متطلبات الكورس</label>
                                        <textarea name='requirments' value={course.requirments || ''} onChange={handleInputChange} placeholder='أضف متطلبات الكورس هنا' maxLength={1500}></textarea>
                                        <span className='note'>الوصف يجب أن لا يقل عن 20 حرف ولا يزيد عن 1500 حرف <i className="las la-info-circle"></i></span>
                                    </div>
                                    <div className='input videoDiv'>
    <label>الفيديو التعريفى بالكورس</label>

    {/* Hidden Input for video file */}
    <input
        id='videoUploadInput'
        type='file'
        accept='video/*'
        onChange={handleVideoUpload}
        style={{ display: 'none' }} 
    />


    {course.intro_video ? (
        <div>
            <video width="400" controls>
                <source src={`./uploads/${course.intro_video}`} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <div className='btnvid-container'>
                {/* Button to change video */}
                <button onClick={() => {
                    setCourse({ ...course, intro_video: '' });
                    setUploadedVideo(null);  
                    document.getElementById('videoUploadInput').click();
                }}>
                    تغيير
                </button>

                {/* Button to delete video */}
                <button className='delete' onClick={() => {
                    axios.delete('/delete-course-video', {
                        data: { courseId: course.id }, 
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    })
                    .then(response => {
                        console.log('Video deleted successfully');
                        // Update state to remove the video
                        setCourse({ ...course, intro_video: null });
                    })
                    .catch(error => {
                        console.error('Error deleting video:', error.response?.data?.message || error.message);
                    });
                }} style={{ marginLeft: '10px' }}>
                    حذف
                </button>
            </div>
        </div>
    ) : (
        <button className="edit" onClick={() => {
            document.getElementById('videoUploadInput').click();
        }}>
            إضافة فيديو جديد
        </button>
    )}

    {/* Note about the video */}
    <span className='note'>
        يمكنك رفع فيديو تعريفى بالكورس. <i className="las la-info-circle"></i>
    </span>
</div>


                                    <div className=' input price-options'>
                                        <label>خيارات الدفع</label>
                                        <div className='price-option'>
                                            <input
                                                type="radio"
                                                id="free"
                                                name="free"
                                                value="1"
                                                checked={currentScreen === 'free'}
                                                onChange={() => setCurrentScreen('free')}
                                            />
                                            <label htmlFor="free">مجاني</label>
                                        </div>
                                        <div className='price-option'>
                                            <input
                                                type="radio"
                                                id="paid"
                                                name="free"
                                                value="0"
                                                checked={currentScreen === 'paid'}
                                                onChange={() => setCurrentScreen('paid')}
                                            />
                                            <label htmlFor="paid">مدفوع</label>
                                        </div>
                                    </div>

                                    {currentScreen === 'paid' && (
                                        <div className='input'>
                                            <label>سعر الكورس</label>
                                            <input name='price' value={course.price || ''} onChange={handleInputChange} type='text' placeholder='100' maxLength={10} />
                                        </div>
                                    )}


                                    <div className='button'>
                                        <button className='link-btn-color' onClick={handleUpdateCourse}>تحديث الكورس</button>
                                    </div>
                                </div>
                            </div>

                            <div className='addition-input'>
                                <div className='header'>
                                    <i className="las la-plus"></i>
                                    <h1>إضافة درس</h1>
                                </div>
                                <div className='content'>
                                    <div className='input'>
                                        <label>عنوان الدرس</label>
                                        <input value={lessonHeader} onChange={(e) => setLessonHeader(e.target.value)} type='text' placeholder='عنوان الدرس' maxLength={200} />
                                    </div>
                                    <div className='upload-input'>
                                        <label>رفع ملف الدرس</label>
                                        <div className='drop-area drag-drop-here' onDragOver={handleDragOver} onDrop={handleDrop} onClick={handleDivClick}>
                                            <input ref={fileInputRef} type='file' onChange={handleFileChange} accept="video/*" style={{ display: 'none' }} />
                                            {uploadedFile ? (
                                                <p>تم تحديد الملف: {uploadedFile.name}</p>
                                            ) : (
                                                <p>اسحب وأسقط الملف هنا أو اضغط لتحديده</p>
                                            )}
                                        </div>
                                        <div className='upload-progress'>
                                            {uploadProgressArray.map((progress, index) => (
                                                <div key={index}>
                                                    <progress value={progress} max="100"></progress>
                                                    <span>{progress}%</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className='input questions'>
                                        <label>الأسئلة</label>
                                        {questions.map((question, index) => (
                                            <div key={index} className='question'>
                                                <div className='input'>
                                                    <label>السؤال</label>
                                                    <input
                                                        type='text'
                                                        value={question.question}
                                                        onChange={(e) => handleQuestionChange(index, e.target.value)}
                                                        placeholder='أدخل السؤال'
                                                    />
                                                </div>
                                                <div className='answers'>
                                                    {question.answers.map((answer, answerIndex) => (
                                                        <div key={answerIndex} className='input'>
                                                            <label>الإجابة {answerIndex + 1}</label>
                                                            <input
                                                                type='text'
                                                                value={answer}
                                                                onChange={(e) => handleAnswerChange(index, answerIndex, e.target.value)}
                                                                placeholder={`الإجابة ${answerIndex + 1}`}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className='input'>
                                                    <label>الإجابة الصحيحة</label>
                                                    <input
                                                        type='text'
                                                        value={question.correctAnswer}
                                                        onChange={(e) => handleCorrectAnswerChange(index, e.target.value)}
                                                        placeholder='أدخل الإجابة الصحيحة'
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                        <button className='link-btn-midcolor add-question-button' onClick={addNewQuestion}>
                                            أضف سؤالاً جديداً
                                        </button>
                                    </div>

                                    <div className='button'>
                                        <button className='link-btn-color' onClick={handleSubmitLesson}>إضافة الدرس</button>
                                    </div>
                                </div>
                            </div>
                            <div className='addition-input'>
                                <div className='header'>
                                    <i className="las la-plus"></i>
                                    <h1> تعديل الدروس</h1>
                                </div>
                                <div className='content'>
                                

                                    <div className='update'>
                                    <p>      يمكنك اضافة أو تحديث أو حذف درس من الدروس من هنا</p>

                                        <button className='link-btn-color' onClick={handleNavigation} courseId={course.id}>تعديل الدرس</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            

        </section>
        </>
    );
}

export default EditCourse;
