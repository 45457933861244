import './css/loginregister.css';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useState,useEffect } from 'react';
import axios from '../config/index';
import Modal from 'react-modal';
Modal.setAppElement('#root');
function LoginRegister() {
    const navigate = useNavigate();
    const [loginEmail, setLoginEmail] = useState('');
    const [loginPassword, setLoginPassword] = useState('');
    const [registerEmail, setRegisterEmail] = useState('');
    const [registerName, setRegisterName] = useState('');
    const [registerPassword, setRegisterPassword] = useState('');
    const [registerConfirmPassword, setRegisterConfirmPassword] = useState('');
    const [registerType, setRegisterType] = useState('user');
    const [loading, setLoading] = useState(false); // Add loading state
        const [isModalOpen, setIsModalOpen] = useState(false);
        const [email, setEmail] = useState('');
        const [confirmCode, setConfirmCode] = useState('');
        const [newPassword, setNewPassword] = useState('');
        const [confirmPassword, setConfirmPassword] = useState('');
        const [step, setStep] = useState(1); 
        const [showSuccess, setShowSuccess] = useState(false);
        const [emailError, setEmailError] = useState('');
        const [confirmCodeError, setConfirmCodeError] = useState('');
        const [passwordError, setPasswordError] = useState('');
        const [confirmPasswordError, setConfirmPasswordError] = useState('');
        const [errorMessage, setErrorMessage] = useState('');

        const handleLogin = async (e) => {
            e.preventDefault();
            setLoading(true); // Show loading screen
            try {
                const response = await axios.post('/login', {
                    email: loginEmail,
                    password: loginPassword
                });
                navigate('/');
            } catch (error) {
                alert(error.response.data.message);
            } finally {
                setLoading(false); // Hide loading screen
            }
        };

        const handleRegister = async (e) => {
            e.preventDefault();
            if (registerPassword !== registerConfirmPassword) {
                alert('Passwords do not match');
                return;
            }
            setLoading(true); // Show loading screen
            try {
                const response = await axios.post('/signup', {
                    fullname: registerName,
                    email: registerEmail,
                    password: registerPassword,
                    type: 'user'
                });
                navigate('/confirm-email');
            } catch (error) {
                alert(error.response.data.message);
            } finally {
                setLoading(false); // Hide loading screen
            }
        };


        const openModal = () => {
            setIsModalOpen(true);
            setStep(1); // Reset to the first step
        };
        
        const closeModal = () => {
            setIsModalOpen(false);
            setEmail('');
            setConfirmCode('');
            setNewPassword('');
            setConfirmPassword('');
        };
        
        

        // Detailed Validation Functions with Specific Error Messages
        const validateEmail = (email) => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!email) {
            setEmailError('البريد الإلكتروني مطلوب.');
            return false;
            }
            if (!emailRegex.test(email)) {
            setEmailError('يرجى إدخال بريد إلكتروني صالح (example@domain.com).');
            return false;
            }
            setEmailError('');
            return true;
        };
        
        const validateConfirmCode = (code) => {
            if (!code) {
            setConfirmCodeError('رمز التفعيل مطلوب.');
            return false;
            }
            if (code.length !==5) {
            setConfirmCodeError('رمز التفعيل يجب أن يكون 5 أرقام.');
            return false;
            }
            setConfirmCodeError('');
            return true;
        };
        
        const validatePassword = (password) => {
            const passwordCriteria = [
            { regex: /.{8,}/, message: 'يجب أن تكون كلمة المرور 8 أحرف على الأقل.' },
            { regex: /[A-Z]/, message: 'يجب أن تحتوي كلمة المرور على حرف كبير (A-Z).' },
            { regex: /[a-z]/, message: 'يجب أن تحتوي كلمة المرور على حرف صغير (a-z).' },
            { regex: /\d/, message: 'يجب أن تحتوي كلمة المرور على رقم (0-9).' },
            ];
        
            for (let criterion of passwordCriteria) {
            if (!criterion.regex.test(password)) {
                setPasswordError(criterion.message);
                return false;
            }
            }
        
            setPasswordError('');
            return true;
        };
        
        const validatePasswordsMatch = (password, confirmPassword) => {
            if (!confirmPassword) {
            setConfirmPasswordError('تأكيد كلمة المرور مطلوب.');
            return false;
            }
            if (password !== confirmPassword) {
            setConfirmPasswordError('كلمات المرور غير متطابقة.');
            return false;
            }
            setConfirmPasswordError('');
            return true;
        };
        
        const handleSendEmail = async () => {
            if (!validateEmail(email)) {
                return;
            }
            try {
                const response = await axios.post('/request-password-reset', { email });
                if (response.data.message) {
                    setStep(2);  
                    setErrorMessage('');  
                }
            } catch (error) {
                const message = error.response?.data.message || 'Error sending email';
                alert(message);  
                setErrorMessage(message);
            }
        };
        
        const handleSendConfirmCode = async () => {
            if (!validateConfirmCode(confirmCode)) {
                return;
            }
            try {
                const response = await axios.post('/verify-confirmation-code', { 
                    email, 
                    confirmCode 
                });
        
                if (response.data.message) {
                    setStep(3);  
                    setErrorMessage('');  
                }
            } catch (error) {
                const message = error.response?.data.message || 'Error verifying confirmation code';
                alert(message);  
                setErrorMessage(message);
            }
        };
        
         const handleSetNewPassword = async () => {
            if (!validatePassword(newPassword)) {
                console.log("Password is not valid");
                return;
            }
            if (!validatePasswordsMatch(newPassword, confirmPassword)) {
                console.log("Passwords do not match");
                return;
            }
        
            try {
                const response = await axios.post('/update-password', { 
                    email, 
                    newPassword  
                });
        
                if (response.data.message) {
                    setShowSuccess(true);
                    console.log(response.statusText);
                }
            } catch (error) {
                const message = error.response?.data.message || 'Error updating password';
                alert(message); 
                setErrorMessage(message);
            }
        };
        
         
        useEffect(() => {
            if (showSuccess) {
                handleRedirectAfterSuccess();
            }
        }, [showSuccess]);
        
        const handleRedirectAfterSuccess = () => {
            setTimeout(() => {
                setShowSuccess(false);
                closeModal();
                
            }, 3000);
        };
        
       
        
    return (
        <>
            {loading && (
                <div className='loading-screen'>
                    <div className="loader"></div>
                </div>
            )}
            <section className='login-register'>
            {showSuccess && (
      <div className="alert alert-success mt-3 text-center position-fixed top-0 end-0 m-3" style={{ zIndex: 1050 }}>
        تم تغيير كلمة المرور بنجاح. يمكنك تسجيل الدخول الآن.
        {handleRedirectAfterSuccess()}
      </div>
      )}
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='form-element'>
                                <h1>تسجيل الدخول</h1>
                                <form onSubmit={handleLogin}>
                                    <div className='inputs'>
                                        <div className='input'>
                                            <input
                                                type='email'
                                                placeholder='البريد الإلكتروني'
                                                required
                                                value={loginEmail}
                                                onChange={(e) => setLoginEmail(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='inputs'>
                                        <div className='input'>
                                            <input
                                                type='password'
                                                placeholder='كلمة المرور'
                                                required
                                                value={loginPassword}
                                                onChange={(e) => setLoginPassword(e.target.value)}
                                                />
                                        </div>
                                    </div>
      <div className="forget-password row justify-content-end align-content-end">
        <RouterLink onClick={openModal} >
          هل نسيت كلمة المرور؟
        </RouterLink>
      </div>
      <div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Forget Password"
        shouldCloseOnOverlayClick={false}
        style={{
            content: {
              top: '60%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              width: '80vw', // Adjust width for mobile
              height: 'auto', // Allow height to adjust based on content
              maxWidth: '500px',
              padding: '20px',
              borderRadius: '10px',
              boxShadow: '0 5px 15px rgba(0,0,0,.3)',
              overflowY: 'auto', // Allow scrolling if content overflows
            },
          }}
        >
        <div className="modal-header border-bottom-0 text-center">
          <h5 className="modal-title w-100">
            {step === 1
              ? 'إعادة تعيين كلمة المرور'
              : step === 2
              ? 'أدخل رمز التفعيل'
              : 'إعادة تعيين كلمة المرور'}
          </h5>
          <div
            type="button"
            className="close"
            onClick={closeModal}
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </div>
        </div>

        <div className="modal-body mt-3">
          {step === 1 && (
            <div className="form-group d-flex justify-content-center align-items-end flex-column">
              <label htmlFor="email" className="mb-3 pe-4 w-full">
                أدخل بريدك الإلكتروني وانتظر رسالة التفعيل
              </label>
              <input
                type="email"
                id="email"
                className="form-control mx-auto"
                style={{ maxWidth: '90%' }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="example@domain.com"
              />
              {emailError && (
                <div className="text-danger mt-2">{emailError}</div>
              )}
            </div>
          )}

          {step === 2 && (
            <div className="form-group d-flex justify-content-center align-items-end flex-column">
              <label htmlFor="confirmCode" className="mb-3 pe-4 w-full">
                أدخل رمز التفعيل المرسل إلى بريدك الإلكتروني
              </label>
              <input
                type="text"
                id="confirmCode"
                className="form-control mx-auto"
                style={{ maxWidth: '90%' }}
                value={confirmCode}
                onChange={(e) => setConfirmCode(e.target.value)}
                placeholder="رمز التفعيل"
              />
              {confirmCodeError && (
                <div className="text-danger mt-2">{confirmCodeError}</div>
              )}
            </div>
          )}

          {step === 3 && (
            <div className="form-group d-flex flex-column justify-content-center align-items-end">
              <label htmlFor="newPassword" className="mb-3 pe-4 w-full">
                أدخل كلمة المرور الجديدة
              </label>
              <input
                type="password"
                id="newPassword"
                className="form-control mx-auto mb-2"
                style={{ maxWidth: '90%' }}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="كلمة المرور الجديدة"
              />
              {passwordError && (
                <div className="text-danger mt-2">{passwordError}</div>
              )}
              <label htmlFor="confirmPassword" className="mb-3 pe-4 w-full">
                تأكيد كلمة المرور الجديدة
              </label>
              <input
                type="password"
                id="confirmPassword"
                className="form-control mx-auto"
                style={{ maxWidth: '90%' }}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="تأكيد كلمة المرور"
              />
              {confirmPasswordError && (
                <div className="text-danger mt-2">{confirmPasswordError}</div>
              )}
            </div>
          )}
        </div>

        <div className="modal-footer  justify-content-between border-top-0">
          <button
            type="button"
            className="link-btn-color-cancel  "
            onClick={closeModal}
          >
            إلغاء
          </button>
          {step === 1 && (
            <button
              type="button"
              className="link-btn-color  "
              onClick={handleSendEmail}
            >
              إرسال البريد الإلكتروني
            </button>
          )}
          {step === 2 && (
            <button
              type="button"
              className="link-btn-color  "
              onClick={handleSendConfirmCode}
            >
              تحقق من الرمز
            </button>
          )}
          {step === 3 && (
            <button
              type="button"
              className="link-btn-color  "
              onClick={handleSetNewPassword}
            >
              إعادة تعيين كلمة المرور
            </button>
          )}
        </div>

       
      </Modal>
    </div>




    
                                    <button type='submit' className='link-btn-midcolor'>
                                        تسجيل الدخول <i className='las la-arrow-right'></i>
                                    </button>
                                </form>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='form-element'>
                                <h1>تسجيل جديد</h1>
                                <form onSubmit={handleRegister}>
                                    <div className='inputs'>
                                        <div className='input'>
                                            <input
                                                type='email'
                                                placeholder='البريد الإلكتروني'
                                                required
                                                value={registerEmail}
                                                onChange={(e) => setRegisterEmail(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='inputs'>
                                        <div className='input'>
                                            <input
                                                type='text'
                                                placeholder='الاسم'
                                                required
                                                value={registerName}
                                                onChange={(e) => setRegisterName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className='inputs'>
                                        <div className='input'>
                                            <select
                                                required
                                                value={registerType}
                                                onChange={(e) => setRegisterType(e.target.value)}
                                            >
                                                <option value=''>إختر نوع الحساب</option>
                                                <option value='user'>طالب</option>
                                                <option value='inst'>مدرب</option>
                                            </select>
                                        </div>
                                    </div> */}
                                    <div className='inputs'>
                                        <div className='input'>
                                            <input
                                                type='password'
                                                placeholder='كلمة المرور'
                                                required
                                                value={registerPassword}
                                                onChange={(e) => setRegisterPassword(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='inputs'>
                                        <div className='input'>
                                            <input
                                                type='password'
                                                placeholder='تأكيد كلمة المرور'
                                                required
                                                value={registerConfirmPassword}
                                                onChange={(e) => setRegisterConfirmPassword(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <button type='submit' className='link-btn-color'>
                                        تسجيل جديد <i className='las la-arrow-right'></i>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default LoginRegister;
