import './css/instdash.css';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import BooksImages from './images/books.webp';

import axios from '../config/index';
import { useState, useEffect } from 'react';

function StudentDash() {
    const navigate = useNavigate();
    const [userId, setUserId] = useState(null);
    const [user, setUser] = useState([]);
    const [courses, setCourses] = useState([]);
    const [userCourses, setUserCourses] = useState([]);
    const [filteredCourses, setFilteredCourses] = useState([]);
    const [lessons, setLessons] = useState([]);

    useEffect(() => {
        axios.get('/session')
            .then((res) => {
                if (res.data.valid && res.data.type === "user") {
                    setUserId(res.data.userId);
                } else {
                    navigate("/");
                }
            })
            .catch((err) => console.log(err));
    }, []);

    useEffect(() => {
        if (userId) {
            const itemId = userId;
            axios.post('/get-user', { itemId })
                .then((res) => setUser(res.data))
                .catch((err) => console.log(err));
        }
    }, [userId]);

    useEffect(() => {
        axios.post('/AllItems', { table: 'courses' })
            .then((res) => {
                if (res.data) {
                    setCourses(res.data);
                } else {
                    console.log("Error happened");
                }
            })
            .catch((err) => console.log(err));
    }, []);

    useEffect(() => {
        if (userId) {
            axios.post('/AllItems', { table: 'user_courses' })
                .then((res) => {
                    if (res.data) {
                        const userCourseIds = res.data.filter(uc => uc.user_id === userId).map(uc => uc.course_id);
                        setUserCourses(userCourseIds);
                    } else {
                        console.log("Error happened");
                    }
                })
                .catch((err) => console.log(err));
        }
    }, [userId]);

    useEffect(() => {
        axios.post('/AllItems', { table: 'lessons' })
            .then((res) => {
                if (res.data) {
                    setLessons(res.data);
                } else {
                    console.log("Error happened");
                }
            })
            .catch((err) => console.log(err));
    }, []);

    useEffect(() => {
        if (courses.length && userCourses.length && lessons.length) {
            const coursesWithLessons = courses.map(course => {
                const courseLessons = lessons.filter(lesson => lesson.course_id === course.id);
                const firstLessonId = courseLessons.length ? courseLessons[0].id : null;
                return { ...course, firstLessonId, lessonCount: courseLessons.length };
            });
            const filtered = coursesWithLessons.filter(course => userCourses.includes(course.id));
            setFilteredCourses(filtered);
        }
    }, [courses, userCourses, lessons]);

    return (
        <>
            <section className='inst-dash'>
                <div className='dash-header'>
                    <div className='inst-header'>
                        <div className='background-colored'></div>
                        <div className='inst-card'>
                            <img src={BooksImages} alt='books' />
                            <h2> طالب في مييد </h2>
                            <h1> {user.fullname} </h1>
                            <div className='info'>
                                <img src={`./uploads/${user.profile_img}`} alt='instructor image' />
                                <div className='info-data'></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='user-dashboard'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                                <div className='user-side-nav'>
                                    <h2> أهلا وسهلا عبد الرحمن الصاوي </h2>
                                    <ul>
                                        <li>
                                            <RouterLink to="/student-dashboard"> لوحة التحكم <i className="las la-home"></i> </RouterLink>
                                        </li>
                                        <li>
                                            <RouterLink to="/user-settings"> الإعدادات <i className="las la-cog"></i></RouterLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-8 col-md-8 col-sm-12 col-12'>
                                <div className='dash-content'>
                                    <h2> الكورسات التي تم شرائها </h2>
                                    <div className='int-courses'>
                                        <div className='row'>
                                            {filteredCourses.map((course, index) => (
                                                <div className='col-lg-6 col-md-6 col-sm-12 col-12' key={index}>
                                                    <div className='course-card'>
                                                        <RouterLink to={`/lesson?id=${course.firstLessonId}&course_id=${course.id}`} >
                                                            <img src={`./uploads/${course.img}`} alt='course image' />
                                                        </RouterLink>
                                                        <div className='text'>
                                                            <div className='reviews'>
                                                                <ul>
                                                                    {Array(course.rate || 5).fill(null).map((_, i) => (
                                                                        <li key={i}>
                                                                            <i className="las la-star"></i>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                                <span>(مراجعات)</span>
                                                            </div>
                                                            <h1>{course.header}</h1>
                                                            <div className='course-info'>
                                                                <span><i className="las la-book"></i> {course.lessonCount} دروس</span>
                                                                <span><i className="las la-users"></i> {course.students} طلاب</span>
                                                            </div>
                                                            <p className='section-text'>{course.description}</p>
                                                            <div className='instructor-info'>
                                                                <img src={`./uploads/${user.profile_img}`} alt='instructor image' />
                                                                <h4>بواسطة <span>{user.fullname}</span></h4>
                                                            </div>
                                                            <div className='course-price'>
                                                                {course.free === 1 ? <h3> مجاني </h3> : (
                                                                    <>
                                                                        <h3>${course.price} <span>${course.prev_price}</span></h3>
                                                                    </>
                                                                )}
                                                                <RouterLink to={`/lesson?id=${course.firstLessonId}&course_id=${course.id}`}>اعرف أكثر <i className="las la-arrow-right"></i></RouterLink>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default StudentDash;
