import './css/dashboardtable.css';
import './css/editform.css';
import React, { useState, useEffect } from "react";
import axios from '../../config/index';
import { useNavigate } from 'react-router-dom';

function AddUsersCourse() {
    const navigate = useNavigate();
    const [courses, setAllCourses] = useState([]);
    const [users, setUsers] = useState([]);
    const [course_id, setCourseId] = useState("");
    const [user_id, setUserId] = useState("");

    const getAllCourses = () => {
        axios.post('AllItems', {
            table: 'courses',
        })
        .then((res) => {
            if (res.data) {
                setAllCourses(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };

    const getAllUsers = () => {
        axios.post('AllUsers', {
            table: 'users',
        })
        .then((res) => {
            if (res.data) {
                setUsers(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        let Loading = document.querySelector(".loading-screen"); 
        Loading.classList.add("active");

        const data = new FormData();
        data.append("course_id", course_id);
        data.append("user_id", user_id);

        // Log FormData entries
        for (let pair of data.entries()) {
            console.log(`${pair[0]}: ${pair[1]}`);
        }

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };

        axios
        .post("/add-user-course", data, config)
        .then((response) => {
            Loading.classList.remove("active");

            let alertS = document.querySelector(".alert-success"); 
            alertS.classList.add("active");

            setTimeout(() => {
                alertS.classList.remove("active");
            }, 3000);
        })
        .catch((error) => {
            Loading.classList.remove("active");

            let alertD = document.querySelector(".alert-danger"); 
            alertD.classList.add("active");
            setTimeout(() => {
                alertD.classList.remove("active");
            }, 3000);
            console.log(error);
        });
    };

    useEffect(() => {
        getAllCourses();
        getAllUsers();
    }, []);

    return (
        <>
            <div className='loading-screen'>
                <div className="loader">
                    <div className="outer"></div>
                    <div className="middle"></div>
                    <div className="inner"></div>
                </div>
            </div>
            <section className='dashboard'>
                <div className='edit-form'>
                    <div className='alert-success'>
                        <h1>تمت الإضافة بنجاح <i className="las la-check-circle"></i></h1>
                    </div>
                    <div className='alert-danger'>
                        <h1>فشل في الإضافة <i className="las la-exclamation-triangle"></i></h1>
                    </div>
                    <h1>إضافة كورس إلى طالب</h1>
                    <form onSubmit={handleSubmit}>
                        <div className='input'>
                            <label>الكورس</label>
                            <select onChange={(event) => setCourseId(event.target.value)} required="required" name='course_id'>
                                <option value="">أختر الكورس</option>
                                {courses.length ? (
                                    courses.map((course) => (
                                        <option key={course.id} value={course.id}>({course.id}) {course.header}</option>
                                    ))
                                ) : null}
                            </select>
                        </div>
                        <div className='input'>
                            <label>الطالب</label>
                            <select onChange={(event) => setUserId(event.target.value)} required="required" name='user_id'>
                                <option value="">أختر الطالب</option>
                                {users.length ? (
                                    users.map((user) => (
                                        <option key={user.user_id} value={user.user_id}>({user.user_id}) {user.email}</option>
                                    ))
                                ) : null}
                            </select>
                        </div>
                        <button type='submit'>إضافة</button>
                    </form>
                </div>
            </section>
        </>
    );
}

export default AddUsersCourse;
