import './css/dashboardtable.css';
import './css/editform.css';
import React, { useState, useEffect } from "react";
import axios from '../../config/index';
import { useNavigate, useLocation } from 'react-router-dom';
import ShowButton from '../ShowButton';

function EditUser() {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const [User, setUser] = useState(null);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [type, setType] = useState("admin");
    const [formChanged, setFormChanged] = useState(false); // Track if the form has changed

    const handleInputChange = () => {
        setFormChanged(true); // Set the formChanged flag to true when any input changes
    };

    const handleImageChange = (event) => {
        const selectedImage = event.target.files[0];
        uploadImage(selectedImage);
    };

    const uploadImage = (file) => {
        const formData = new FormData();
        let Loading = document.querySelector(".loading-screen"); 
        Loading.classList.add("active");

        formData.append("id", id);
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };
        
        axios.post("upload-image", formData, config)
            .then((response) => {
                Loading.classList.remove("active");
                let alertS = document.querySelector(".alert-success"); 
                alertS.classList.add("active");
                setTimeout(() => {
                    alertS.classList.remove("active");
                }, 3000);
                return response.data;
            })
            .catch((error) => {
                Loading.classList.remove("active");
                let alertD = document.querySelector(".alert-danger"); 
                alertD.classList.add("active");
                setTimeout(() => {
                    alertD.classList.remove("active");
                }, 3000);
                console.error("Error uploading image: ", error);
                return null;
            });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let Loading = document.querySelector(".loading-screen"); 
        Loading.classList.add("active");

        const data = {
            email: email || "",
            password: password || "",
            type: type || "",
        };
        
        if (id) {
            data.id = id;
        }

        console.log(data)

        axios.put("/edit-user", data)

            .then((response) => {

                Loading.classList.remove("active");
                let alertS = document.querySelector(".alert-success"); 
                alertS.classList.add("active");
                setTimeout(() => {
                    alertS.classList.remove("active");
                }, 3000);

                console.log(response.data);
            })
            .catch((error) => {

                Loading.classList.remove("active");
                let alertD = document.querySelector(".alert-danger"); 
                alertD.classList.add("active");
                setTimeout(() => {
                    alertD.classList.remove("active");
                }, 3000);
                
                console.log(error);
            });
    };

    const [show, setShow] = useState(false);
    const handleToggle = () => {
        setShow(!show);
    };
    const handleChange = (event) => {
        setPassword(event.target.value);
    };

    axios.defaults.withCredentials = true;

    const getUser = (id) => {
        axios.post('/user', { id })
            .then((res) => {
                if (res.data.message) {
                    console.log(res.data.message);
                } else {
                    setUser(res.data);
                    setEmail(res.data.email); // Set the initial value of email
                }
            })
            .catch((err) => console.log(err));
    };

    const getIfLogin = () => {
        axios.get('/admin-session')
            .then((res) => {
                if (!res.data.validadmin || id <= 0 || id == null || id == "") {
                    navigate('/admin-login');
                } else {
                    getUser(id);
                }
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        getIfLogin();
    }, []);

    return (
        <>
            <div className='loading-screen'>
                <div className="loader">
                    <div className="outer"></div>
                    <div className="middle"></div>
                    <div className="inner"></div>
                </div>
            </div>

            <section className='dashboard'>
                <div className='edit-form'>
                    <div className='alert-success'>
                        <h1>تم التحديث <i className="las la-check-circle"></i></h1>
                    </div>
                    <div className='alert-danger'>
                        <h1>فشل التحديث <i className="las la-exclamation-triangle"></i></h1>
                    </div>
                    <h1>تعديل المستخدم : {User?.username}</h1>

                    <form onSubmit={handleSubmit}>
                        <div className='input'>
                            <label>البريد الإلكتروني</label>
                            <input
                                onChange={(event) => { setEmail(event.target.value); handleInputChange(event); }}
                                type='email'
                                required="required"
                                value={email}
                                maxLength="250"
                                name='email'
                            />
                        </div>

                        <div className='input'>
                            <label>كلمة المرور</label>
                            <input
                                onChange={(e) => { handleChange(e); setPassword(e.target.value); handleInputChange(e); }}
                                type={show ? 'text' : 'password'}
                                placeholder="New Password"
                                maxLength="250"
                                name='password'
                            />
                            <ShowButton className="show-btn" show={show} handleToggle={handleToggle} />
                        </div>

                        <button type='submit'>تحديث</button>
                    </form>
                </div>
            </section>
        </>
    );
}

export default EditUser;
