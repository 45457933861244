import "./css/landing.css";
import { Link as RouterLink } from "react-router-dom";

import student1 from "./images/home/image 4.png";
import student2 from "./images/home/image 5.png";
import student3 from "./images/home/image 6.png";
import student4 from "./images/home/image 7.png";
import teacher1 from "./images/home/image 9.png";
import teacher2 from "./images/home/image 10.png";
import teacher3 from "./images/home/image 11.png";
import teacher4 from "./images/home/image 12.png";
import subtract from "./images/home/Subtract.svg";
import axios from "../config/index";
import { useState, useEffect } from "react";

function Landing() {
  const [landing, setLanding] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const getLanding = (itemId) => {
    const table = "landing";
    axios
      .post("/get-item", { itemId, table })
      .then((res) => {
        if (!res.data.message) {
          setLanding(res.data);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getLanding(1);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post("/add-subscriber", { name, email, phonenumber: phone })
      .then((response) => {
        setMessage(response.data.message);
        setName("");
        setEmail("");
        setPhone("");
      })
      .catch((error) => {
        if (error.response) {
          setMessage(error.response.data.message);
        } else {
          setMessage("An error occurred. Please try again.");
        }
      });
  };

  const stars = Array(5).fill(null);
  const stats = [
    { value: "+500", description: "الطلاب والمدرسين" },
    { value: "+800", description: "دورة مسجلة" },
    { value: "+1,000", description: "اختبارات الدورات" },
    { value: "+100", description: "طلب حاصلين على شهادة" },
  ];
  return (
    <section className="landing">
      <div className="bottom-navbar">
        <p>قم بتسجيل دخولك واستفد من خصم 25% على اسعار الدورات</p>
      </div>
      <div className="hero-section">
        <div className="hero-container">
          <div className="hero-content">
            <h1>
              استثمر في
              <span className="highlight"> مستقبلك </span> التعليمي مع دوراتنا
              المتميزة عبر الإنترنت.
            </h1>
            <p>
              اكسب مهارات جديدة وتفوق في ريادة الأعمال، إدارة المشاريع، البرمجة،
              والذكاء الاصطناعي بمجالات متنوعة مع دوراتنا المتخصصة.
            </p>
            <div className="hero-details">
              <div className="hero-buttons">
                <button className="cta-button primary-button">
                  <RouterLink
                    className="cta-button primary-button"
                    to="/login-register"
                    style={{
                      backgroundColor: "#F06730",
                      color: "#FFF",
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    سجل الآن
                  </RouterLink>
                </button>
                <button className="cta-button secondary-button">
                  <RouterLink to="/courses">
                    <p className="button-text">شاهد الدورات</p>
                  </RouterLink>
                  <div>
                    {" "}
                    <img src={subtract} alt="subtract" className="subtract" />
                  </div>
                </button>
              </div>

              <div className="teachers-info-container">
                <h3>ازيد من 54 مدرس خبير</h3>
                <div className="teachers-images-container">
                  <img
                    src={teacher1}
                    alt="Teacher 1"
                    className="teacher-image"
                  />
                  <img
                    src={teacher2}
                    alt="Teacher 2"
                    className="teacher-image"
                  />
                  <img
                    src={teacher3}
                    alt="Teacher 3"
                    className="teacher-image"
                  />
                  <img
                    src={teacher4}
                    alt="Teacher 3"
                    className="teacher-image"
                  />
                  <div className="more-count">+54</div>
                </div>
              </div>
            </div>
          </div>
          <div className="hero-images">
            <div className="image-grid">
              <img src={student1} alt="Student 1" className="grid-image" />
              <img src={student2} alt="Student 2" className="grid-image" />
              <img src={student3} alt="Student 3" className="grid-image" />
              <img src={student4} alt="Student 4" className="grid-image" />
            </div>
          </div>
        </div>

        {/* <div className="statistics-container">
            {stats.map((stat, index) => (
                <div key={index} className="stat-item">
                    <h2>{stat.value}</h2>
                    <p>{stat.description}</p>
                </div>
            ))}
        </div> */}
      </div>
    </section>
  );
}

export default Landing;
