import './css/landcourses.css';
import { Link as RouterLink } from 'react-router-dom';
import axios from '../config/index';
import { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import searchIcon from './images/courses/Search_alt_fill.svg';
import SidebarToggle from './SidebarToggle';
import AllCoursesFilter from './AllCoursesFilter';

const Stars = Array(5).fill(null);

function CoursesPage() {
    const [categories, setCategories] = useState([]);
    const [courses, setCourses] = useState([]);
    const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedRating, setSelectedRating] = useState(null);
    const [selectedPrice, setSelectedPrice] = useState(null);
    const [videoDuration, setVideoDuration] = useState([]);
    const [level, setLevel] = useState([]);
    const [freeOrPaid, setFreeOrPaid] = useState(null);
    const [resetFilters, setresetFilters] = useState(null);
  
    const handleInputChange = (event) => setSearchTerm(event.target.value);
  
    useEffect(() => {
      const getAllCategories = () => {
        axios.post('AllItems', { table: 'categories' })
          .then((res) => setCategories(res.data || []))
          .catch((err) => console.log(err));
      };
  
      const getAllCourses = () => {
        axios.post('AllItems', { table: 'courses' })
          .then((res) => setCourses(res.data || []))
          .catch((err) => console.log(err));
      };
  
      const getAllUsers = () => {
        axios.post('AllUsers', { table: 'users' })
          .then((res) => setUsers(res.data || []))
          .catch((err) => console.log(err));
      };
  
      getAllCategories();
      getAllCourses();
      getAllUsers();
    }, []);
   
    return (
      <section className='courses-box'>
        <Sidebar
          setSelectedCategory={setSelectedCategory}
          setSelectedRating={setSelectedRating}
          setSelectedPrice={setSelectedPrice}
          setVideoDuration={setVideoDuration}
          setLevel={setLevel}
          setFreeOrPaid={setFreeOrPaid}
          resetFilters={setresetFilters}
        />
        <div className='container courses-container'>
          <div className='courses-header'>
            <span>شاهد</span>
            <span className='text-color'>{` وتعلم `}</span>
            <span>{`واستثمر `}</span>
            <span className='text-color'>{`في نفسك في `}</span>
            <span>الوقت ذاته</span>
          </div>
          <div className="search-container">
            <img src={searchIcon} className="search-icon" alt="Search" />
            <input
              type="text"
              className="search-input"
              placeholder="ابحث..."
              value={searchTerm}
              onChange={handleInputChange}
            />
          </div>
          <SidebarToggle
      setSelectedCategory={setSelectedCategory}
      setSelectedRating={setSelectedRating}
      setSelectedPrice={setSelectedPrice}
      setVideoDuration={setVideoDuration}
      setLevel={setLevel}
      setFreeOrPaid={setFreeOrPaid}
      resetFilters={setresetFilters}
      />
              <AllCoursesFilter
            searchTerm={searchTerm}
            selectedCategory={selectedCategory}
            selectedRating={selectedRating}
            selectedPrice={selectedPrice}
            videoDuration={videoDuration}
            level={level}
            freeOrPaid={freeOrPaid}
            courses={courses}
            users={users}
            reset={resetFilters}
          />
        </div>
      </section>
    );
  }
  


export default CoursesPage;
