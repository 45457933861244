import './css/instprofile.css';
import BooksImages from './images/books.webp';
import React, { useState, useEffect } from "react";
import axios from '../config/index';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';

function InstProfile() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const navigate = useNavigate();

    const [itemData, setItemData] = useState({});
    const [courseData, setCourseData] = useState([]);
    const [filteredCourses, setFilteredCourses] = useState([]);
    const [totalEnrolledStudents, setTotalEnrolledStudents] = useState(0);

    useEffect(() => {
        getItemData(id);
        getAllCourses();
    }, [id]);

    useEffect(() => {
        if (courseData.length > 0 && Object.keys(itemData).length > 0) {
            const filteredCourses = courseData.filter(course => course.user_id == id && course.status == 'active');
            setFilteredCourses(filteredCourses);
            console.log(filteredCourses)

        }
    }, [courseData, itemData, id]);

    useEffect(() => {
        filterCourses(filteredCourses);
    }, [filteredCourses]);

    const getItemData = (itemId) => {
        axios.post('/get-user', { itemId, table: 'users' })
            .then((res) => {
                if (!res.data.message) {
                    if (res.data.type !== "inst") {
                        navigate("/");
                    }
                    setItemData(res.data);
                }
            })
            .catch((err) => console.log(err));
    };

    const getAllCourses = () => {
        axios.post('/AllItems', { table: 'courses' })
            .then((res) => {
                if (res.data) {
                    setCourseData(res.data);
                    console.log(res.data)
                }
            })
            .catch((err) => console.log(err));
    };

    const filterCourses = (allCourses) => {
        if (allCourses.length) {
            const enrolledStudentsSum = allCourses.reduce((sum, course) => sum + (course.enrolled_students || 0), 0);
            setTotalEnrolledStudents(enrolledStudentsSum);
            console.log(enrolledStudentsSum)

        }
    };

    const Stars = Array(5).fill(null);

    return (
        <section className='inst-profile'>
            <div className='inst-header'>
                <div className='background-colored'></div>
                <div className='inst-card'>
                    <img src={BooksImages} alt='books' />
                    <h2> مدرب في مييد </h2>
                    <h1> تعلم الان مع {itemData.fullname} </h1>
                    <h3> إعمل - تعلم - سافر </h3>
                    <div className='info'>
                        <img src={`./uploads/${itemData.profile_img}`} alt='instructor image' />
                        <div className='info-data'>
                            <h4> {itemData.fullname} </h4>
                            <div className='reviews'>
                                <ul>
                                    {Stars.map((_, i) => (
                                        <li key={i}>
                                            <i className="las la-star"></i>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className='courses-stu'>
                                <span> <i className="las la-book"></i> {filteredCourses.length} كورس </span>
                                <span> <i className="las la-users"></i> {totalEnrolledStudents} طالب </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='inst-description'>
                    <h2>السيرة الذاتية </h2>
                    <p>{itemData.aboutuser}</p>
                    <ul>
                        {itemData.facebook && (
                            <li>
                                <a href={itemData.facebook}>
                                    <i className="lab la-facebook-f"></i>
                                </a>
                            </li>
                        )}
                        {itemData.twitter && (
                            <li>
                                <a href={itemData.twitter}>
                                    <i className="lab la-twitter"></i>
                                </a>
                            </li>
                        )}
                        {itemData.github && (
                            <li>
                                <a href={itemData.github}>
                                    <i className="lab la-github"></i>
                                </a>
                            </li>
                        )}
                        {itemData.linkedin && (
                            <li>
                                <a href={itemData.linkedin}>
                                    <i className="lab la-linkedin-in"></i>
                                </a>
                            </li>
                        )}
                        {itemData.instagram && (
                            <li>
                                <a href={itemData.instagram}>
                                    <i className="lab la-instagram"></i>
                                </a>
                            </li>
                        )}
                    </ul>
                    {itemData.phone && (
                        <div>
                            <a href={`tel:${itemData.phone}`}> {itemData.phone} <i className="las la-phone-volume"></i> </a>
                        </div>
                    )}
                </div>
                <div className='int-courses'>
                    <div className='row'>
                        {filteredCourses.length ? (
                            filteredCourses.map((course, index) => (
                                <div className='col-lg-4 col-md-4 col-sm-12 col-12' key={index}>
                                    <div className='course-card'>
                                        <RouterLink to={`/course?id=${course.id}`}>
                                            <img src={`./uploads/${course.img}`} alt='course image' />
                                        </RouterLink>
                                        <div className='text'>
                                            <div className='reviews'>
                                                <ul>
                                                    {Stars.map((_, i) => (
                                                        <li key={i}>
                                                            <i className="las la-star"></i>
                                                        </li>
                                                    ))}
                                                </ul>
                                                <span>( مراجعات)</span>
                                            </div>
                                            <h1>{course.header}</h1>
                                            <div className='course-info'>
                                                <span><i className="las la-users"></i> {course.enrolled_students} طلاب</span>
                                            </div>
                                            <p className='section-text'>{course.description}</p>
                                            <div className='course-price'>
                                                {course.free === 1 ? <h3> مجاني </h3> : (
                                                    <>
                                                        <h3>${course.price} <span>${course.prev_price}</span></h3>
                                                    </>
                                                )}
                                                <RouterLink to={`/course?id=${course.id}`}>اعرف أكثر <i className="las la-arrow-right"></i></RouterLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : <></>}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default InstProfile;
