import './css/dashboardtable.css';
import './css/editform.css';
import React, { useState, useEffect } from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';




function EditSocial() {



    useEffect(() => {
        axios.get('/admin-session')
            .then((res) => {
                if (res.data.validadmin) {
                    if (res.data.type !== "admin") {
                        navigate('/admin-login');
                    }
                } else {
                    // Handle the case when session is not valid
                }
            })
            .catch((err) => console.log(err));
    }, []);




    const [formChanged, setFormChanged] = useState(false); // Track if the form has changed


    const handleInputChange = () => {
        setFormChanged(true); // Set the formChanged flag to true when any input changes
    };




    const navigate = useNavigate();


    const [ItemData, setItemData] = useState({});


    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');


    const [formInputs, setFormInputs] = useState({
        facebook: "",
        twitter: "",
        instagram: "",
        linkedin: "",
        youtube: "",
        phone: "",
        email: "",
        whatsapp: "",

    });
    
    // Use formInputs to set the initial value of inputs
    useEffect(() => {
        if (ItemData.id) {
        setFormInputs({
            
            facebook: ItemData.facebook,
            twitter: ItemData.twitter,
            instagram: ItemData.instagram,
            linkedin: ItemData.linkedin,
            youtube: ItemData.youtube,
            phone: ItemData.phone,
            email: ItemData.email,
            whatsapp: ItemData.whatsapp,


        });
        }
    }, [ItemData]);

        const handleChange = (e) => {
            handleInputChange();
            const { name, value, type } = e.target;
            const inputValue = type === "file" ? e.target.files[0] : value;
        
            setFormInputs((prevFormInputs) => ({
            ...prevFormInputs,
            [name]: inputValue,
            }));
        };


        
        const handleSubmit = async (e) => {
            e.preventDefault();
        
            let Loading = document.querySelector(".loading-screen");
            Loading.classList.add("active");
        
            const { facebook,twitter,instagram,linkedin,youtube,phone,email,whatsapp } = formInputs;
        
            if (!facebook || !twitter || !instagram || !linkedin || !youtube || !phone ||!email || !whatsapp  ) {
            return alert("All fields are required");
            }
        
            try {
            const form = new FormData();
            form.append("id", id);
            form.append("facebook", facebook);
            form.append("twitter", twitter);
            form.append("instagram", instagram);
            form.append("linkedin", linkedin);
            form.append("youtube", youtube);
            form.append("phone", phone);
            form.append("email", email);
            form.append("whatsapp", whatsapp);
        
            const response = await axios.post("/update-social", form);
        
            if (response.status === 200) {
                // Update LandingSlide state after a successful update
                setItemData((prevItemData) => ({
                ...prevItemData,
                    facebook,twitter,instagram,linkedin,youtube,phone,email,whatsapp
                }));
        
                Loading.classList.remove("active");
                let alertS = document.querySelector(".alert-success");
                alertS.classList.add("active");
        
                setTimeout(() => {
                alertS.classList.remove("active");
                }, 3000);
            } else {
                Loading.classList.remove("active");
                let alertD = document.querySelector(".alert-danger");
                alertD.classList.add("active");
                setTimeout(() => {
                alertD.classList.remove("active");
                }, 3000);
            }
            } catch (error) {
            console.error(error);
            alert("Server error");
            }
        };




    axios.defaults.withCredentials = true;
    
    const getItemData = (itemId) => {
        let table = "socials";
        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setItemData(res.data);
                }
            })
            .catch((err) => console.log(err));
    };




    const getIfLogin = () => {
        axios.get('/admin-session')
            .then((res) => {
                if (!res.data.validadmin || id <= 0 || id == null || id == "") {
                    navigate('/admin-login');
                } else {
                    getItemData(id);
                }
            })
            .catch((err) => console.log(err));
    };
    



    useEffect(() => {
        getItemData(id);
    }, []);
    
    return (
        <>

<div className='loading-screen'>
                            <div class="loader">
                                <div class="outer"></div>
                                <div class="middle"></div>
                                <div class="inner"></div>
                            </div>

                        </div>
            <section className='dashboard'>
                <div className='edit-form'>

                
                <div className='alert-success'>
                        <h1> تمت الإضافة  <i class="las la-check-circle"></i></h1>
                    </div>

                    <div className='alert-danger'>
                        <h1> فشل التعديل <i class="las la-exclamation-triangle"></i></h1>

                    </div>


                    <form onSubmit={handleSubmit}>
                

            
                        
                    <div className='input'>
                        <label> فيسبوك  </label>
                        <input   value={formInputs.facebook} onChange={handleChange} type='text' required="required"  maxLength="250" name='facebook'/>
                    </div>

                    <div className='input'>
                        <label> تويتر  </label>
                        <input   value={formInputs.twitter} onChange={handleChange} type='text' required="required"  maxLength="250" name='twitter'/>
                    </div>


                    <div className='input'>
                        <label> انستجرام  </label>
                        <input   value={formInputs.instagram} onChange={handleChange} type='text' required="required"  maxLength="250" name='instagram'/>
                    </div>

                    <div className='input'>
                        <label> لينكد ان  </label>
                        <input   value={formInputs.linkedin} onChange={handleChange} type='text' required="required"  maxLength="250" name='linkedin'/>
                    </div>


                    <div className='input'>
                        <label> اليوتيوب  </label>
                        <input   value={formInputs.youtube} onChange={handleChange} type='text' required="required"  maxLength="250" name='youtube'/>
                    </div>

                    <div className='input'>
                        <label> رقم الهاتف  </label>
                        <input   value={formInputs.phone} onChange={handleChange} type='text' required="required"  maxLength="250" name='phone'/>
                    </div>


                    <div className='input'>
                        <label> البريد الإلكتروني  </label>
                        <input   value={formInputs.email} onChange={handleChange} type='email' required="required"  maxLength="250" name='email'/>
                    </div>

                    <div className='input'>
                        <label> واتساب  </label>
                        <input   value={formInputs.whatsapp} onChange={handleChange} type='text' required="required"  maxLength="250" name='whatsapp'/>
                    </div>





                                <button disabled={!formChanged} type='submit'> تحديث </button>
                            </form>
                        </div>
                    </section>
                </>
            );
            }
            


            export default EditSocial;
