import "./css/landcontact.css";
import { Link as RouterLink } from "react-router-dom";
import axios from "../config/index";
import { useState, useEffect } from "react";
import PlatformFacebookColorNegative from "./images/contact/Social Icons(3).svg";
import PlatformLinkedinColorNegative from "./images/contact/Social Icons(2).svg";
import PlatformXTwitterColorNegative from "./images/contact/twiter.svg";
import PlatformYoutubeColorNegative from "./images/contact/youtupe.svg";
import SocialIcons from "./images/contact/Social Icons.svg";
import img from "./images/contact/Contact.png";
import img2 from "./images/contact/Vector 211.png";

function LandContact() {
  // const [socails, setsocails] = useState([]);

  // const getAllSocials = (itemId) => {
  //   const table = "socials";
  //   axios
  //     .post("/get-item", { itemId, table })
  //     .then((res) => {
  //       if (!res.data.message) {
  //         setsocails(res.data);
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };

  // useEffect(() => {
  //   getAllSocials(1);
  // }, []);
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const [showInfo, setShowInfo] = useState(false);
  // State variables to hold the form input values
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  // Handler for form submission
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent page refresh
    // You can send the form data to an API or handle it as needed
    console.log("Form Data Submitted:" +  name, email, message );

    // Optionally, reset the form fields after submission
    setName("");
    setEmail("");
    setMessage("");
  };

  return (
    <section className="land-contact">
      <div className="box">
        <div className="contact-welcom">
          <div className="contact-welcom-content">
            <div className="text">
              <p className="contact-us">اتصل بنا </p>
              <h1>موجودون دائما لتلقي رسائلكم</h1>
            </div>
            <div className="icons">
              <img src={PlatformYoutubeColorNegative} className="" />
              <img src={SocialIcons} className="" />
              <img src={PlatformLinkedinColorNegative} className="" />
              <img src={PlatformXTwitterColorNegative} className="" />
              <img src={PlatformFacebookColorNegative} className="" />
            </div>
          </div>

          <div className="image">
            <img
              src={img}
              className="contact-us-rafiki"
              alt="Contact us rafiki"
            />
          </div>
        </div>
      </div>
      <div className="custmor-service">
        <div className="contact-form-container">
          <form className="contact-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                placeholder="الاسم الخاص"
                className="form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                placeholder="البريد الإلكتروني"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <textarea
                placeholder="الرسالة"
                className="form-control"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
            <div className="form-group">
              <button type="submit" className="submit-button" onClick={handleSubmit}>
                إرسال
              </button>
            </div>
          </form>
        </div>
        <div className="support-container">
          <h1 className="support-title">خدمة دعم العملاء</h1>
          <div className="support-steps">
            <div className="step-item">
              <div className="step-number">
                <span>1</span>
              </div>
              <div className="step-content">
                <h2>خدمة سريعة وإحترافية</h2>
                <p>في منصتنا التعليمية، نلتزم بتقديم خدمة سريعة واحترافية لضمان حصولكم على أفضل تجربة تعليمية ممكنة. فريقنا المتخصص <br/> يعمل بجد للرد على استفساراتكم ومعالجة طلباتكم بأسرع وقت <br/> وبأعلى مستوى من الاحترافية.
                </p>
              </div>
            </div>
            <div className="step-item">
              <div className="step-number">
                <span>2</span>
              </div>
              <div className="step-content">
                <h2>دعم مستمر 24 ساعة</h2>
                <p>في منصتنا التعليمية، نؤمن بأهمية توفير دعم مستمر على مدار الساعة لضمان تجربة تعليمية مثالية. فريقنا من المتخصصين في الدعم الفني متاح 24 ساعة يومياً، سبعة أيام في الأسبوع، لتقديم المساعدة في أي وقت تحتاجونه.
                </p>
              </div>
            </div>
            <div className="step-item">
              <div className="step-number">
                <span>3</span>
              </div>
              <div className="step-content">
                <h2>فريق دعم محترف</h2>
                <p>
                نفخر في منصتنا التعليمية بتقديم خدمات دعم تقني من خلال فريق دعم محترف يتمتع بالخبرة والمهارات اللازمة لتقديم أفضل تجربة للمستخدمين. فريقنا على دراية تامة بجميع جوانب المنصة ويعمل بجد لضمان حل أي مشكلة قد تواجهونها بسرعة وكفاءة. </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LandContact;
