import "./css/allcoursesfilter.css";
import React, { useEffect, useState } from "react";
 import { Link as RouterLink } from "react-router-dom";
import axios from "../config/index";
 
 
function AllCoursesFilter({
  searchTerm,
  selectedCategory,
  selectedRating,
  selectedPrice,
  videoDuration,
  level,
  freeOrPaid,
  resetFilters
}) {
  const [courses, setCourses] = useState([]);
  const [users, setUsers] = useState([]);
  function truncateText(text, wordLimit) {
    if (!text) return '';
    
    const words = text.split(' ');
    if (words.length <= wordLimit) return text;
  
    return words.slice(0, wordLimit).join(' ') + '...';
  }
  
  useEffect(() => {
    const getAllCourses = () => {
      axios.post("AllItems", { table: "courses" })
        .then((res) => setCourses(res.data || []))
        .catch((err) => console.log(err));
    };

    const getAllUsers = () => {
      axios.post("AllUsers", { table: "users" })
        .then((res) => setUsers(res.data || []))
        .catch((err) => console.log(err));
    };

    getAllCourses();
    getAllUsers();
  }, []);
  const activeCourses = courses.filter(course => course.status === 'active');

  const coursesWithInstructors = activeCourses.map(course => {
    const instructor = users.find(user => user.user_id === course.user_id) || {};
    return { ...course, instructor };
  });


  const parseDuration = (durationStr) => {
    const match = durationStr.match(/\d+/);
    return match ? parseInt(match[0], 10) : 0;
  };
  const filteredCourses = coursesWithInstructors.filter(course => {
    const matchesSearch = course.header.toLowerCase().includes(searchTerm.toLowerCase()) ||
                          course.description.toLowerCase().includes(searchTerm.toLowerCase());

    const courseDuration = parseDuration(course.duration);
    
    const matchesCategory = !selectedCategory || course.cat_id === selectedCategory;
    const matchesRating = !selectedRating || course.rate === parseFloat(selectedRating);
    const matchesPrice = !selectedPrice || course.price === selectedPrice;
    
    const matchesDuration = videoDuration.length === 0 || 
                            (videoDuration.includes('1') && courseDuration <= 1) ||
                            (videoDuration.includes('2') && courseDuration === 2) ||
                            (videoDuration.includes('3') && courseDuration > 3);

    const matchesLevel = level.length === 0 || level.includes(course.level);
    const matchesFreeOrPaid = freeOrPaid === null || 
      (freeOrPaid === 'free' && course.free === 1) || 
      (freeOrPaid === 'paid' && course.free === 0);

    return matchesSearch && matchesCategory && matchesRating && matchesPrice && matchesDuration && matchesLevel && matchesFreeOrPaid;
  });
    return (
      <section className="all-courses-filter">
        <div className="container">
          <div className="screens row gap-5">
            {filteredCourses.length ? (
              <div className="screen">
                <div className="cards">
                  {filteredCourses.map((course, courseIndex) => (
                    <div key={courseIndex}>
                      <article className="course-item2">
                        <RouterLink to={`/course?id=${course.id}`}>
                          <img
                           src={`./uploads/${course.img}`} 
                            alt="Course thumbnail image"
                            className="course-image"
                          />
                        </RouterLink>
                        <h2 className="course-title">{truncateText(course.header, 3)}</h2>
                        <p className="course-description"> {truncateText(course.description, 25)}</p>
                        <div className="rating-container2 d-flex align-baseline">
                          <span className="review-count" dir="rtl">({course.rate_num}تعليق )</span>
                          <div className="star-rating mt-2">
                            {Array.from({ length: course.rate }, (_, i) => (
                              <span key={i}>
                                <i className="las la-star"></i>
                              </span>
                            ))}
                          </div>
                        </div>
                        <div className="course-footer px-2 my-3">
                          <RouterLink to={`/course?id=${course.id}`}>
                            <button className="view-course-btn">عرض الدورة</button>
                          </RouterLink>
                          <div className="d-flex flex-column position-relative price-parent">
                            {course.free === 1 ? (
                              <h3>مجاني</h3>
                            ) : (
                              <>
                                <span className="course-price-old">{course.prev_price} د.ع</span>
                                <span className="course-price">
                                  {course.price ? course.price + " د.ع" : ''}
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                      </article>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="no-courses">
                <h2>لا توجد دورات حالياً</h2>
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
  

export default AllCoursesFilter;
