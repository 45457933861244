import './css/sidenav.css';
import React, { useState,useEffect} from "react";
import axios from '../../config/index';
import {Link, useNavigate} from 'react-router-dom';
import { useLocation } from "react-router-dom";

import Logo from '../images/logo.png';





function SideNav(props){

    useEffect(() => {
        axios
    .get('/admin-session')
    .then((res) => {
        if (res.data.validadmin) {

            navigate('/dashboard');

        } else {
            navigate('/admin-login');

        }
    })
    .catch((err) => console.log(err));
    },[]);

    
const navigate = useNavigate();
const [showUserOptions, setShowUserOptions] = useState(false);
const [user, setUser] = useState('');
const location = useLocation();

const [userDataLoaded, setUserDataLoaded] = useState(false); // New state variable




axios.defaults.withCredentials = true;



const logout = () => {
    axios
    .get('logout')
    .then((res) => {
        if (res.data.logout) {
            props.setSessionExists(false);
        navigate('/admin-login');

        } else {
        navigate('/admin-login');
        }
    })
    .catch((err) => console.log(err));
};


const handleUserOptionsClick = () => {
    setShowUserOptions(!showUserOptions);
};

const getUser = (userId) => {
    console.log(userId);
    axios
    .post('user', { userId })
    .then((res) => {
        if (res.data.message) {
            console.log(res.data.message);

        } else {
            setUser(res.data);
            setUserDataLoaded(true); 

        }
    })
    .catch((err) => console.log(err));

};


const openCloseNav = ()=>{
    let SideNav = document.querySelector(".sidenav");
    let searchBar = document.querySelector(".search-bar");
    let dashboard = document.querySelector(".dashboard");

    if(SideNav.classList.contains("active")){
        SideNav.classList.remove("active");
        searchBar.classList.remove("active");
        dashboard.classList.remove("active");
    }else{
        SideNav.classList.add("active");
        searchBar.classList.add("active");
        dashboard.classList.add("active");
    }
    

}


useEffect(() => {
    axios
    .get('/admin-session')
    .then((res) => {
        if (res.data.validadmin) {
            props.setSessionExists(true);
            getUser(res.data.userId);
            setUserDataLoaded(true); 

            console.log(userDataLoaded);

        } else {
            props.setSessionExists(false);
            getUser(0);
            console.log(userDataLoaded);

        }
    })
    .catch((err) => console.log(err));
}, [props.sessionExists]);













    return(
        <>
            
            <div  className={`${props.className} sidenav`}>
                <button onClick={openCloseNav} className='open-close-nav'> <i class="las la-bars"></i> </button>
                <div className="sidenav-content">
                    <div className="user-info">
                    {userDataLoaded && props.sessionExists ? (
                        <>
                            <img src={Logo} alt="logo" />
                        </>
                            ) : <></>}
                    </div>
                


                <div className='sidenav-elements'>
                    <ul>
                    

                        <li className={location.pathname === "/dashboard"? 'active':null}>
                            <Link to="/dashboard">
                            <i class="las la-home"></i>
                                لوحة التحكم
                            </Link>
                        </li>

                        
                        <li className={location.pathname === "/all-users"? 'active':null}>
                            <Link to="/all-users">
                                <i class="las la-poll-h"></i>
                                المستخدمين
                            </Link>
                        </li>
                        
                        
                        <li className={location.pathname === "/all-subscribers"? 'active':null}>
                            <Link to="/all-subscribers">
                                <i class="las la-poll-h"></i>
                                المشتركين
                            </Link>
                        </li>

                        
                        <li className={location.pathname === "/all-landings"? 'active':null}>
                            <Link to="/all-landings">
                                <i class="las la-poll-h"></i>
                                الواجهة الرئيسية
                            </Link>
                        </li>

                                                
                        <li className={location.pathname === "/all-categories"? 'active':null}>
                            <Link to="/all-categories">
                                <i class="las la-poll-h"></i>
                                الأقسام
                            </Link>
                        </li>

                        <li className={location.pathname === "/all-courses"? 'active':null}>
                            <Link to="/all-courses">
                                <i class="las la-poll-h"></i>
                                الكورسات
                            </Link>
                        </li>

                        <li className={location.pathname === "/all-lessons"? 'active':null}>
                            <Link to="/all-lessons">
                                <i class="las la-poll-h"></i>
                                الدروس
                            </Link>
                        </li>


                        <li className={location.pathname === "/all-user-courses"? 'active':null}>
                            <Link to="/all-user-courses">
                                <i class="las la-poll-h"></i>
                                الدروس المفتوحة للطالب
                            </Link>
                        </li>

                        <li className={location.pathname === "/all-features"? 'active':null}>
                            <Link to="/all-features">
                                <i class="las la-poll-h"></i>
                                المميزات
                            </Link>
                        </li>

                        
                        <li className={location.pathname === "/all-numbers"? 'active':null}>
                            <Link to="/all-numbers">
                                <i class="las la-poll-h"></i>
                                الأرقام
                            </Link>
                        </li>

                    
                        <li className={location.pathname === "/all-events"? 'active':null}>
                            <Link to="/all-events">
                                <i class="las la-poll-h"></i>
                                الأحداث
                            </Link>
                        </li>

                        
                        <li className={location.pathname === "/all-rates"? 'active':null}>
                            <Link to="/all-rates">
                                <i class="las la-poll-h"></i>
                                التقييمات
                            </Link>
                        </li>


                        <li className={location.pathname === "/all-questions"? 'active':null}>
                            <Link to="/all-questions">
                                <i class="las la-poll-h"></i>
                                الأسألة
                            </Link>
                        </li>

                        <li className={location.pathname === "/all-social"? 'active':null}>
                            <Link to="/all-social">
                                <i class="las la-poll-h"></i>
                                السوشيال ميديا
                            </Link>
                        </li>





                        <li>
                            <button onClick={logout}>
                                <i class="las la-sign-out-alt"></i>
                                تسجيل الخروج
                            </button>
                        </li>

                        <li>
                            <Link to="/">
                                <i class="las la-search"></i>
                                زيارة الموقع
                            </Link>
                        </li>


                    </ul>
                </div>
            </div>
            </div>
        
        </>
    )
}

export default SideNav;