import React, { useState, useEffect, useRef } from 'react';
import './css/addcourse.css';
import axios from '../config/index';
import { useNavigate } from 'react-router-dom';

function EditUserInfo() {
    const [userId, setUserId] = useState(null);
    const [usertype, setUsertype] = useState(null);
    const [user, setUser] = useState({});
    const [formData, setFormData] = useState({
        fullname: '',
        password: '',
        phone: '',
        aboutuser: '',
        facebook: '',
        twitter: '',
        linkedin: '',
        instagram: '',
        github: '',


        profile_img: null,
    });
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const imgInputRef = useRef(null);

    const navigate = useNavigate();

    useEffect(() => {
        axios
            .get('/session')
            .then((res) => {
                if (res.data.valid) {
                    setUserId(res.data.userId);
                    setUsertype(res.data.type);
                } else {
                    navigate("/");
                }
            })
            .catch((err) => console.log(err));
    }, [navigate]);

    useEffect(() => {
        if (userId) {
            axios.post('/get-user', { itemId: userId })
                .then((res) => {
                    if (!res.data.message) {
                        setUser(res.data);
                        setFormData({
                            fullname: res.data.fullname || '',
                            password: '',
                            phone: res.data.phone || '',
                            aboutuser: res.data.aboutuser || '',
                            facebook: res.data.facebook || '',
                            twitter: res.data.twitter || '',
                            instagram: res.data.instagram || '',
                            linkedin: res.data.linkedin || '',
                            github: res.data.github || '',

                            profile_img: null,
                        });
                    }
                })
                .catch((err) => console.log(err));
        }
    }, [userId]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setFormData({ ...formData, [name]: files[0] });
    };

    const handleUpdate = (e) => {
        e.preventDefault();
    
        const data = new FormData();
        Object.keys(formData).forEach(key => {
            if (formData[key]) {
                data.append(key, formData[key]);
            }
        });
    
        setUploading(true);
    
        axios.post('/updateProfile', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (progressEvent) => {
                const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setUploadProgress(progress);
            }
        })
        .then(response => {
            setUploading(false);
            alert(response.data.message);
            navigate('/user-settings');
        })
        .catch(error => {
            console.error('Update failed:', error);
            setUploading(false);
        });
    };


    const handleDivClick = () => {
        imgInputRef.current.click();
    };

    return (
        <section className='add-course'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                        <div className='course-tip'>
                            <h1>الملحوظات</h1>
                            <ul>
                                <li>يمكنك اعادة تعيين بياناتك الشخصية من هنا.</li>
                                <li>الحجم القياسي لصورة الشخصية المصغرة هو 700x430.</li>
                                <li>يمكنك تعديل كلمة المرور الخاصة بك.</li>
                                <li>لا يمكنك تعديل البريد الإلكتروني المستخدم.</li>
                            </ul>
                        </div>
                    </div>

                    <div className='col-lg-8 col-md-8 col-sm-12 col-12'>
                        <div className='course-addition-inputs'>
                            <form onSubmit={handleUpdate}>
                                <div className='addition-input'>
                                    <div className='header'>
                                        <i className="las la-plus"></i>
                                        <h1>تفاصيل المستخدم</h1>
                                    </div>

                                    <div className='content'>
                                        <div className='input'>
                                            <label>الاسم الكامل</label>
                                            <input 
                                                type='text' 
                                                name='fullname' 
                                                value={formData.fullname}
                                                onChange={handleInputChange} 
                                            />
                                        </div>

                                        <div className='input'>
                                            <label>كلمة المرور</label>
                                            <input 
                                                type='password' 
                                                name='password' 
                                                value={formData.password}
                                                onChange={handleInputChange} 
                                            />
                                        </div>

                                        <div className='input'>
                                            <label>رقم الهاتف</label>
                                            <input 
                                                type='text' 
                                                name='phone' 
                                                value={formData.phone}
                                                onChange={handleInputChange} 
                                            />
                                        </div>


                                        <div className='input'>
                                            <label> السيرة الذاتية </label>
                                            <textarea 
                                                name='aboutuser' 
                                                value={formData.aboutuser}
                                                onChange={handleInputChange} 
                                            > </textarea>
                                        </div>


                                        <div className='input'>
                                            <label> فيسبوك </label>
                                            <input 
                                                type='text' 
                                                name='facebook' 
                                                value={formData.facebook}
                                                onChange={handleInputChange} 
                                            />
                                        </div>

                                        <div className='input'>
                                            <label> تويتر </label>
                                            <input 
                                                type='text' 
                                                name='twitter' 
                                                value={formData.twitter}
                                                onChange={handleInputChange} 
                                            />
                                        </div>

                                        <div className='input'>
                                            <label> جيت هاب </label>
                                            <input 
                                                type='text' 
                                                name='github' 
                                                value={formData.github}
                                                onChange={handleInputChange} 
                                            />
                                        </div>

                                        <div className='input'>
                                            <label> لينكد ان </label>
                                            <input 
                                                type='text' 
                                                name='linkedin' 
                                                value={formData.linkedin}
                                                onChange={handleInputChange} 
                                            />
                                        </div>


                                        <div className='input'>
                                            <label> انستجرام </label>
                                            <input 
                                                type='text' 
                                                name='instagram' 
                                                value={formData.instagram}
                                                onChange={handleInputChange} 
                                            />
                                        </div>





                                        <div className='upload-input'>
                                            <label>الصورة الشخصية</label>
                                            <div
                                                className='drag-drop-here'
                                                onClick={handleDivClick}
                                            >
                                                {formData.profile_img ? (
                                                    <img
                                                        src={URL.createObjectURL(formData.profile_img)}
                                                        alt="Uploaded"
                                                        className='uploaded-image'
                                                    />
                                                ) : (
                                                    <>
                                                        <i className="las la-cloud-upload-alt"></i>
                                                        <h2>اختر الملف</h2>
                                                    </>
                                                )}
                                            </div>
                                            <input
                                                type='file'
                                                name='profile_img'
                                                ref={imgInputRef}
                                                onChange={handleFileChange}
                                                style={{ display: 'none' }}
                                            />
                                        </div>

                                        {uploading && (
                                            <div className='uploading'>
                                                <div className='progress'>
                                                    <div className='progress-bar' style={{ width: `${uploadProgress}%` }}></div>
                                                </div>
                                                <span>{uploadProgress}%</span>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className='addition-btns'>
                                    <button type='submit' className='submit-course link-btn-color'>تحديث المعلومات <i className="las la-arrow-right"></i></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    );
}

export default EditUserInfo;
