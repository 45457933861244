import './css/features.css';
import { Link as RouterLink } from 'react-router-dom';
import FeatureIcon1 from './images/features/1.png';
import FeatureIcon2 from './images/features/2.png';
import FeatureIcon3 from './images/features/3.png';
import FeatureIcon4 from './images/features/4.png';

import axios from '../config/index';
import { useState, useEffect } from 'react';

function Features() {
    

    const [featuresData, setfeaturesData] = useState([]);

    const getFeaturesData = (itemId) => {
        const table = "features";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (!res.data.message) {
                setfeaturesData(res.data);
            }
        }).catch((err) => console.log(err));
    };

    useEffect(() => {
        getFeaturesData(1);
    }, []);


    return (
        featuresData.length ? (
            <section className='features'>
            <div className='container'>
                <div className='section-header'>
                    <h2 className='text-btn'> ميزات ميد </h2>

                    <h1 className='section-headline'> المعرفة قوة، ونحن نقدمها </h1>
                </div>

                <div className='row'>
                    <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                        <div className='feature'>
                            <div className='top'>
                                <img src={FeatureIcon1} alt='icon' />
                                <h3> {featuresData.header1} </h3>
                                <p className='section-text'>
                                {featuresData.para1} 
                                </p>
                            </div>

                            <RouterLink to='/courses'> تعرف على المزيد <i class="las la-arrow-right"></i> </RouterLink>
                        </div>
                    </div>

                    <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                        <div className='feature'>
                            <div className='top'>
                                <img src={FeatureIcon2} alt='icon' />
                                <h3>  {featuresData.header2}  </h3>
                                <p className='section-text'>
                                {featuresData.para2} 
                                </p>
                            </div>

                            <RouterLink to='/courses'> تعرف على المزيد <i class="las la-arrow-right"></i> </RouterLink>
                        </div>
                    </div>

                    <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                        <div className='feature'>
                            <div className='top'>
                                <img src={FeatureIcon3} alt='icon' />
                                <h3>  {featuresData.header3}  </h3>
                                <p className='section-text'>
                                {featuresData.para3} 
                                </p>
                            </div>

                            <RouterLink to='/courses'> تعرف على المزيد <i class="las la-arrow-right"></i> </RouterLink>
                        </div>
                    </div>

                    <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                        <div className='feature'>
                            <div className='top'>
                                <img src={FeatureIcon4} alt='icon' />
                                <h3>  {featuresData.header4}  </h3>
                                <p className='section-text'>
                                {featuresData.para4} 
                                </p>
                            </div>

                            <RouterLink to='/courses'> تعرف على المزيد <i class="las la-arrow-right"></i> </RouterLink>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        ) : <></>
    );
}

export default Features;
