import './css/dashboardtable.css';
import './css/editform.css';
import React, { useState, useEffect } from "react";
import axios from '../../config/index';
import { useNavigate, useLocation } from 'react-router-dom';

function EditUsersCourse() {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    const [courses, setAllCourses] = useState([]);
    const [users, setUsers] = useState([]);
    const [course_id, setCourseId] = useState();
    const [user_id, setUserId] = useState();

    useEffect(() => {
        axios.get('/admin-session')
            .then((res) => {
                if (res.data.validadmin) {
                    if (res.data.type !== "admin") {
                        navigate('/admin-login');
                    }
                } else {
                    navigate('/admin-login');
                }
            })
            .catch((err) => console.log(err));
    }, [navigate]);

    useEffect(() => {
        getItemData(id);
        getAllCourses();
        getAllUsers();
    }, [id]);

    const getItemData = (itemId) => {
        let table = "user_courses";
        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data) {
                    setCourseId(res.data.course_id);
                    setUserId(res.data.user_id);
                }
            })
            .catch((err) => console.log(err));
    };

    const getAllCourses = () => {
        axios.post('AllItems', { table: 'courses' })
            .then((res) => {
                if (res.data) {
                    setAllCourses(res.data);
                } else {
                    console.log("Error happened");
                }
            })
            .catch((err) => console.log(err));
    };

    const getAllUsers = () => {
        axios.post('AllUsers', { table: 'users' })
            .then((res) => {
                if (res.data) {
                    setUsers(res.data);
                } else {
                    console.log("Error happened");
                }
            })
            .catch((err) => console.log(err));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    
        let Loading = document.querySelector(".loading-screen");
        Loading.classList.add("active");
    
        if (!course_id || !user_id) {
            console.error("Course ID and User ID must be provided");
            Loading.classList.remove("active");
            let alertD = document.querySelector(".alert-danger");
            alertD.classList.add("active");
            setTimeout(() => {
                alertD.classList.remove("active");
            }, 3000);
            return;
        }
    
        const data = {
            id: id,
            course_id: course_id,
            user_id: user_id,
        };
    
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
    
        axios.put("/update-user-course", data, config)
            .then((response) => {
                Loading.classList.remove("active");
    
                let alertS = document.querySelector(".alert-success");
                alertS.classList.add("active");
    
                setTimeout(() => {
                    alertS.classList.remove("active");
                }, 3000);
            })
            .catch((error) => {
                Loading.classList.remove("active");
    
                let alertD = document.querySelector(".alert-danger");
                alertD.classList.add("active");
                setTimeout(() => {
                    alertD.classList.remove("active");
                }, 3000);
                console.log(error);
            });
    };
    
    axios.defaults.withCredentials = true;

    return (
        <>
            <div className='loading-screen'>
                <div className="loader">
                    <div className="outer"></div>
                    <div className="middle"></div>
                    <div className="inner"></div>
                </div>
            </div>
            <section className='dashboard'>
                <div className='edit-form'>
                    <div className='alert-success'>
                        <h1> تمت التحديث بنجاح <i className="las la-check-circle"></i></h1>
                    </div>
                    <div className='alert-danger'>
                        <h1> فشل في التحديث <i className="las la-exclamation-triangle"></i></h1>
                    </div>
                    <h1> تحديث الكورس الخاص بالطالب </h1>
                    <form onSubmit={handleSubmit}>
                        <div className='input'>
                            <label> الكورس </label>
                            <select value={course_id} onChange={(event) => setCourseId(event.target.value)} required="required" name='course_id'>
                                <option value=""> أختر الكورس </option>
                                {courses.length ? (
                                    courses.map((course) => (
                                        <option key={course.id} value={course.id}> ({course.id}) {course.header} </option>
                                    ))
                                ) : (
                                    null
                                )}
                            </select>
                        </div>
                        <div className='input'>
                            <label> الطالب </label>
                            <select value={user_id} onChange={(event) => setUserId(event.target.value)} required="required" name='user_id'>
                                <option value=""> أختر الطالب </option>
                                {users.length ? (
                                    users.map((user) => (
                                        <option key={user.user_id} value={user.user_id}> ({user.user_id}) {user.email} </option>
                                    ))
                                ) : (
                                    null
                                )}
                            </select>
                        </div>
                        <button type='submit'> تحديث </button>
                    </form>
                </div>
            </section>
        </>
    )
}

export default EditUsersCourse;
