import './css/dashboardtable.css';
import React, { useState, useEffect } from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';

function AllLessons() {
    const navigate = useNavigate();
    const [AllItems, setAllItems] = useState([]);
    const [lessons, setlessons] = useState({});

    axios.defaults.withCredentials = true;

    useEffect(() => {
        axios.get('admin-session')
            .then((res) => {
                if (res.data.validadmin) {
                    if (res.data.type !== "admin") {
                        navigate('/admin-login');
                    }
                }
            })
            .catch((err) => console.log(err));
        
        getAllItems();
        getAllLessons();
    }, []);

    const getAllItems = () => {
        axios.post('AllItems', { table: 'questions' })
            .then((res) => {
                if (res.data) {
                    setAllItems(res.data);
                } else {
                    console.log("Error happened");
                }
            })
            .catch((err) => console.log(err));
    };

    
    const getAllLessons = () => {
        axios.post('AllItems', { table: 'lessons' })
            .then((res) => {
                if (res.data) {
                    setlessons(res.data);
                } else {
                    console.log("Error happened");
                }
            })
            .catch((err) => console.log(err));
    };

    const getIfLogin = () => {
        axios.get('/admin-session')
            .then((res) => {
                if (!res.data.validadmin) {
                    navigate('/admin-login');
                }
            })
            .catch((err) => console.log(err));
    };

    const deleteItem = (id) => {
        axios.delete(`delete-item/${id}`, { data: { table: 'questions' } })
            .then((response) => {
                if (response.data.message === 'Item deleted successfully') {
                    getAllItems();
                } else {
                    console.error('Failed to delete result:', response.data.message);
                }
            })
            .catch((error) => {
                console.error('Error deleting result:', error);
            });
    };

    const handleFullScreen = (videoElement) => {
        if (videoElement.requestFullscreen) {
            videoElement.requestFullscreen();
        } else if (videoElement.mozRequestFullScreen) { // Firefox
            videoElement.mozRequestFullScreen();
        } else if (videoElement.webkitRequestFullscreen) { // Chrome, Safari and Opera
            videoElement.webkitRequestFullscreen();
        } else if (videoElement.msRequestFullscreen) { // IE/Edge
            videoElement.msRequestFullscreen();
        }
    };

    return (
        <>
            <section className='dashboard'>
                <div className='results-table'>
                    <table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th> العنوان </th>
                                <th> الاجابة الاولى </th>
                                <th> الاجابة الثانية </th>
                                <th> الاجابة الثالثة </th>
                                <th> الاجابة الرابعة </th>
                                <th> الاجابة الصحيحة </th>
                                <th> الدرس </th>


                                <th>الخيارات</th>
                            </tr>
                        </thead>
                        <tbody>
                            {AllItems.length ? (
                                AllItems.map((item) => {
                                    const lesson = lessons[item.lesson_id] || {};
                                    return (
                                        <tr key={item.id}>
                                            <td><h6>{item.id}</h6></td>
                                            
                                            <td><h6>{item.header}</h6></td>
                                            <td><h6>{item.answer_1}</h6></td>
                                            <td><h6>{item.answer_2}</h6></td>
                                            <td><h6>{item.answer_3}</h6></td>
                                            <td><h6>{item.answer_4}</h6></td>
                                            <td><h6>{item.correct_answer}</h6></td>
                                            <td><h6>{lessons.header}</h6></td>

                                            <td>
                                                <button className='delete' onClick={() => deleteItem(item.id)}>
                                                    <i className="las la-trash-alt"></i>
                                                </button>
                                                
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={14}>Loading...</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    );
}

export default AllLessons;
