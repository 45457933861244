import './css/footer.css';
import Logo from './images/logo.png';
import { Router, Link as RouterLink } from 'react-router-dom';
import axios from '../config/index';
import { useState, useEffect } from 'react';
function Footer(){

    
    const [socails, setsocails] = useState([]);


    
    const getAllSocials = (itemId) => {
        const table = "socials";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (!res.data.message) {
                setsocails(res.data);
            }
        }).catch((err) => console.log(err));
    };

    useEffect(() => {
        getAllSocials(1);
    }, []);


    return(
        <>
            <footer className='mt-5'>
                <div className='container'>
                <div className='d-flex flex-column flex-lg-row justify-content-between align-content-baseline'>
    <div className='col-lg-5 order-1 order-lg-4 mt-5 pt-4 col-md-3 col-sm-12 col-12'>
        <div className='footer-content'>
            <div className='logo'>
                <img src={Logo} alt='logo'/>
            </div>
            <p className='section-text'>
                تأسست "دورات ميد" على الاعتقاد بأن التعليم يجب أن يكون متاحًا ومرنًا وبأسعار معقولة. لقد قام فريقنا من الخبراء بتنسيق مجموعة من الدورات التي تغطي مجموعة واسعة من الموضوعات، مما يضمن أن يكون هناك شيء لكل شخص. نحن ملتزمون بتقديم أفضل تجربة تعلم من خلال محتوى عالي الجودة، ومدربين ذوي خبرة، ومجتمع داعم.
            </p>
        </div>
    </div>

    <div className='col-lg-2 order-2 order-lg-3 mt-5 pt-4 col-md-2 col-sm-6 col-12'>
        <div className='footer-content'>
            <ul>
                <h1> روابط مفيدة </h1>
                <li>
                    <RouterLink to="/">الصفحة الرئيسية</RouterLink>
                </li>
                {/* <li>
                    <RouterLink to="/about-us">عن ميد</RouterLink>
                </li> */}
                <li>
                    <RouterLink to="/courses">الدورات</RouterLink>
                </li>
                <li>
                    <RouterLink to="/categories">التصنيفات</RouterLink>
                </li>
            </ul>
        </div>
    </div>

    <div className='col-lg-2 order-3 order-lg-2 mt-5 pt-4 col-md-2 col-sm-6 col-12'>
        <div className='footer-content'>
            <ul>
                <h1> روابط ميد </h1>
                <li>
                    <RouterLink to="/">الشروط والأحكام</RouterLink>
                </li>
                <li>
                    <RouterLink to="/">سياسة الخصوصية</RouterLink>
                </li>
                <li>
                    <RouterLink to="/login-register">تسجيل الدخول والتسجيل</RouterLink>
                </li>  
                <li>
                    <RouterLink to="/register-ins">انضم كمدرب</RouterLink>
                </li>
            </ul>
        </div>
    </div>

    <div className='col-lg-2 order-4 order-lg-1 mt-5 pt-4 col-md-5 col-sm-12 col-12'>
        <div className='footer-content'>
            <ul>
                <h1> تواصل معنا </h1>
                <li>
                    <a href={`tel:${socails.phone}`} target='_blank'>
                        <strong> الهاتف: </strong>
                        {socails.phone}
                    </a>
                </li>
                <li>
                    <a className='email' href={`mailto:${socails.email}`} target='_blank'>
                        <strong> البريد الإلكتروني: </strong>
                        {socails.email}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>


                </div>
                    <div className='  text-center w-100 justify-content-center align-content-center'>
                        <div className='copy-right'>
                            <div className='left pb-3'>
                                <h5> حقوق الطبع والنشر &copy; 2023 <strong> دورات ميد </strong>. جميع الحقوق محفوظة </h5>
                            </div>

                            {/* <div className='right'>
                                <ul>
                                    <li>
                                        <RouterLink to="/"> الشروط والأحكام</RouterLink>
                                    </li>

                                    <li>
                                        <RouterLink to="/"> سياسة الخصوصية </RouterLink>
                                    </li>

                                    <li>
                                        <RouterLink to="/login-register"> تسجيل الدخول والتسجيل </RouterLink>
                                    </li>
                                </ul>
                            </div> */}
                        </div>
                    </div>
            </footer>
        </>
    )
}

export default Footer;
