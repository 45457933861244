import React, { useState, useRef, useEffect } from 'react';
import './css/coursepage.css';
import { Link as RouterLink } from 'react-router-dom';
import axios from '../config/index';
import { useLocation } from 'react-router-dom';

function CoursePage() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    const [courseData, setCourseData] = useState({});
    const [userData, setUserData] = useState({});
    const [lessons, setLessons] = useState([]);
    const [filteredCoursesCount, setFilteredCoursesCount] = useState(0);
    const [totalEnrolledStudents, setTotalEnrolledStudents] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef(null);
    const Stars = Array(5).fill(null);

    const getCourseData = (itemId) => {
        let table = "courses";
        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (!res.data.message) {
                    setCourseData(res.data);
                }
            })
            .catch((err) => console.log(err));
    };

    const getUser = (itemId) => {
        axios.post('get-user', { itemId })
            .then((res) => {
                if (!res.data.message) {
                    setUserData(res.data);
                }
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        if (courseData.user_id) {
            getUser(courseData.user_id);
        }
    }, [courseData.user_id]);

    useEffect(() => {
        if (id) {
            getCourseData(id);
        }
    }, [id]);

    const getAllLessons = () => {
        axios.post('AllItems', {
            table: 'lessons',
        })
            .then((res) => {
                if (res.data) {
                    setLessons(res.data);
                } else {
                    console.log("Error happened");
                }
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        getAllLessons();
    }, []);

    useEffect(() => {
        if (Object.keys(courseData).length && lessons.length) {
            addLessonCountToCourses();
        }
    }, [courseData, lessons]);

    const addLessonCountToCourses = () => {
        if (Array.isArray(courseData)) {
            const coursesWithLessons = courseData.map(course => {
                const lessonCount = lessons.filter(lesson => lesson.course_id === course.id).length;
                return { ...course, lessonCount };
            });
            setCourseData(coursesWithLessons);
        }
    };

    const filterCourses = (allCourses) => {
        if (Array.isArray(allCourses) && allCourses.length) {
            const filteredCourses = allCourses.filter(course => course.user_id === userData.user_id);
            const filteredCoursesCount = filteredCourses.length;
            const enrolledStudentsSum = filteredCourses.reduce((sum, course) => sum + (course.enrolled_students || 0), 0);

            setFilteredCoursesCount(filteredCoursesCount);
            setTotalEnrolledStudents(enrolledStudentsSum);
        }
    };

    useEffect(() => {
        filterCourses(courseData);
    }, [courseData]);

    const handlePlayPause = () => {
        const video = videoRef.current;
        if (video.paused || video.ended) {
            video.play();
            setIsPlaying(true);
        } else {
            video.pause();
            setIsPlaying(false);
        }
    };

    useEffect(() => {
        const video = videoRef.current;
        if (video) {
            const handlePause = () => setIsPlaying(false);

            video.addEventListener('pause', handlePause);
            video.addEventListener('ended', handlePause);

            return () => {
                video.removeEventListener('pause', handlePause);
                video.removeEventListener('ended', handlePause);
            };
        }
    }, [videoRef]);

    return (
        
            <>
                <section className='course-page'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                                <div className='course-pay-details'>
                                    <div className='intro-video'>
                                        <video ref={videoRef} src={`./uploads/${courseData.intro_video}`} onClick={handlePlayPause}></video>

                                        {!isPlaying && (
                                            <button className='play-video' onClick={handlePlayPause}>
                                                <i className="las la-play"></i>
                                            </button>
                                        )}
                                    </div>
                                    <div className='price-time'>
                                        <div className='price'>
                                            {courseData.free == 1 ? <h5> مجاني </h5> :<>
                                                <h5>${courseData.price} <span>${courseData.prev_price}</span></h5>
                                            </>}
                                        </div>

                                        <div className='time'>
                                            <h5>
                                                <i className="las la-clock"></i>
                                                ينتهي العرض قريبا
                                            </h5>
                                        </div>
                                    </div>

                                    <RouterLink className='link-btn-color' to="/payment">
                                        <i className="las la-comments-dollar"></i>
                                        طلب الكورس
                                    </RouterLink>

                                    <ul>
                                        <li>
                                            <span className='main'>المدة</span>
                                            <span className='sub'>{courseData.duration}</span>
                                        </li>

                                        <li>
                                            <span className='main'>عدد الطلاب</span>
                                            <span className='sub'>{courseData.enrolled_students}</span>
                                        </li>

                                        <li>
                                            <span className='main'>عدد المحاضرات</span>
                                            <span className='sub'>{courseData.lessonCount || 0}</span>
                                        </li>

                                        <li>
                                            <span className='main'>المستوى</span>
                                            <span className='sub'>{courseData.level}</span>
                                        </li>

                                        <li>
                                            <span className='main'>اللغة المستخدمة</span>
                                            <span className='sub'>{courseData.language}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className='col-lg-8 col-md-8 col-sm-12 col-12'>
                                <div className='course-header'>
                                    <h1>{courseData.header}</h1>
                                    <p>{courseData.description}</p>

                                    <div className='rates'>
                                        <ul>
                                            {Stars.map((_, i) => (
                                                <li key={i}>
                                                    <i className="las la-star"></i>
                                                </li>
                                            ))}
                                        </ul>

                                        <h2 className='quntity-num'>{courseData.lessonCount || 0} درس</h2>

                                        <h2 className='quntity-num'>{courseData.enrolled_students} طالب</h2>
                                    </div>

                                    <div className='intructor-info'>
                                        <img src={`./uploads/${userData.profile_img}`} alt='instructor image' />
                                        <h3> صنع بواسطة <span> {userData.fullname} </span> </h3>
                                    </div>

                                    <div className='share-info'>
                                        <ul>
                                            <li>
                                                <i className="las la-calendar"></i>
                                                {courseData.date}
                                            </li>

                                            <li>
                                                <i className="las la-globe-europe"></i>
                                                {courseData.language}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='course-details'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                                <div className='content'>
                                    <img src={`./uploads/${courseData.img}`} alt='course image' />

                                    <div className='description'>
                                        <h2> ماذا نتعلم في هذا الكورس </h2>
                                        <p>{courseData.description}</p>
                                    </div>

                                    <div className='description'>
                                        <h2> ما هي متطلبات هذا الكورس </h2>
                                        <p>{courseData.requirments}</p>
                                    </div>

                                    <div className='instructor'>
                                        <h2> المدرب </h2>

                                        <div className='instructor-info'>
                                            <RouterLink to="/instructor-profile">
                                                <img src={`./uploads/${userData.profile_img}`} alt='img'/>
                                            </RouterLink>

                                            <div className='info'>
                                                <h1> {userData.fullname}  </h1>
                                                <h2>  مدرب خبير لدي مييد</h2>

                                                <div className='rates'>
                                                    <ul>
                                                        {Stars.map((_, i) => (
                                                            <li key={i}>
                                                                <i className="las la-star"></i>
                                                            </li>
                                                        ))}
                                                    </ul>

                                                    <h2 className='quntity-num'>{filteredCoursesCount} الكورسات</h2>
                                                    <h2 className='quntity-num'>{totalEnrolledStudents} طالب</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        
    );
}

export default CoursePage;
