import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from '../config/index';
import './css/editlesson.css';

function EditLesson() {
    const [lessons, setLessons] = useState([]);
    const [loadingLessonId, setLoadingLessonId] = useState(null); // Track loading state for each lesson
    const navigate = useNavigate();
    const location = useLocation();
  
    const queryParams = new URLSearchParams(location.search);
    const courseId = Number(queryParams.get('courseId')); 

    useEffect(() => {
        const getAllLessons = async () => {
            try {
                const numericCourseId = Number(courseId);
                if (isNaN(numericCourseId)) {
                    console.error('Invalid course_id:', courseId);
                    return;
                }

                const response = await axios.post('/AllItems', {
                    table: "lessons"
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                const courseLessons = response.data.filter(lesson => lesson.course_id === numericCourseId);
                courseLessons.sort((a, b) => a.id - b.id);
                setLessons(courseLessons);
            } catch (error) {
                console.error('Error fetching lessons:', error.message);
            }
        };

        getAllLessons();
    }, [courseId]);
    
    const handleVideoUpload = (e, lessonId) => {
        const file = e.target.files[0];
        if (file) {
            setLessons(prevLessons => prevLessons.map(lesson => 
                lesson.id === lessonId 
                    ? { ...lesson, videoFile: file, video: URL.createObjectURL(file) } 
                    : lesson
            ));
        }
    };
    

 const handleDeleteVideo = async (lessonId) => {
    setLessons(prevLessons => prevLessons.map(lesson =>
        lesson.id === lessonId ? { ...lesson, videoFile: null, video: '' } : lesson
    ));
    try {
        await axios.delete(`/delete-video/${lessonId}`, {
            withCredentials: true, // Include session credentials
        });
        
        // Update the lesson state to remove the video
        setLessons(prevLessons => prevLessons.map(lesson =>
            lesson.id === lessonId ? { ...lesson, videoFile: null, video: '' } : lesson
        ));
        
        console.log('Video deleted successfully for lesson:', lessonId);
    } catch (error) {
        console.error('Error deleting video:', error.response ? error.response.data.message : error.message);
    }
};


    const handleUpdateLesson = async (lessonId) => {    
        setLoadingLessonId(lessonId); // Show loading state for this specific lesson
        const lesson = lessons.find(l => l.id === lessonId);

        if (!lesson) {
            setLoadingLessonId(null);
            console.error('Lesson not found');
            return;
        }

        const formData = new FormData();
        formData.append('id', lesson.id);
        formData.append('header', lesson.header);
        formData.append('course_id', lesson.course_id);
        if (lesson.videoFile) {
            formData.append('video', lesson.videoFile);
        }

        try {
            await axios.put('/update-lesson', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
            });
            setLoadingLessonId(null); // Hide loading state
            console.log('Lesson updated:', lessonId);

            setLessons(prevLessons => prevLessons.map(l =>
                l.id === lessonId ? { ...l, videoFile: null, video: lesson.video } : l
            ));
        } catch (error) {
            setLoadingLessonId(null);
            console.error('Error updating lesson:', error.response ? error.response.data.message : error.message);
        }
    };

    return (
        <div className="input videoDiv2">
            {lessons.map((lesson) => (
                <div key={lesson.id} className="addition-input">
                    <div className="header">
                        <h1>{lesson.header}</h1>
                    </div>
                    <div className="content">
                        {loadingLessonId === lesson.id ? (
                            <div className="loading-overlay">جاري رفع الفديو برجاء الانتظار ...</div>
                        ) : lesson.video ? (
                            <video width="200" controls>
                                <source src={`./uploads/${lesson.video}`} type="video/mp4" /> 
                                Your browser does not support the video tag.
                            </video>
                        ) : (
                            <p>No video uploaded.</p>
                        )}
                        <input
                            id={`videoUploadInput-${lesson.id}`}
                            type="file"
                            accept="video/*"
                            onChange={(e) => handleVideoUpload(e, lesson.id)}
                            style={{ display: 'none' }} 
                        />
                        <div className="buttons">
                            <button className='edit' onClick={() => document.getElementById(`videoUploadInput-${lesson.id}`).click()}>
                                تغير
                            </button>
                            <button style={{ backgroundColor: 'green', color: 'white', important: 'true' }} className='edit' onClick={() => handleUpdateLesson(lesson.id)}>
                                حفظ 
                            </button>
                            <button className='delete' onClick={() => handleDeleteVideo(lesson.id)}>
                                حــــــــــــذف
                            </button>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default EditLesson;
