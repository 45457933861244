import './css/landevents.css';
import { Link as RouterLink } from 'react-router-dom';



import axios from '../config/index';
import { useState, useEffect } from 'react';
const EventCard = ({ imageUrl, date, time, title }) => {
    return (
      <div className="event-card" style={{ backgroundImage: `url(${imageUrl})` }}>
        <div className="overlay">
          <div className="event-details">
            <div className="event-date-time">
              <span className="event-date">📅 {date}</span>
              <span className="event-time">⏰ {time}</span>
            </div>
            <div className="event-title">{title}</div>
          </div>
          <div className="play-icon">▶</div>
        </div>
      </div>
    );
  };
function LandEvents() {


    const [EventData, setAllEvents] = useState([]);

    const getAllEvents = () => {
        axios.post('AllItems', { table: 'events' })
            .then((res) => {
                if (res.data) {
                    setAllEvents(res.data);
                } else {
                    console.log("Error happened");
                }
            })
            .catch((err) => console.log(err));
    };



    useEffect(() => {
        getAllEvents();
    }, []);


    const EventDataFilterd = EventData.slice(-4);
    const events = [
        {
          imageUrl: "https://via.placeholder.com/300x150", // Replace with your image URL
          date: "2024-07-15",
          time: "20:26",
          title: "معرض التعليم هيسنتونج 2023",
        },
        {
          imageUrl: "https://via.placeholder.com/300x150", // Replace with your image URL
          date: "2024-07-15",
          time: "20:26",
          title: "معرض التعليم الدولي 2023",
        },
        {
          imageUrl: "https://via.placeholder.com/300x150", // Replace with your image URL
          date: "2024-07-15",
          time: "20:26",
          title: "إضاءة صندوق جوائز وروقية أنيقة",
        },
      ];
  
    return (
        <>
            <section className='land-events'>
                <div className='container'>
                    <div className='row'>
                        <div className='section-header'>
                            <div>
                                <h2 className='text-btn'> الأحداث </h2>
                                <h1 className='events-headline'>شاركنا المتعة والاستفادة مع افضل 
                                الاحداث المنتظرة</h1>
                            </div>
                            {/* <RouterLink className='link-btn-color' to='/events'> عرض جميع الأحداث <i className="las la-arrow-right"></i> </RouterLink> */}
                        </div>

                         {EventDataFilterd.length ? (
                        EventDataFilterd.map((event, index) => (
                            <div key={index} className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                <div className='event'>
                                    <img src={`./uploads/${event.img}`} alt='event' />
                                    <div className='text'>
                                        <div>
                                            <ul>
                                                <li>
                                                    <a href={event.location} target='_blank'>
                                                        <i className="las la-map-marker-alt"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <i className="las la-calendar-day"></i>
                                                    {event.date}
                                                </li>
                                                <li>
                                                    <i className="las la-clock"></i>
                                                    {event.time}
                                                </li>
                                            </ul>
                                        </div>
                                        <h1>{event.header}</h1>
                                        <a className='link-btn-midcolor' href={event.link}>
                                            احصل على تذكرة <i className="las la-arrow-right"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                       ))
                            ) : <></>} 
                              {/* <div className="events-list">
      {events.map((event, index) => (
        <EventCard
          key={index}
          imageUrl={event.imageUrl}
          date={event.date}
          time={event.time}
          title={event.title}
        />
      ))}
    </div> */}
                    </div>
                </div>
            </section>
        </>
    );
}

export default LandEvents;
