import './css/dashboardtable.css';
import './css/editform.css';
import React, { useState,useEffect} from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';
import ShowButton from '../ShowButton';






function AddUser(){


        



    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const [email, setemail] = useState("");
    const [password, setPassword] = useState("");
    const [type, setType] = useState("admin");

    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");




    
    const clearForm = () => {
        const formInputs = document.querySelectorAll('input[type="text"], input[type="password"], input[type="file"]');
        formInputs.forEach((input) => {
            input.value = "";
        });
    };
    



    const handleSubmit = (event) => {
        event.preventDefault();

        let Loading = document.querySelector(".loading-screen"); 
        Loading.classList.add("active");


        const data = new FormData();
        data.append("email", email || "");
        data.append("password", password || "");
        data.append("type", type || "");
        

                        
            axios.post("/add-user-admin", data )
            .then((response) => {

                Loading.classList.remove("active");



                let alertS = document.querySelector(".alert-success"); 
                alertS.classList.add("active");
                clearForm();
                setTimeout(() => {
                    alertS.classList.remove("active");
                }, 3000);


                })
                


            .catch((error) => {


                Loading.classList.remove("active");


                let alertD = document.querySelector(".alert-danger"); 
                alertD.classList.add("active");
                clearForm();


                setTimeout(() => {
                    alertD.classList.remove("active");
                }, 3000);
                console.log(error);


            });
        


    }





    const [show, setShow] = useState(false);

    const handleToggle = () => {
        setShow(!show);
    };

    const handleChange = (event) => {
        setPassword(event.target.value);
    };

    axios.defaults.withCredentials = true;






    
    




    

    return(
        <>
            <div className='loading-screen'>
                <div class="loader">
                    <div class="outer"></div>
                    <div class="middle"></div>
                    <div class="inner"></div>
                </div>

            </div>


            <section className='dashboard'>



                <div className='edit-form'>

                    <div className='alert-success'>
                        <h1> تم الاضافة  <i class="las la-check-circle"></i></h1>
                    </div>

                    <div className='alert-danger'>
                        <h1> فشل الاضافة <i class="las la-exclamation-triangle"></i></h1>

                    </div>

                    <h1> اضافة مستخدم </h1>

                    <form onSubmit={handleSubmit}>


                    <div className='input'>
                        <label> البريد الإلكتروني  </label>
                        <input  onChange={(event) => setemail(event.target.value)} type='email' required="required"  maxLength="250" name='email'/>
                    </div>


                    <div className='input'>
                        <label> كلمة المرور  </label>
                        <input onChange={(e) => {handleChange(e);setPassword(e.target.value);}} type={show ? 'text' : 'password'} placeholder="New Password" maxLength="250" name='password'/>
                        <ShowButton className="show-btn" show={show} handleToggle={handleToggle} />
                    </div>

                    <button type='submit'> اضافة </button>

                    </form>
                </div>
            </section>
        
        </>
    )
}

export default AddUser;