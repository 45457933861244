import './css/landcategories.css';
import { Link as RouterLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from '../config/index';

function CategoriesPage() {
    const [categoriesdata, setAllCategories] = useState([]);

    const getAllCategories = () => {
        axios.post('AllItems', { table: 'categories' })
            .then((res) => {
                if (res.data) {
                    setAllCategories(res.data);
                } else {
                    console.log("Error happened");
                }
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        getAllCategories();
    }, []);

    return (
        <section className='land-categories'>
            <div className='container'>
                <div className='section-header'>
                    <h2 className='text-btn'> فئة الدورة </h2>
                    <h1 className='section-headline'> ابدأ رحلتك التعليمية اليوم </h1>
                </div>

                <div className='row'>
                    {categoriesdata.length ? (
                    categoriesdata.map((category, index) => (
                        <div key={index} className='col-lg-3 col-md-3 col-sm-6 col-12'>
                        <RouterLink to={`/category?id=${category.id}`}>
                            <div className='course-category'>
                            <img src={`./uploads/${category.img}`} alt='category' />
                            <div className='icon'>
                                <img src={`./uploads/${category.icon}`} alt='icon' />
                            </div>
                            <div className='text'>
                                <h3>{category.header}</h3>
                                <RouterLink to={`/category?id=${category.id}`}>
                                    {category.courses} دورة <i className="las la-arrow-right"></i>
                                </RouterLink>
                            </div>
                        </div>
                            </RouterLink>
                        </div>
                   ))
                            ) : <></>}
                </div>

            </div>
        </section>
    );
}

export default CategoriesPage;
