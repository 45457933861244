import styleSidebar from './css/sidebartoggle.module.css';
import React, { useEffect, useState } from 'react';
import axios from "../config/index";

function SidebarToggle({
  setSelectedCategory,
  setSelectedRating,
  setSelectedPrice,
  setVideoDuration,
  setLevel,
  setFreeOrPaid,
  resetFilters,
}) {
  const [categoriesOpen, setCategoriesOpen] = useState(true);
  const [filtersOpen, setFiltersOpen] = useState(true);
  const [selectedRating, setSelectedRatingState] = useState(null);
  const [categories, setCategories] = useState([]);
  const [isCategoriesModalOpen, setCategoriesModalOpen] = useState(false);
  const [isFiltersModalOpen, setFiltersModalOpen] = useState(false);
  const [selectedPrice, setSelectedPriceState] = useState(""); // or "free", or "paid"
  const [videoDuration, setVideoDurationState] = useState(null);
  const [level, setLevelState] = useState("");
  const [freeOrPaid, setFreeOrPaidState] = useState(null);
  const [selectedCategory, setSelectedCategoryState] = useState(null);
  const [selectedDuration, setSelectedDuration] = useState("");

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.post("AllItems", { table: "categories" });
        if (response.data) {
          setCategories(response.data);
        } else {
          console.error("Failed to fetch categories.");
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleCategoryChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setSelectedCategory(value);
    setSelectedCategoryState(value);
    setTimeout(() => setCategoriesModalOpen(false), 500);
  };

  const handleRatingChange = (event) => {
    const value = event.target.value;
    setSelectedRatingState(value);
    setSelectedRating(value);
    setTimeout(() => setFiltersModalOpen(false), 500);
  };

  const handlePriceChange = (event) => {
    console.log("Price changed:", event.target.value); // Log the event value
    setSelectedPriceState(event.target.value); // Update selected price state 
    setFreeOrPaid(event.target.value);
    setTimeout(() => setFiltersModalOpen(false), 500);
  };

  const handleDurationChange = (event) => {
    const value = event.target.id; // This should match the duration ID
    console.log("Selected Duration:", value); // Debugging log

    // Set the selected duration
    setSelectedDuration(value);
    setVideoDurationState(value); // Update video duration state

    // Optionally close the filters modal after selection
    setTimeout(() => setFiltersModalOpen(false), 500);
  };
  


  const handleLevelChange = (event) => {
    const value = event.target.id;
  
    // Toggle the selection off if the same option is selected
    if (level === value) {
      setLevelState(""); // Deselect
      setLevel(""); // Deselect
    } else {
      setLevelState(value);
      setLevel(value);
    }
  
    setFiltersModalOpen(false);
  };
  


  const resetAllFilters = () => {
    setSelectedCategoryState(null);
    setSelectedCategory(null);
    setSelectedRatingState(null);
    setSelectedRating(null);
    setSelectedPriceState(null);
    setFreeOrPaid(null);
    setVideoDurationState(null);
    setSelectedDuration(""); // Reset selected duration
    setLevelState(""); // Reset level state
    setLevel(""); // Reset selected level
    resetFilters(); // Ensure this doesn't reset the selected duration
  };






  return (
    <div>
      {/* Modal Trigger Buttons */}
      <div
        className={`${styleSidebar.sidebarToggle} ${styleSidebar.disappear}`}
      >
        {(selectedCategory ||
          selectedPrice ||
          selectedRating ||
          level ||
          selectedDuration ) && (
            <button
              className={styleSidebar.categoriesBtn}
              onClick={resetAllFilters}
            >
              مسح
            </button>

          )}
        <button
          className={styleSidebar.categoriesBtn}
          onClick={() => setCategoriesModalOpen(true)}
        >
          التصنيفات
        </button>
        <button
          className={styleSidebar.filterBtn}
          onClick={() => setFiltersModalOpen(true)}
        >
          الفلاتر
        </button>
      </div>

      {/* Categories Modal */}
      {isCategoriesModalOpen && (
        <div className={styleSidebar.modal}>
          <div className={styleSidebar.modalContent}>
            <button
              className={styleSidebar.closeBtn}
              onClick={() => setCategoriesModalOpen(false)}
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
            <div className={styleSidebar.section}>
              {categoriesOpen && (
                <div
                  className={`${styleSidebar.sectionContent2} ${styleSidebar.radioButtonGroup2}`}
                >
                  <input
                    name="options"
                    type="radio"
                    id="showAll"
                    value={0}
                    onChange={handleCategoryChange}
                    checked={selectedCategory === 0}
                  />
                  <label
                    className={styleSidebar.radioButton2}
                    htmlFor="showAll"
                  >
                    الكل
                  </label>
                  {categories.map((category) => (
                    <React.Fragment key={category.id}>
                      <input
                        type="radio"
                        name="options"
                        value={category.id}
                        id={`category${category.id}`}
                        onChange={handleCategoryChange}
                        checked={selectedCategory === category.id}
                      />
                      <label
                        className={styleSidebar.radioButton2}
                        htmlFor={`category${category.id}`}
                      >
                        {category.header}
                      </label>
                    </React.Fragment>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Filters Modal */}
      {isFiltersModalOpen && (
        <div className={styleSidebar.modal}>
          <div className={styleSidebar.modalContent}>
            <button
              className={styleSidebar.closeBtn}
              onClick={() => setFiltersModalOpen(false)}
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
            <div className={`${styleSidebar.section2} ${styleSidebar.s2}`}>
              <div className={styleSidebar.sectionContent2}>
                {/* Pricing Filter */}
                <div
                  className={`${styleSidebar.filterGroup2} ${styleSidebar.priceContainer}`}
                >
                  <label className={styleSidebar.filterHeader}>التسعير</label>
                  <div
                    className={`${styleSidebar.radioButtonGroup2} ${styleSidebar.priceContainer}`}
                  >
                    <label
                      className={`${styleSidebar.radioButton2} ${selectedPrice === "free" ? styleSidebar.selected : ""
                        }`}
                    >
                      <input
                        type="radio"
                        value="free"
                        id="free"
                        checked={selectedPrice === "free"}
                        onChange={handlePriceChange}
                      />
                      مجاني
                    </label>
                    <label
                      className={`${styleSidebar.radioButton2} ${selectedPrice === "paid" ? styleSidebar.selected : ""
                        }`}
                    >
                      <input
                        type="radio"
                        value="paid"
                        id="paid"
                        checked={selectedPrice === "paid"}
                        onChange={handlePriceChange}
                      />
                      مدفوع
                    </label>
                  </div>
                </div>

                {/* Rating Filter */}
                <div
                  className={`${styleSidebar.filterGroup2} ${styleSidebar.ratingContainer2}`}
                >
                  <label className={styleSidebar.filterHeader}>التقييمات</label>
                  <div className={styleSidebar.ratingOptions2}>
                    {[5, 4, 3].map((rating) => (
                      <label
                        key={rating}
                        className={`${styleSidebar.ratingBox2} ${selectedRating === String(rating)
                            ? styleSidebar.selected
                            : ""
                          }`}
                      >
                        <input
                          type="radio"
                          name="rating"
                          value={rating}
                          checked={selectedRating === String(rating)}
                          onChange={handleRatingChange}
                        />
                        <div>
                          <span>{"★ ".repeat(rating)}</span>
                          <span>نجوم {rating}</span>
                        </div>
                      </label>
                    ))}
                  </div>
                </div>

                {/* Video Duration Filter */}
                <div className={`${styleSidebar.filterGroup2} ${styleSidebar.ratingContainer2}`}>
                  <label className={styleSidebar.filterHeader}>مدة الفيديو</label>
                  <div className={styleSidebar.durationOptions}>
                    {[
                      { id: '1', header: 'ساعة', value: '1' },
                      { id: '2', header: 'ساعتين', value: '2' },
                      { id: '3', header: '3 ساعات', value: '3' },
                    ].map((option) => (
                      <React.Fragment key={option.id}>
                        <label className={`${styleSidebar.durationBox} ${selectedDuration === option.id ? styleSidebar.selected : ""}`}>
                          <input
                            type="radio"
                            name="duration"
                            id={option.id}
                            value={option.value}
                            checked={selectedDuration === option.id}
                            onChange={handleDurationChange}
                          />
                          {option.header}
                        </label>
                      </React.Fragment>
                    ))}
                  </div>
                </div>




                {/* Level Filter */}
                <div className={`${styleSidebar.filterGroup2} ${styleSidebar.ratingContainer2}`}>
  <label className={styleSidebar.filterHeader}>المستوى</label>
  <div className={styleSidebar.durationOptions}>
    {[
      { id: 'مبتدئ', header: 'مبتدئ', value: 'beginner' },
      { id: 'متوسط', header: 'متوسط', value: 'intermediate' },
      { id: 'متقدم', header: 'متقدم', value: 'advanced' },
    ].map((option) => (
      <React.Fragment key={option.id}>
        <label className={`${styleSidebar.durationBox} ${level === option.id ? styleSidebar.selected : ""}`}>
          <input
            name="level"
            type="radio"
            id={option.id}
            onChange={handleLevelChange}
            checked={level === option.id} // Check if level matches this option
          />
          {option.header}
        </label>
      </React.Fragment>
    ))}
  </div>
</div>






              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SidebarToggle;
