import './css/landcourses.css';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import axios from '../config/index';
import { useState, useEffect } from 'react';

const Stars = Array(5).fill(null);

function CategoryCourses() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    const [categoriesdata, setAllCategories] = useState([]);
    const [courses, setAllCourses] = useState([]);
    const [users, setAllUsers] = useState([]);
    const [filteredCourses, setFilteredCourses] = useState([]);
    const [categoryTitle, setCategoryTitle] = useState('');

    const getAllCategories = () => {
        axios.post('AllItems', { table: 'categories' })
            .then((res) => {
                if (res.data) {
                    setAllCategories(res.data);
                } else {
                    console.log("Error happened");
                }
            })
            .catch((err) => console.log(err));
    };

    const getAllCourses = () => {
        axios.post('AllItems', { table: 'courses' })
            .then((res) => {
                if (res.data) {
                    setAllCourses(res.data);
                } else {
                    console.log("Error happened");
                }
            })
            .catch((err) => console.log(err));
    };

    const getAllUsers = () => {
        axios.post('AllUsers', { table: 'users' })
            .then((res) => {
                if (res.data) {
                    setAllUsers(res.data);
                } else {
                    console.log("Error happened");
                }
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        getAllCategories();
        getAllCourses();
        getAllUsers();
    }, []);

    useEffect(() => {
        if (categoriesdata.length > 0 && courses.length > 0 && users.length > 0) {
            const selectedCategory = categoriesdata.find(category => category.id === parseInt(id));
            if (selectedCategory) {
                setCategoryTitle(selectedCategory.title);
                const categoryCourses = courses.filter(course => course.cat_id === parseInt(id) && course.status === 'active');

                // Combine course data with instructor data
                const coursesWithInstructors = categoryCourses.map(course => {
                    const instructor = users.find(user => user.user_id === course.user_id) || {};
                    return { ...course, instructor };
                });

                setFilteredCourses(coursesWithInstructors);
            }
        }
    }, [categoriesdata, courses, users, id]);

    return (
        <section className='land-courses'>
            <div className='container'>
                <div className='section-header'>
                    <div>
                        <h2 className='text-btn'>الدورات الخاصة بالقسم </h2>
                        <h1 className='section-headline'> {categoryTitle} </h1>
                    </div>
                </div>
                <div className='row'>
                    {filteredCourses.length ? (
                    filteredCourses.map((course, index) => (
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12' key={index}>
                            <div className='course'>
                                <RouterLink to={`/course?id=${course.id}`}>
                                    <img src={`./uploads/${course.img}`} alt='course image' />
                                </RouterLink>
                                <div className='text'>
                                    <div className='reviews'>
                                        <ul>
                                            {Stars.map((_, i) => (
                                                <li key={i}>
                                                    <i className="las la-star"></i>
                                                </li>
                                            ))}
                                        </ul>
                                        <span>({course.reviews} مراجعات)</span>
                                    </div>
                                    <h1>{course.header}</h1>
                                    <div className='course-info'>
                                        <span><i className="las la-book"></i> {course.lessons} دروس</span>
                                        <span><i className="las la-users"></i> {course.students} طلاب</span>
                                    </div>
                                    <p className='section-text'>{course.description}</p>
                                    <div className='instructor-info'>
                                        <img src={course.instructor.profile_img} alt='instructor image' />
                                        <h4>بواسطة <span>{course.instructor.fullname}</span></h4>
                                    </div>
                                    <div className='course-price'>
                                    {course.free == 1 ? <h3> مجاني </h3> :<>
                                                                    <h3>${course.price} <span>${course.prev_price}</span></h3>
                                                                </>}
                                        <RouterLink to={`/course?id=${course.id}`}>اعرف أكثر <i className="las la-arrow-right"></i></RouterLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                   ))
                            ) : <></>}
                </div>
            </div>
        </section>
    );
}

export default CategoryCourses;
