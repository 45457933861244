import "./css/landcategories.css";
import { Link as RouterLink } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "../config/index";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
// import 'swiper/modules/pagination/pagination.min.css';
import { Pagination } from "swiper";
import CatImg1 from "./images/home/Next steps-amico 1.png";
import CatImg2 from "./images/home/Next steps-amico 1 (1).png";
import CatImg3 from "./images/home/Next steps-amico 1 (3).png";
import CatImg4 from "./images/home/Next steps-amico 1 (4).png";
import CatImg5 from "./images/home/Next steps-amico 1 (5).png";
import CatImg6 from "./images/home/Next steps-amico 1 (6).png";

import CatIcon1 from "./images/home/CPU.svg";
import CatIcon2 from "./images/home/Code.svg";
import CatIcon3 from "./images/home/Colum_up_fill.svg";
import CatIcon4 from "./images/home/Img.svg";
import CatIcon5 from "./images/home/Group 8637.svg";

const LandCategories = () => {
  const [categoriesdata, setAllCategories] = useState([]);

  const getAllCategories = () => {
    axios
      .post("AllItems", { table: "categories" })
      .then((res) => {
        if (res.data) {
          setAllCategories(res.data);
        } else {
          console.log("Error happened");
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  const lastFourCategories = categoriesdata.slice(-6);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);

  useEffect(() => {
    const handleResize = () => {
      console.log("Window width:", window.innerWidth);
      setIsMobile(window.innerWidth <= 576);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);
   return (
     <section className="land-categories">
       <div className="container">
         <div className="section-header">
           <h2 className="categoriesHeader"> التصنيفات </h2>
           <h1 className="section-headline"> ابدأ رحلتك التعليمية اليوم </h1>
         </div>

         {/* Conditional rendering based on screen size */}
         {isMobile ? (
           <div className="categories-container">
             <Swiper
               spaceBetween={20}
               slidesPerView={1}
               loop={true}
               modules={[Pagination]}
               pagination={{ clickable: true }}
               className="custom-swiper-width"
             >


{lastFourCategories.length ? (
               lastFourCategories.map((category, index) => (
                 <div key={index} className="col-lg-3 col-md-3 col-sm-6 col-12">
                   <RouterLink to={`/category?id=${category.id}`}> 
               <SwiperSlide>
                 <div className="cat1">
                   <div className="groupChild">
                     <img className="nextStepsAmico1" alt=""   src={`./uploads/${category.img}`} />
                     <img className="cpuIcon" alt=""  src={`./uploads/${category.icon}`} />
                     <div className="categories-name"> {category.header}</div>
                   </div>
                 </div>
               </SwiperSlide>
                   </RouterLink>
                 </div>
               ))
             ) : (
               <></>
             )}

             
               {/* <SwiperSlide>
                 <div className="cat2">
                   <div className="groupChild">
                     <img className="nextStepsAmico1" alt="" src={CatImg2} />
                     <img className="cpuIcon" alt="" src={CatIcon2} />
                     <div className="categories-name">البرمجة</div>
                   </div>
                 </div>
               </SwiperSlide>
               <SwiperSlide>
                 <div className="cat3">
                   <div className="groupChild">
                     <img className="nextStepsAmico1" alt="" src={CatImg3} />
                     <img className="cpuIcon" alt="" src={CatIcon3} />
                     <div className="categories-name">التنمية البشرية</div>
                   </div>
                 </div>
               </SwiperSlide>
               <SwiperSlide>
                 <div className="cat4">
                   <div className="groupChild">
                     <img className="nextStepsAmico1" alt="" src={CatImg4} />
                     <img className="cpuIcon" alt="" src={CatIcon4} />
                     <div className="categories-name">التصميم</div>
                   </div>
                 </div>
               </SwiperSlide>
               <SwiperSlide>
                 <div className="cat5">
                   <div className="groupChild">
                     <img className="nextStepsAmico1" alt="" src={CatImg5} />
                     <img className="cpuIcon" alt="" src={CatIcon5} />
                     <div className="categories-name">ريادة الأعمال</div>
                   </div>
                 </div>
               </SwiperSlide> */}
             </Swiper>
           </div>
         ) : (
           <div className="categories-container">
             {lastFourCategories.length ? (
               lastFourCategories.map((category, index) => (
                 <div key={index} className="col-lg-3 col-md-3 col-sm-6 col-12">
                   <RouterLink to={`/category?id=${category.id}`}>
                     <div className="cat1">
                       <div className="groupChild">
                         <img
                           className="nextStepsAmico1"
                           alt=""
                           src={`./uploads/${category.img}`}
                         />
                         <img
                           className="cpuIcon"
                           alt=""
                           src={`./uploads/${category.icon}`}
                         />
                         <div className="categories-name">
                           {category.header}
                         </div>
                       </div>
                     </div>
                   </RouterLink>
                 </div>
               ))
             ) : (
               <></>
             )}
             {/* <div className="cat2">
               <div className="groupChild">
                 <img className="nextStepsAmico1" alt="" src={CatImg2} />
                 <img className="cpuIcon" alt="" src={CatIcon2} />

                 <div className="categories-name">البرمجة</div>
               </div>
             </div>
             <div className="cat3">
               <div className="groupChild">
                 <img className="nextStepsAmico1" alt="" src={CatImg3} />
                 <img className="cpuIcon" alt="" src={CatIcon3} />

                 <div className="categories-name">التنمية البشرية</div>
               </div>
             </div>
             <div className="cat4">
               <div className="groupChild">
                 <img className="nextStepsAmico1" alt="" src={CatImg4} />
                 <img className="cpuIcon" alt="" src={CatIcon4} />

                 <div className="categories-name">التصميم</div>
               </div>
             </div>
             <div className="cat5">
               <div className="groupChild">
                 <img className="nextStepsAmico1" alt="" src={CatImg5} />
                 <img className="cpuIcon" alt="" src={CatIcon5} />

                 <div className="categories-name">ريادة الأعمال</div>
               </div>
             </div>
             <div className="cat6">
               <div className="groupChild">
                 <img className="nextStepsAmico1" alt="" src={CatImg1} />
                 <i className="fa-solid fa-laptop cpuIcon2"></i>

                 <div className="categories-name">التكنولوجيا</div>
               </div>
             </div> */}
           </div>
         )}
       </div>

       <div className="view-all">
         <RouterLink className="link-btn" to="/categories">
           {" "}
           تعرف على المزيد
         </RouterLink>
       </div>
     </section>
   );
};

export default LandCategories;
