import './css/loginregister.css';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import axios from '../config/index';

function ConfirmEmail() {
    const navigate = useNavigate();

    const [loginEmail, setLoginEmail] = useState('');
    const [loginPassword, setLoginPassword] = useState('');
    const [registerEmail, setRegisterEmail] = useState('');
    const [registerName, setRegisterName] = useState('');
    const [registerPassword, setRegisterPassword] = useState('');
    const [registerConfirmPassword, setRegisterConfirmPassword] = useState('');
    const [registerType, setRegisterType] = useState('');
    const [confirmationCode, setConfirmationCode] = useState('');
    const [loading, setLoading] = useState(false); // Add loading state

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true); // Show loading screen
        try {
            const response = await axios.post('/login', {
                email: loginEmail,
                password: loginPassword
            });
            alert(response.data.message);
        } catch (error) {
            alert(error.response.data.message);
        } finally {
            setLoading(false); // Hide loading screen
        }
    };

    const handleRegister = async (e) => {
        e.preventDefault();
        if (registerPassword !== registerConfirmPassword) {
            alert('Passwords do not match');
            return;
        }
        setLoading(true); // Show loading screen
        try {
            const response = await axios.post('/signup', {
                fullname: registerName,
                email: registerEmail,
                password: registerPassword,
                type: registerType
            });
            navigate('/confirm-email');
        } catch (error) {
            alert(error.response.data.message);
        } finally {
            setLoading(false); // Hide loading screen
        }
    };

    const handleConfirmEmail = async (e) => {
        e.preventDefault();
        setLoading(true); // Show loading screen
        try {
            const response = await axios.post('/check_confirmation_code', {
                confirmationCode: confirmationCode
            });
            alert(response.data.message);
            navigate('/login-register');
        } catch (error) {
            alert(error.response.data.message);
        } finally {
            setLoading(false); // Hide loading screen
        }
    };

    return (
        <>
            {loading && (
                <div className='loading-screen'>
                    <div className="loader"></div>
                </div>
            )}
            <section className='login-register'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='form-element'>
                                <h1>تسجيل جديد</h1>
                                <form onSubmit={handleRegister}>
                                    <div className='inputs'>
                                        <div className='input'>
                                            <input
                                                type='email'
                                                placeholder='البريد الإلكتروني'
                                                required
                                                value={registerEmail}
                                                onChange={(e) => setRegisterEmail(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='inputs'>
                                        <div className='input'>
                                            <input
                                                type='text'
                                                placeholder='الاسم'
                                                required
                                                value={registerName}
                                                onChange={(e) => setRegisterName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='inputs'>
                                        <div className='input'>
                                            <select
                                                required
                                                value={registerType}
                                                onChange={(e) => setRegisterType(e.target.value)}
                                            >
                                                <option value=''>إختر نوع الحساب</option>
                                                <option value='user'>طالب</option>
                                                <option value='inst'>مدرب</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='inputs'>
                                        <div className='input'>
                                            <input
                                                type='password'
                                                placeholder='كلمة المرور'
                                                required
                                                value={registerPassword}
                                                onChange={(e) => setRegisterPassword(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='inputs'>
                                        <div className='input'>
                                            <input
                                                type='password'
                                                placeholder='تأكيد كلمة المرور'
                                                required
                                                value={registerConfirmPassword}
                                                onChange={(e) => setRegisterConfirmPassword(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <button type='submit' className='link-btn-color'>
                                        تسجيل جديد <i className='las la-arrow-right'></i>
                                    </button>
                                </form>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='form-element'>
                                <h1>تأكيد البريد الإلكتروني</h1>
                                <form onSubmit={handleConfirmEmail}>
                                    <div className='inputs'>
                                        <div className='input'>
                                            <input
                                                type='text'
                                                placeholder='رمز التأكيد'
                                                required
                                                value={confirmationCode}
                                                onChange={(e) => setConfirmationCode(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <button type='submit' className='link-btn-midcolor'>
                                        تأكيد البريد الإلكتروني <i className='las la-arrow-right'></i>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ConfirmEmail;
