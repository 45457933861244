import './css/landevents.css';
import { Link as RouterLink } from 'react-router-dom';

import axios from '../config/index';
import { useState, useEffect } from 'react';

function EventsPage() {

    
    const [EventData, setAllEvents] = useState([]);

    const getAllEvents = () => {
        axios.post('AllItems', { table: 'events' })
            .then((res) => {
                if (res.data) {
                    setAllEvents(res.data);
                } else {
                    console.log("Error happened");
                }
            })
            .catch((err) => console.log(err));
    };



    useEffect(() => {
        getAllEvents();
    }, []);




    return (
        EventData.length ? (
            <section className='land-events'>
            <div className='container'>
                <div className='row'>
                    <div className='section-header'>
                        <div>
                            <h2 className='text-btn'> الأحداث </h2>
                            <h1 className='section-headline'> أحداث ميد </h1>
                        </div>
                    </div>

                    {EventData.map((event, index) => (
                        <div key={index} className='col-lg-6 col-md-6 col-sm-12 col-12'>
                        <div className='event'>
                            <img src={`./uploads/${event.img}`} alt='event' />
                            <div className='text'>
                                <div>
                                    <ul>
                                        <li>
                                            <a href={event.location} target='_blank'>
                                                <i className="las la-map-marker-alt"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <i className="las la-calendar-day"></i>
                                            {event.date}
                                        </li>
                                        <li>
                                            <i className="las la-clock"></i>
                                            {event.time}
                                        </li>
                                    </ul>
                                </div>
                                <h1>{event.header}</h1>
                                <a className='link-btn-midcolor' href={event.link}>
                                    احصل على تذكرة <i className="las la-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    ))}
                </div>
            </div>
            </section>
        )  : <></>
        
    );
}

export default EventsPage;
