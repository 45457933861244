import React from 'react';

// Star component to render individual stars
const Star = ({ filled }) => {
  return (
    <span style={{ cursor: 'pointer', color: filled ? '#FFD700' : '#D3D3D3' }}>
      &#9733;
    </span>
  );
};

// StarRating component to display the stars based on static rating
const StarRating = ({ totalStars = 5, rating }) => {
  return (
    <div>
      {/* Render stars up to totalStars */}
      {[...Array(totalStars)].map((_, index) => (
        <Star key={index} filled={index < rating} />
      ))}
    </div>
  );
};

export default StarRating;
