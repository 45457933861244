import './css/dashboardtable.css';
import './css/editform.css';
import React, { useState, useEffect } from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';




function EditCategory() {



    useEffect(() => {
        axios.get('/admin-session')
            .then((res) => {
                if (res.data.validadmin) {
                    if (res.data.type !== "admin") {
                        navigate('/admin-login');
                    }
                } else {
                    // Handle the case when session is not valid
                }
            })
            .catch((err) => console.log(err));
    }, []);




    const [formChanged, setFormChanged] = useState(false); // Track if the form has changed


    const handleInputChange = () => {
        setFormChanged(true); // Set the formChanged flag to true when any input changes
    };




    const navigate = useNavigate();


    const [ItemData, setItemData] = useState({});


    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');


    const [formInputs, setFormInputs] = useState({
        header: "",

        img: null,
        icon: null,

    });
    
    // Use formInputs to set the initial value of inputs
    useEffect(() => {
        if (ItemData.id) {
        setFormInputs({
            
            header: ItemData.header,
            img: null,
            icon: null,

        });
        }
    }, [ItemData]);

        const handleChange = (e) => {
            handleInputChange();
            const { name, value, type } = e.target;
            const inputValue = type === "file" ? e.target.files[0] : value;
        
            setFormInputs((prevFormInputs) => ({
            ...prevFormInputs,
            [name]: inputValue,
            }));
        };


        
        const handleSubmit = async (e) => {
            e.preventDefault();
        
            let Loading = document.querySelector(".loading-screen");
            Loading.classList.add("active");
        
            const { header,img, icon } = formInputs;
        
            if (!header  ) {
            return alert("All fields are required");
            }
        
            try {
            const form = new FormData();
            form.append("id", id);
            form.append("header", header);

            form.append("img", img);
            form.append("icon", icon);

            const response = await axios.put("/update-category", form);
        
            if (response.status === 200) {
                // Update LandingSlide state after a successful update
                setItemData((prevItemData) => ({
                ...prevItemData,
                header
                }));
        
                Loading.classList.remove("active");
                let alertS = document.querySelector(".alert-success");
                alertS.classList.add("active");
        
                setTimeout(() => {
                alertS.classList.remove("active");
                }, 3000);
            } else {
                Loading.classList.remove("active");
                let alertD = document.querySelector(".alert-danger");
                alertD.classList.add("active");
                setTimeout(() => {
                alertD.classList.remove("active");
                }, 3000);
            }
            } catch (error) {
            console.error(error);
            alert("Server error");
            }
        };




    axios.defaults.withCredentials = true;
    
    const getItemData = (itemId) => {
        let table = "categories";
        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setItemData(res.data);
                }
            })
            .catch((err) => console.log(err));
    };




    const getIfLogin = () => {
        axios.get('/admin-session')
            .then((res) => {
                if (!res.data.validadmin || id <= 0 || id == null || id == "") {
                    navigate('/admin-login');
                } else {
                    getItemData(id);
                }
            })
            .catch((err) => console.log(err));
    };
    



    useEffect(() => {
        getItemData(id);
    }, []);
    
    return (
        <>

<div className='loading-screen'>
                            <div class="loader">
                                <div class="outer"></div>
                                <div class="middle"></div>
                                <div class="inner"></div>
                            </div>

                        </div>
            <section className='dashboard'>
                <div className='edit-form'>

                
                <div className='alert-success'>
                        <h1> تمت الإضافة  <i class="las la-check-circle"></i></h1>
                    </div>

                    <div className='alert-danger'>
                        <h1> فشل التعديل <i class="las la-exclamation-triangle"></i></h1>

                    </div>


                    <form onSubmit={handleSubmit}>
                

            
                        
                    <div className='input'>
                        <label> العنوان  </label>
                        <input   value={formInputs.header} onChange={handleChange} type='text' required="required"  maxLength="230" name='header'/>
                    </div>



                            <div className='input'>
                                <label> الصورة  (لا تتعدى الصورة حجم الواحد ميجا )</label>
                                <input  onChange={handleChange} type='file' name="img" accept="image/*"/>
                            </div>

                            <div className='input'>
                                <label> الأيقونة  (لا تتعدى الأيقونة حجم الواحد ميجا )</label>
                                <input  onChange={handleChange} type='file' name="icon" accept="image/*"/>
                            </div>


                                <button disabled={!formChanged} type='submit'> تحديث </button>
                            </form>
                        </div>
                    </section>
                </>
            );
            }
            


            export default EditCategory;
